import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextArea from '../formInputs/textArea/textArea';
import TextInput from '../formInputs/textInput/textInput';

const initialValues = {
  name: '',
  description: '',
};

const DebateAudioForm = ({ onSubmit, formReference, canResize, hasCounter, maxLength, rows }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    name: Yup.string(),
    description: Yup.string(),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="name"
              id="name"
              placeholder={t('debates.yourName')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              x
            />
            <TextArea
              name="description"
              id="description"
              placeholder={t('debates.shortDescription')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              maxLength={maxLength}
              canResize={canResize}
              hasCounter={hasCounter}
              rows={rows}
            />
          </>
        )
      }
    </Formik>
  );
};

DebateAudioForm.propTypes = {
  onSubmit: PropTypes.func,
  canResize: PropTypes.bool,
  hasCounter: PropTypes.bool,
  maxLength: PropTypes.string,
  rows: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

DebateAudioForm.defaultProps = {
  canResize: true,
  hasCounter: true,
  maxLength: "100",
  rows: "3",
};

export default DebateAudioForm;
