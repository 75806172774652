import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextInput from '../formInputs/textInput/textInput';

const initialValues = {
  username: '',
  password: '',
};

const LoginForm = ({ onSubmit, formReference }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t('validation.fieldIsRequired')),
    password: Yup.string()
      .required(t('validation.fieldIsRequired')),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="username"
              id="username"
              placeholder={t('authentication.phoneNumberOrEmail')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="password"
              id="password"
              type="password"
              placeholder={t('globals.password')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
          </>
        )
      }

    </Formik>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default LoginForm;
