import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

const ClickableText = ({
  icon, text, iconHeight, iconWidth, onClick, textClass, errorMsg,
}) => (
  <div onClick={() => onClick()} aria-hidden="true" style={{ cursor: 'pointer' }}>
    <Row className="no-gutters d-flex flex-row align-items-center">
      {icon && (
      <div className="flex-shrink-1">
        <img src={icon} alt="icon" width={iconWidth} height={iconHeight} />
      </div>
      )}
      <div className="ml-2" style={{ position: 'relative' }}>
        <div className={`app-value h-100 d-flex flex-row align-items-center pt-1 ${textClass}`}>{text}</div>
        {errorMsg? (
        <span
          style={{
            color: 'red', fontSize: '0.5rem', position: 'absolute', top: '22px', left: 0,
          }}
          className={`app-value ${textClass}`}
        >
          {errorMsg}
        </span>
        ): null}
      </div>
    </Row>
  </div>
);

ClickableText.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  iconHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  textClass: PropTypes.string,
  errorMsg: PropTypes.string,
};

ClickableText.defaultProps = {
  icon: null,
  iconHeight: "18",
  iconWidth: "18",
  textClass: '',
  errorMsg: null,
};

export default ClickableText;
