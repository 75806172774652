import {
  FOLLOW, GET_FOLLOWERS, GET_FOLLOWING, GET_USER,
} from '../constants/connectionsConstants';

/**
 * @param {number} page
 * @param {string} search
 * @param {number} pageLimit
 */
export const getFollowing = (page = 1, userId, search = '', pageLimit = 10) => ({
  type: GET_FOLLOWING,
  payload: {
    request: {
      url: `/user${userId? `/${userId}`: ''}/following`,
      method: 'get',
      authenticated: true,
      params: {
        page,
        pageLimit,
        search,
      },
    },
    page,
  },
});

/**
 * @param {number} page
 * @param {string} search
 * @param {number} pageLimit
 */
export const getFollowers = (page = 1, userId, search = '', pageLimit = 10) => ({
  type: GET_FOLLOWERS,
  payload: {
    request: {
      url: `/user${userId? `/${userId}`: ''}/followers`,
      method: 'get',
      authenticated: true,
      params: {
        page,
        pageLimit,
        search,
      },
    },
    page,
  },
});

/**
 * @param {string} user
 * @param {boolean} isFollow
 */
export const follow = (user, isFollow) => ({
  type: FOLLOW,
  payload: {
    request: {
      url: `user/${user}/${isFollow ? 'follow' : 'unfollow'}`,
      method: 'post',
      authenticated: true,
    },
    user,
    isFollow
  },
});

/**
 * @param {string} user
 */
export const getUser = (user) => ({
  type: GET_USER,
  payload: {
    request: {
      url: `user/${user}`,
      method: 'get',
      authenticated: true,
    },
    user,
  },
});
