import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './home.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import { getRiffById, getRiffs } from '../../store/actions/homepageActions';
import constants from '../../utils/constants';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import playCircleOrange from '../../assets/icons/playCircleOrange@3x.png';
import pauseLarge from '../../assets/icons/pauseLarge@3x.png';
import { getUser } from '../../store/actions/userActions';
import TrendingTags from '../../components/trendingTags/trendingTags';
import { getTrendingHashTags } from '../../store/actions/searchActions';
import RiffsList from '../../components/riffsList/riffsList';
import SortBtn from '../../components/sortBtn/sortBtn';
import SideRiff from '../../components/sideRiff/sideRiff';


const Home = ({history, location}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [audioPlaying, setAudioPlaying] = useState(0);
  const [playingStatus, setPlayingStatus] = useState('');
  const [showSideRiff, setShowSideRiff] = useState(false);
  const [sort, setSort] = useState(constants.sort.recent);
  const home = useSelector((state) => state.home);
  const { tag } = useParams();
  const [playAllMode, setPlayAllMode] = useState(false);
  const { userId } = useSelector(state => state.authentication);
  const {trendingHashTags} = useSelector(state => state.search);

  useEffect(() => {
    dispatch(getTrendingHashTags());
  }, [dispatch]);
  
  useEffect(() => {
    if(!userId){
      dispatch(getUser());
    }
  }, [userId, dispatch])

  useEffect(() => {
    if (tag && !isNaN(tag)) {
      dispatch(getRiffById(tag));
      return;
    }
    dispatch(getRiffs(1, sort, tag));
  }, [dispatch, tag, sort]);

  useEffect(() => {
    if(playAllMode && !audioPlaying) {
      setAudioPlaying(`${home.allIds[0]}-0`);
    }
  }, [playAllMode, home.allIds, audioPlaying]);

  useEffect(() => {
    if(!playAllMode){
      setAudioPlaying(0);
    }
  }, [playAllMode]);

  const renderPlayAllBtn = (w) => (
    <div className="mt-3 mt-md-0 mt-lg-4 ml-0 ml-md-4 ml-lg-0 playAllBtnContainer">
      <img src={playAllMode? pauseLarge: playCircleOrange} alt="playAll" onClick={() => setPlayAllMode(!playAllMode)} className="clickable mr-2" width={w} />
      <span className="bold clickable pt-1" onClick={() => setPlayAllMode(!playAllMode)}>{playAllMode? t('riff.pauseAllRiffs'): t('riff.playAllRiffs')}</span>
    </div>
  )

  const renderError = () => (
    <h3 className="errorTxt"> {home.error} </h3>
  )

  const notAllHidden = (allIds) => {
    let result = false;
    for(const id of allIds){
      if(!home.byId[id].hide){
        result = true;
        break;
      }
    } 
    return result;
  }

  const handleBack = () => {
    if(location.state && location.state.length) {
      const currentState = location.state.shift();
      history.push(currentState.backTo, location.state.length? location.state: null );
    } else {
      history.push(constants.screens.home);
    }
  }

  return (
    <Page header={<AuthenticatedNavBar activeKey={(location.state && location.state[0].mainScreen) || 'myFeeds'} />} hideMargin>
      <Row className={`no-gutters d-block d-lg-none`}>
        <div className={`position-fixed topFixedRiff${!showSideRiff? '-hide': ''} w-100`}>
          <SideRiff playAllMode={playAllMode} setPlayAllMode={setPlayAllMode} hide={!showSideRiff} setPlayingStatus={setPlayingStatus} ismd/>
        </div>
      </Row>
      <Row className="no-gutters mt-4 mt-xl-3">
        {tag ? (
          <>
            <Col xs={1} sm={2} lg={4} className="flex-fill">
              {location.state && location.state[0].backTo !== constants.screens.home? 
                <PageLeftContent 
                  title={t(location.state[0].backTo === constants.screens.search? 'globals.backToSearch': location.state[0].backTo === constants.screens.profile? 'globals.backToProfile': 'globals.back')} 
                  onClick={handleBack} />:              
                <PageLeftContent title={t('globals.backToFeed')} onClick={handleBack} />
              }
            </Col>
            {
                  isNaN(tag) ? (
                    <Col xs={10} sm={8} lg={4}>
                      <PageTitle>
                        <span className={tag.toLowerCase()==='podcastpreviews'? 'blueTxt': ''}>{'#' + tag}</span>
                      </PageTitle>
                      <div className={`d-flex flex-column align-items-start flex-md-row align-items-md-center d-lg-none`}>
                        {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && <SortBtn sort={sort} setSort={setSort} />}
                        {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && renderPlayAllBtn(24)}
                      </div>
                      <RiffsList playAllMode={playAllMode}
                                  setPlayAllMode={setPlayAllMode} 
                                  tag={tag} 
                                  goTo={(screen) => screen !== location.pathname && history.push(screen, [{backTo: location.pathname, mainScreen: (location.state && location.state[0].mainScreen) || 'myFeeds'}, ...location.state])}
                                  sort={sort}
                                  playingStatus={playingStatus}
                                  setPlayingStatus={setPlayingStatus}
                                  setShowSideRiff={setShowSideRiff}
                                  getRiffs={getRiffs}
                                  history={history}
                                 />
                    </Col>
                  ) : (
                    <Col xs={10} sm={8} lg={4}>
                      <RiffsList playAllMode={playAllMode}
                                  setPlayAllMode={setPlayAllMode} 
                                  tag={tag} 
                                  goTo={(screen) => history.push(screen, [{backTo: location.pathname, mainScreen: (location.state && location.state[0].mainScreen) || 'myFeeds'}, ...location.state])}
                                  sort={sort}
                                  history={history}
                                 />
                    </Col>
                  )
                }
            {
              home.error && <Col xs={10} sm={8} lg={4} className="offset-1 offset-sm-2 offset-lg-4">
                {renderError()}
              </Col>
            }

          </>
        ) : (
          <Col xs={10} sm={8} lg={4} className="offset-1 offset-sm-2 offset-lg-4">
            <div className="d-block d-lg-none">
              <PageTitle>
                My Feed
              </PageTitle>
            </div>
           
            <div className={`d-flex flex-column align-items-start flex-md-row align-items-md-center d-lg-none mb-3`}>
              {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && <SortBtn sort={sort} setSort={setSort} />}
              {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && renderPlayAllBtn(24)}
            </div>
            {trendingHashTags && <TrendingTags hasTitle={false} onClick={(tag) => [setPlayAllMode(false), history.push(`${constants.screens.home}/${tag.name}`, [{backTo: location.pathname, mainScreen: 'myFeeds'}])]} trendingHashTags={trendingHashTags} />}
            <RiffsList playAllMode={playAllMode}
                        setPlayAllMode={setPlayAllMode} 
                        tag={tag} 
                        goTo={(screen) => history.push(screen, [{backTo: location.pathname, mainScreen: 'myFeeds'}])}
                        sort={sort}
                        playingStatus={playingStatus}
                        setPlayingStatus={setPlayingStatus}
                        setShowSideRiff={setShowSideRiff}
                        getRiffs={getRiffs}
                        history={history}
                        />
          </Col>
        )}
        <Col lg={3} xl={3} className="d-none d-lg-block d-xl-block ml-5">
          <div className="position-fixed">
            <SideRiff playAllMode={playAllMode} setPlayAllMode={setPlayAllMode} hide={!showSideRiff} setPlayingStatus={setPlayingStatus} />
            {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && <SortBtn sort={sort} setSort={setSort} />}
            {!home.loading && (!tag || (tag && isNaN(tag))) && notAllHidden(home.allIds) && renderPlayAllBtn(36)}
          </div>
        </Col>
      </Row>
    </Page>
  );
};

export default Home;
