const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAIL = 'GET_USER_FAIL';
const GET_USER_BY_ID = 'GET_USER_BY_ID';
const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';
const SET_FIRST_LOGIN = 'SET_FIRST_LOGIN';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
const CLEAR_UPDATE_USER = 'CLEAR_UPDATE_USER';
const GET_USER_RIFF = 'GET_USER_RIFF';
const GET_USER_RIFF_SUCCESS = 'GET_USER_RIFF_SUCCESS';
const GET_USER_RIFF_FAIL = 'GET_USER_RIFF_FAIL';
const SUSPEND_ACCOUNT = 'SUSPEND_ACCOUNT';
const SUSPEND_ACCOUNT_SUCCESS = 'SUSPEND_ACCOUNT_SUCCESS';
const GET_USER_SETTINGS = 'GET_USER_SETTINGS';
const GET_USER_SETTINGS_SUCCESS = 'GET_USER_SETTINGS_SUCCESS';
const GET_USER_SETTINGS_FAIL = 'GET_USER_SETTINGS_FAIL';

export {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  SET_FIRST_LOGIN,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  CLEAR_UPDATE_USER,
  GET_USER_RIFF,
  GET_USER_RIFF_SUCCESS,
  GET_USER_RIFF_FAIL,
  SUSPEND_ACCOUNT,
  SUSPEND_ACCOUNT_SUCCESS,
  GET_USER_SETTINGS,
  GET_USER_SETTINGS_SUCCESS,
  GET_USER_SETTINGS_FAIL
};
