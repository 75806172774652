import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import playFilled from '../../assets/icons/playFilled.png';
import playGreyFilled from '../../assets/icons/playCircleOrange@2x.png';
import pauseFilled from '../../assets/icons/pauseFilled.png';
import './audioPlayer.scss';
import { formatDate, formatSeconds } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { setCanSetRemainingTime, setRiffAudioLength, setRiffAudioRemainingTime, setRiffIdName } from '../../store/actions/riffActions';

const AudioPlayer = ({
  file,
  waveform,
  waveformActive,
  audioRef,
  isPlaying,
  onPlay,
  id,
  className,
  next,
  playAllMode,
  setPlayAllMode,
  isYourRiff,
  date,
  index,
  isComment,
  isEcho
}) => {
  const [marginLeft, setMarginLeft] = useState('0%');
  const [imageWidth, setImageWidth] = useState('100%');
  const [canPlay, setCanPlay] = useState(true);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [wasInPlayAllMode, setWasInPlayAllMode] = useState(false);
  const [canSliderMouseMove, setCanSliderMouseMove] = useState(false);
  const [sliderMoved, setSliderMoved] = useState(false);
  const [canSetSideRiffSlider, setCanSetSideRiffSlider] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [length, setlength] = useState(0);
  const dispatch = useDispatch();
  const { canSetRemainingTime, sideRiffTime, prevId } = useSelector(state => state.riff);

  useEffect(() => {
    if(prevId === `${id}-${index.toString()}` && sideRiffTime && !isPlaying) {
      setImageWidth(sideRiffTime.imgWidthPercent);
      setMarginLeft(sideRiffTime.marginLeftPercent);
      setRemainingTime(sideRiffTime.remainingLength);
      audioRef.current.currentTime = length - sideRiffTime.remainingLength;
      if(sideRiffTime.shouldPlay) {
        audioRef.current.play();
      }
      // dispatch(setRiffAudioRemainingTime(sideRiffTime.remainingLength));
    }
  }, [sideRiffTime, prevId, id, index, audioRef, length, dispatch, isPlaying]);

  const FindPosition = (oElement) => {
    const imgWidth = oElement.width;
    if (typeof (oElement.offsetParent) !== 'undefined') {
      for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
      return [posX, posY, imgWidth];
    }

    return [oElement.x, oElement.y, imgWidth];
  };
  const GetCoordinates = (e) => {
    if (isPlaying) {
      audioRef.current.pause(); 
      onPlay('');
      setWasPlaying(true);
      setWasInPlayAllMode(playAllMode);
      setPlayAllMode && setPlayAllMode(false);
    }
    let PosX = 0;
    let ImgPos;
    const waveImage = document.getElementById('waveFormImg'+index);
    ImgPos = FindPosition(waveImage);
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
      PosX = e.pageX;
    } else if (e.clientX || e.clientY) {
      PosX = e.clientX + document.body.scrollLeft
          + document.documentElement.scrollLeft;
    }
    PosX -= ImgPos[0];
    if (PosX < 0) PosX = 0;
    if ((PosX / ImgPos[2]) > 1) {
      PosX = ImgPos[2];
    }
    setSlider(PosX, ImgPos[2]);
  };
  const setSlider = useCallback((PosX, imageWidth) => {
    setImageWidth(`${100 - ((PosX / imageWidth) * 100)}%`);
    setMarginLeft(`${(PosX / imageWidth) * 100}%`);
    const currentTime = (PosX * length) / imageWidth;
    setRemainingTime(length - currentTime);
    audioRef.current.currentTime = currentTime;
  }, [audioRef, length]);

  const checkWasPlaying = useCallback(() => {
    if(canSliderMouseMove) {
      setSliderMoved(true);
    }
    if(wasPlaying && !canSliderMouseMove) {
      setSliderMoved(false);
      setWasPlaying(false);
      setCanPlay(true);
      onPlay(`${id}-${index.toString()}`);
      setPlayAllMode && setPlayAllMode(wasInPlayAllMode);
    }
  }, [wasPlaying, id, index, onPlay, canSliderMouseMove, wasInPlayAllMode, setPlayAllMode]);

  useEffect(() => {
    if(sliderMoved) {
      checkWasPlaying();
    }
  }, [checkWasPlaying, sliderMoved]);

  useEffect(() => {
    if(!canSliderMouseMove && canSetSideRiffSlider){
      dispatch(setRiffAudioRemainingTime(remainingTime));
      setCanSetSideRiffSlider(false);
    }
  }, [remainingTime, canSetSideRiffSlider, dispatch, canSliderMouseMove]);

  const sliderMouseDown = () => {
    setCanSetSideRiffSlider(true);
    setCanSliderMouseMove(true);
  };

  const sliderMouseUp = () => {
    setCanSliderMouseMove(false);
  };

  const sliderMouseMove = (e, canMove = false) => {
    setCanSliderMouseMove(true);
    if (canSliderMouseMove || canMove) {
      GetCoordinates(e);
    }
  };


  useEffect(() => {
    audioRef.current.onloadedmetadata = () => {
      if (audioRef.current) {
        const len = Math.round(audioRef.current.duration);
        setlength(len);
        setRemainingTime(len);
      }
    };
  }, [audioRef, setlength, setRemainingTime]);

  const handleOnPlay = () => {
    setCanPlay(true);
    onPlay(`${id}-${index.toString()}`);
    !isComment && dispatch(setRiffAudioLength(length));
    !isComment && dispatch(setRiffAudioRemainingTime(remainingTime));
  }

  const getAnimationTransition = (seconds) => {
    const transitionDuration = `${seconds}s`;
    return {
      WebkitTransition: transitionDuration,
      MozTransition: transitionDuration,
      msTransition: transitionDuration,
      OTransition: transitionDuration,
      transition: transitionDuration,
      transitionProperty: 'margin',
      transitionTimingFunction: 'linear',
    };
  };

  const getAnimationTransitionWaveForm = (seconds) => {
    const transitionDuration = `${seconds}s`;
    return {
      WebkitTransition: transitionDuration,
      MozTransition: transitionDuration,
      msTransition: transitionDuration,
      OTransition: transitionDuration,
      transition: transitionDuration,
      transitionProperty: 'clip-path',
      transitionTimingFunction: 'linear',
    };
  };

  useEffect(() => {
    if (!isPlaying) {
      audioRef.current.pause();
      // playAllMode && setPlayAllMode && setPlayAllMode(false);
    } else {
      if(!file || !length) {
        onPlay(next && playAllMode? `${next}-${(index + 1).toString()}`: '');
        !next && setPlayAllMode && setPlayAllMode(false);
        return;
      }
      audioRef.current.play();
    }
  }, [isPlaying, audioRef, next, playAllMode, index, onPlay, file, setPlayAllMode, length]);

  const stopAudio = () => {
    setRemainingTime(length);
    !isComment && dispatch(setRiffAudioRemainingTime(length));
    setMarginLeft('0%');
    setImageWidth('100%');
    onPlay(next && playAllMode? `${next}-${(index + 1).toString()}`: '');
    !next && setPlayAllMode && setPlayAllMode(false);
  };
  return (
    <div className={className || ''}>
      <Row className="no-gutters audio-container">
        <Col className="flex-grow-0">
          <Button
            className="riffButton rounded-circle d-flex justify-content-center align-items-center"
            disabled={!length}
            onClick={() => {
              if (!isPlaying) {
                audioRef.current.currentTime = length - remainingTime;
                audioRef.current.play();
              } else if (isPlaying) {
                audioRef.current.pause();
                setPlayAllMode && setPlayAllMode(false);
                onPlay('');
              }
            }}
            aria-hidden="true"
          >
            {
                        isPlaying ? <img src={pauseFilled} alt="pauseFilled" className={`pauseFilled ${isEcho? 'greyImg': ''}`} />
                          : <img src={isEcho? playGreyFilled: playFilled} alt="playFilled" className={`playFilled ${isEcho? 'greyImg': ''}`} />
                        }
          </Button>
        </Col>
        <Col className="offset-1 flex-grow-1 text-center">
          <div className="audio-container-bar">
            <div 
              style={{ width: '100%', position: 'relative', cursor: 'pointer' }}
              onMouseDown={(e) => [GetCoordinates(e), sliderMouseDown()]}
              onTouchStart={(e) => [GetCoordinates(e.changedTouches[0]), sliderMouseDown()]}
              onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
              onTouchEnd={sliderMouseUp}
            >
              <img
                src={waveformActive}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  top: 0,
                  bottom: 0,
                  clipPath: `inset(0% ${imageWidth} 0% 0%)`,
                  ...(isPlaying && canPlay) ? getAnimationTransitionWaveForm(remainingTime) : null,
                }}
                alt="waveformActive"
                className={`waveFormActive noSelect ${isPlaying ? 'waveFormActiveTransition' : ''}`}
              />
              <img src={waveform} id={"waveFormImg"+index} alt="waveform" className="waveForm noSelect" />
            </div>
            <div
              className={` audioBar ${isPlaying ? 'horizTranslate' : ''}`}
              onMouseDown={sliderMouseDown}
              onTouchStart={sliderMouseDown}
              onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
              onTouchEnd={sliderMouseUp}
              style={{
                position: 'absolute',
                cursor: canSliderMouseMove? 'grabbing': 'grab',
                marginLeft,
                ...(isPlaying && canPlay) ? getAnimationTransition(remainingTime) : null,
                backgroundColor: (remainingTime === length && !isPlaying) && 'transparent',
              }}
            />
            <div 
              className="position-absolute audioBarContainer" 
              onMouseEnter={sliderMouseDown} 
              onMouseLeave={sliderMouseUp}
              onMouseUp={sliderMouseUp} 
              onMouseMove={sliderMouseMove}
              // onTouchStart={sliderMouseDown}
              // onTouchEnd={sliderMouseUp}
              // onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
              style={!canSliderMouseMove? {zIndex: -1 }: {}} />
          </div>
        </Col>
        {!isEcho && <Col className="offset-1 flex-grow-0 timeContainer d-flex flex-row">
          {isYourRiff && <span className="yourRiff mr-2 d-none d-sm-none d-md-none d-lg-none d-xl-block">Your riff</span>}
          {date? (
            <>
              <span
                  className="app-inner-body light d-block"
              >
                {formatSeconds(length)}
              </span>
              
              <span
                className="pl-2 app-inner-body light d-none d-sm-none d-md-none d-lg-none d-xl-block"
              >
                {
                  date && formatDate(date)
                }
              </span>
            </>
          ): (
            <span
            className="app-inner-body light"
          >
            {formatSeconds(length)}
          </span>
          )}
        </Col>}
      </Row>
      <audio
        src={file}
        ref={audioRef}
        onEnded={stopAudio}
        onPlay={handleOnPlay}
        onPause={({ target }) => {
          setCanPlay(false);
          setTimeout(() => setCanPlay(true),100);
          !isComment && dispatch(setRiffIdName(""));
          if(canSetRemainingTime) {
            dispatch(setRiffAudioRemainingTime(length - target.currentTime));
            dispatch(setCanSetRemainingTime(false));
          }
          setRemainingTime(length - target.currentTime);
          const marginLeftInPercentage = `${(target.currentTime / length) * 100}%`;
          setMarginLeft(marginLeftInPercentage);
          setImageWidth(`${100 - (target.currentTime / length) * 100}%`);
          checkWasPlaying();
        }}
      />
    </div>
  );
};

AudioPlayer.propTypes = {
  file: PropTypes.string,
  waveform: PropTypes.node.isRequired,
  waveformActive: PropTypes.node.isRequired,
  audioRef: PropTypes.any.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  onPlay: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  next: PropTypes.string,
  isYourRiff: PropTypes.bool,
  isComment: PropTypes.bool,
  isEcho: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  file: '',
  waveform: null,
  waveformActive: null,
  audioRef: null,
  isPlaying: false,
  onPlay: null,
  id: '',
  className: '',
  isYourRiff: false,
  isComment: false,
  isEcho: false,
};

export default memo(AudioPlayer);
