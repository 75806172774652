import React, { useEffect, useState } from 'react';
import './homepage.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Scroll from 'react-scroll';
import logo from '../../assets/icons/riffrLogoWhite.png';
import downloadOnAppStore from '../../assets/images/downloadOnAppStore.png';
import downloadOnAppStoreMedium from '../../assets/images/downloadOnAppStore@2x.png';
import downloadOnAppStoreLarge from '../../assets/images/downloadOnAppStore@3x.png';
import getItOnGooglePlay from '../../assets/images/getItOnGooglePlay.png';
import getItOnGooglePlayMedium from '../../assets/images/getItOnGooglePlay@2x.png';
import getItOnGooglePlayLarge from '../../assets/images/getItOnGooglePlay@3x.png';
import mockup from '../../assets/images/riffrMockup.png';
import mockupMedium from '../../assets/images/riffrMockup@2x.png';
import mockupLarge from '../../assets/images/riffrMockup@3x.png';
import hear from '../../assets/icons/hear.png';
import community from '../../assets/icons/community.png';
import share from '../../assets/icons/share.png';
import constants from '../../utils/constants';
import Riff from '../../components/riff/riff';
import { publicHttpClient } from '../../utils/http/http';
import Footer from '../../components/footer/footer';

const elementIds = {
  listenToRiffs: 'list-to-riffs',
  howItWorks: 'how-it-works',
};

const Homepage = () => {
  const [riffs, setRiffs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(0);
  const { t } = useTranslation();
  const scrollHandler = () => {
    document.getElementById('homepage-nav').classList[
      window.scrollY > 20 ? 'add' : 'remove'
    ]('scrolled');
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return function cleanup() {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  useEffect(() => {
    async function getRiffs() {
      try {
        const { data } = await publicHttpClient.get('collection/Featured?pageLimit=6&page=2');
        setRiffs(data.result.slice(0, 6));
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    getRiffs();
  }, []);

  const scrollToElement = (elementId) => {
    Scroll.scroller.scrollTo(elementId, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: '',
      offset: -100,
    });
  };

  const renderHeader = () => (
    <>
      <ul className="nav" id="homepage-nav">
        <li className="nav-item flex-fill logo-item">
          <a className="nav-link nav-link-logo" href="/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </li>
        <Row className="no-gutters">
          <li className="nav-item d-none d-md-block">
            <span
              aria-hidden="true"
              className="nav-link clickable"
              onClick={() => scrollToElement(elementIds.howItWorks)}
            >
              <span className="align-middle">{t('homepage.howRiffrWorks')}</span>
            </span>
          </li>
          <li className="nav-item d-none d-lg-block">
            <span
              aria-hidden="true"
              className="nav-link clickable"
              onClick={() => scrollToElement(elementIds.listenToRiffs)}
            >
              {t('homepage.listenToRiffs')}
            </span>
          </li>
          <li className="nav-item justify-content-center">
            <a className="nav-link align-middle" href="/login">{t('homepage.loginOrSignup')}</a>
          </li>
        </Row>
      </ul>
    </>
  );

  const renderFirstSection = () => (
    <Row className="homepage-first-section no-gutters">
      <Col xs={11} md={6} lg={5} className="left-container">
        <h1 className="app-headline riffr-title">{t('riffr')}</h1>
        <h2 className="app-sub-header mt-3 mt-md-4 mt-sm-0 mb-4 mb-sm-0 social-micro">{t('homepage.socialMicro')}</h2>
        <h5 className="app-value mt-md-2 mb-4 mb-sm-0">{t('homepage.socialIntro')}</h5>
        <div className="stores-container">
          <Row>
            <Col className="flex-sm-grow-1 flex-md-grow-0">
              <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer">
                <img
                  src={downloadOnAppStoreLarge}
                  srcSet={`${downloadOnAppStore} 300w, ${downloadOnAppStoreMedium} 768w, ${downloadOnAppStoreLarge} 1280w`}
                  alt="appStore"
                  className="downloadOnAppStore"
                />
              </a>
            </Col>
            <Col className="no-padding flex-sm-grow-1 flex-md-grow-0">
              <a href={constants.riffrAndroidStore} target="_blank" rel="noopener noreferrer">
                <img
                  src={getItOnGooglePlay}
                  srcSet={`${getItOnGooglePlay} 300w, ${getItOnGooglePlayMedium} 768w, ${getItOnGooglePlayLarge} 1280w`}
                  alt="googleStore"
                  className="getItOnGooglePlay"
                />
              </a>
            </Col>
          </Row>
        </div>

      </Col>
      <Col md={6} lg={7} className="right-container align-self-end">
        <picture>
          <source media="(max-width: 767px)" srcSet={mockup} />
          <source media="(min-width: 1280px)" srcSet={mockupLarge} />
          <source media="(min-width: 768px)" srcSet={mockupMedium} />
          <img src={mockup} alt="mockup" className="float-right mockup" />
        </picture>
      </Col>
    </Row>
  );
  const renderHowItWorks = () => (
    <div className="homepage-how-it-works-container" id={elementIds.howItWorks}>
      <Row className="no-gutters">
        <Col xs={12} md={10} lg={8}>
          <h2 className="app-sub-header">{t('homepage.howRiffrWorks')}</h2>
          <h5 className="app-body light how-it-works-body">{t('homepage.justOpenYourFeed')}</h5>
        </Col>
      </Row>
      <Row className="how-it-works-content">
        <Col xs={12} md={6} lg={4}>
          <img src={hear} alt="hear" className="hear" />
          <h5 className="app-section-title">{t('homepage.hearGreatRiffs')}</h5>
          <h5 className="app-body light how-it-works-body">{t('homepage.hearGreatRiffsText')}</h5>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <img src={share} alt="share" className="share" />
          <h5 className="app-section-title">{t('homepage.shareYourVoice')}</h5>
          <h5 className="app-body light how-it-works-body">{t('homepage.shareYourVoiceText')}</h5>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <img src={community} alt="community" className="community" />
          <h5 className="app-section-title">{t('homepage.bePartOfTheCommunity')}</h5>
          <h5 className="app-body light how-it-works-body">{t('homepage.bePartOfTheCommunityText')}</h5>
        </Col>
      </Row>
    </div>
  );

  const renderListenToRiffs = () => (
    <div className="homepage-listen-container" id={elementIds.listenToRiffs}>
      <Row className="no-gutters">
        <Col xs={12} md={10} lg={8}>
          <h2 className="app-sub-header">{t('homepage.listenToRiffs')}</h2>
          <h5 className="app-value light">{t('homepage.ourCommunityText')}</h5>
        </Col>
      </Row>
      {
        loading ? <div className="text-center"><Spinner animation="border" /></div> : (
          <Row className="no-gutters">
            {riffs.map((riff, i) => (
              <Col
                className="no-padding riff-element"
                xs={12}
                md={6}
                lg={6}
                xl={4}
                key={riff.id}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Riff
                  name={riff.user.name}
                  description={riff.name}
                  commentCount={riff.commentCount}
                  playCount={riff.playCount}
                  echoCount={riff.echoCount}
                  likes={riff.likes}
                  length={riff.length}
                  file={riff.file}
                  onPlay={setAudioPlaying}
                  isPlaying={`${riff.id}-${i}` === audioPlaying}
                  data={riff}
                  tags={riff.tags}
                  index={i}
                />
              </Col>
            ))}
          </Row>
        )
      }
    </div>
  );

  const renderJoinRiffr = () => (
    <div className="homepage-join-container">
      <Col xs={12} md={5} lg={6} className="no-padding">
        <h2 className="app-sub-header">{t('homepage.downloadTheApp')}</h2>
        <Row className="no-gutters">
          <Col xs={12} className="flex-grow-0">
            <h5 className="app-value join-content">{t('homepage.joinRiffr')}</h5>
          </Col>
        </Row>
        <Row className="no-gutters">
          <Col className="flex-grow-0">
            <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer">
              <img
                src={downloadOnAppStore}
                srcSet={`${downloadOnAppStore} 300w, ${downloadOnAppStoreMedium} 768w, ${downloadOnAppStoreLarge} 1280w`}
                alt="appStore"
                className="downloadOnAppStore"
              />
            </a>
          </Col>
          <Col>
            <a href={constants.riffrAndroidStore} target="_blank" className="getItOnGooglePlay" rel="noopener noreferrer">
              <img
                src={getItOnGooglePlay}
                srcSet={`${getItOnGooglePlay} 300w, ${getItOnGooglePlayMedium} 768w, ${getItOnGooglePlayLarge} 1280w`}
                alt="googleStore"
                className="getItOnGooglePlay"
              />
            </a>
          </Col>
        </Row>
      </Col>
    </div>
  );

  return (
    <div className="homepage-container">
      {renderHeader()}
      <div className="homepage-content">
        {renderFirstSection()}
        {renderHowItWorks()}
        {renderListenToRiffs()}
        {renderJoinRiffr()}
        <Footer />
      </div>
    </div>
  );
};

export default Homepage;
