import constants from '../../utils/constants';
import {
  RESET_AUDIO, SEARCH_HASH_TAGS, SET_IS_DRAFT, SET_AUDIO_FILE, SET_AUDIO_LENGTH, SET_MIC_RECORDER, SET_UPLOAD_MODE, ADD_RIFF, GET_DRAFTS, DELETE_DRAFT, SET_SUCCESSFULLY_POSTED,
} from '../constants/recordConstants';

export const setAudioFile = (audioFile, blob, buffer) => ({
  type: SET_AUDIO_FILE,
  payload: {
    audioFile,
    blob,
    buffer,
  },
});

export const setMicRecorder = (micRecorder) => ({
  type: SET_MIC_RECORDER,
  payload: {
    micRecorder,
  },
});

export const resetAudio = () => ({
  type: RESET_AUDIO,
});

export const setAudioLength = (audioLength) => ({
  type: SET_AUDIO_LENGTH,
  payload: {
    audioLength,
  },
});

export const setUploadMode = (isUploadMode) => ({
  type: SET_UPLOAD_MODE,
  payload: {
    isUploadMode,
  },
});

export const setSuccessfullyPosted = (successfullyPosted) => ({
  type: SET_SUCCESSFULLY_POSTED,
  payload: {
    successfullyPosted
  }
});

export const setIsDraft = (isDraft) => ({
  type: SET_IS_DRAFT,
  payload: {
    isDraft
  }
});

export const searchHashTags = (s, page) => ({
  type: SEARCH_HASH_TAGS,
  payload: {
    request: {
      url: 'hashtag/search',
      method: 'get',
      authenticated: false,
      params: {
        pageLimit: constants.hashTagLimit,
        page,
        s,
      },
    },
  },
});

export const getDrafts = (page) => ({
  type: GET_DRAFTS,
  payload: {
    request: {
      url: 'user/riff/draft',
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: constants.draftsLimit,
        page,
      },
    },
    page
  },
});

export const addRiff = (data) => ({
  type: ADD_RIFF,
  payload: {
    request: {
      url: `${constants.uploadUrl}/riff`,
      method: 'post',
      authenticated: true,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  },
});

export const updateRiff = (data, id) => ({
  type: ADD_RIFF,
  payload: {
    request: {
      url: `${constants.uploadUrl}/riff`,
      method: 'post',
      authenticated: true,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  },
});

export const deleteDraft = (id) => ({
  type: DELETE_DRAFT,
  payload: {
    request: {
      url: `riff/${id}`,
      method: 'delete',
      authenticated: true,
    },
    id
  },
});
