import React from 'react';
import PropTypes, { element } from 'prop-types';
import './pageContent.scss';

const Page = ({ header, children, hideMargin }) => (
  <div>
    {header}
    <div className={`page-content ${hideMargin} ${!hideMargin ? 'mt-3 mt-sm-5' : ''}`}>
      {children}
    </div>
  </div>
);

Page.propTypes = {
  header: element.isRequired,
  children: PropTypes.oneOfType([element, PropTypes.arrayOf(element)]).isRequired,
  hideMargin: PropTypes.bool,
};

Page.defaultProps = {
  hideMargin: false,
};

export default Page;
