import { combineReducers } from 'redux';
import trumpReducer from './trumpReducer';
import bidenReducer from './bidenReducer';
import voteReducer from './voteReducer';

export default combineReducers({
  trump: trumpReducer,
  biden: bidenReducer,
  vote: voteReducer,
});
