import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const ProtectedRoute = ({ path, component }) => {
  const authentication = useSelector((state) => state.authentication);
  const isAuthenticated = authentication.access_token !== null;

  return isAuthenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default ProtectedRoute;
