import { merge } from 'lodash';
import { normalize, schema } from 'normalizr';
import { FOLLOW_SUCCESS } from '../constants/connectionsConstants';
import {
  CLEAR_UPDATE_USER,
  GET_USER,
  GET_USER_BY_ID,
  GET_USER_BY_ID_FAIL,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_FAIL,
  GET_USER_RIFF,
  GET_USER_RIFF_FAIL,
  GET_USER_RIFF_SUCCESS,
  GET_USER_SUCCESS,
  SET_FIRST_LOGIN,
  SUSPEND_ACCOUNT,
  SUSPEND_ACCOUNT_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from '../constants/userConstants';

const initialState = {
  result: null,
  loading: false,
  suspended: false,
  error: null,
  firstLogin: false,
  loadingRiff: false,
  resultRiff: null,
  errorRiff: null,
  byId: {},
  allIds: [],
  hasMore: false,
  updateUser: {
    result: null,
    loading: false,
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FOLLOW_SUCCESS: 
      if(state.result) {
        const { isFollow } = action.payload.config.reduxSourceAction.payload;
        return {
          ...state,
          result: {...state.result, actions: {...state.result.actions, follower: isFollow}}
        }
      }
      return state;
    case GET_USER_BY_ID:
    case GET_USER:
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
        updateLoading: true,
        updateResult: null,
        updateError: null,
      };
    case GET_USER_BY_ID_SUCCESS:
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload.data,
      };
    case GET_USER_BY_ID_FAIL:
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: {
          result: null,
          loading: true,
          error: null,
        },
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          result: 'success',
          loading: false,
          error: null,
        },
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        updateUser: {
          result: null,
          loading: false,
          error: action.payload,
        },
      };
    case CLEAR_UPDATE_USER:
      return {
        ...state,
        updateUser: {
          result: null,
          loading: false,
          error: null,
        },
      };
    case SET_FIRST_LOGIN:
      return {
        ...state,
        firstLogin: action.payload,
      };
    case GET_USER_RIFF: {
      const { page } = action.payload.request.params;
      const isFirstPage = page === 1;
      return {
        ...state,
        loadingRiff: true,
        resultRiff: null,
        errorRiff: null,
        byId: isFirstPage ? {} : state.byId,
        allIds: isFirstPage ? [] : state.allIds,
      };
    }
    case GET_USER_RIFF_SUCCESS: {
      const { data } = action.payload;
      const riff = new schema.Entity('riff');
      const Schema = { riffs: [riff] };
      const normalizedData = normalize({ riffs: data.result }, Schema);
      return {
        ...state,
        loadingRiff: false,
        resultRiff: action.payload.data,
        hasMore: action.payload.data.currentPage < action.payload.data.totalPages,
        byId: merge({}, state.byId, normalizedData.entities.riff),
        allIds: action.payload.data.result ? state.allIds.concat(normalizedData.result.riffs) : state.allIds,
      };
    } case GET_USER_RIFF_FAIL:
      return {
        ...state,
        loadingRiff: false,
        errorRiff: action.error,
      };
    case SUSPEND_ACCOUNT: 
      return {
        ...state,
        loading: true
      }
    case SUSPEND_ACCOUNT_SUCCESS: 
      return {
        ...state,
        loading: false,
        suspended: true,
      }

    default:
      return state;
  }
};

export default reducer;
