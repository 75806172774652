import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar'
import Page from '../../components/pageContent/pageContent'
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent'
import PageTitle from '../../components/pageTitle/pageTitle'
import SearchInput from '../../components/searchInput/searchInput'
import { getUserBlocked, getUserMuted, unMuteUser, unBlockUser, unBlockUserSuccess, unMuteUserSuccess } from '../../store/actions/blockedMutedActions'
import constants from '../../utils/constants'
import cloudyNightGrey from '../../assets/icons/cloudyNightGrey@3x.png'
import './blockedMuted.scss'
import InfiniteScroll from 'react-infinite-scroll-component'

export default function BlockedMuted() {
    const { t } = useTranslation();
    const [isBlocked, setIsBlocked] = useState(true);
    const [searchTxt, setSearchTxt] = useState('');
    const {loading, blockedUsers, mutedUsers, resultMuted, resultBlocked, userLoading} = useSelector(state => state.blockedMuted)
    const dispatch = useDispatch();
    const loadingSpinner = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;
    useEffect(() => {
        dispatch(getUserBlocked(1, ''));
        dispatch(getUserMuted(1, ''));
    }, [dispatch]);

    const onSearch = (search) => {
        dispatch(getUserBlocked(1, search));
        dispatch(getUserMuted(1, search));
    }

    const onClick = (id) => {
        if(isBlocked) {
            dispatch(unBlockUser(id)).then(() => dispatch(unBlockUserSuccess(id)));
        } else {
            dispatch(unMuteUser(id)).then(() => dispatch(unMuteUserSuccess(id)));
        }
    }

    const hasData = () => (
        !((isBlocked && !blockedUsers.length) || (!isBlocked && !mutedUsers.length))
    )

    const content = isBlocked? blockedUsers: mutedUsers;
    const contentResult = isBlocked? resultBlocked: resultMuted;

    return (
        <Page header={<AuthenticatedNavBar activeKey="profile" />} hideMargin>
            <Row className="no-gutters mt-4">
                <Col xs={1} sm={2} md={3}>
                    <PageLeftContent title={t('globals.backToSettings')} navigateToRef={constants.screens.settings} />
                </Col>
                <Col xs={10} sm={8} md={6} lg={4}>
                    <PageTitle>
                        <div className="d-flex flex-row justify-content-start align-items-center pt-0 pt-md-1 mb-4">
                            <h4 onClick={() => setIsBlocked(true)} className={`m-0 clickable ${isBlocked? 'blockedMutedSelected': ''}`}> {t('settings.blocked')} </h4>
                            <div className="blockedMutedSeparator" />
                            <h4 onClick={() => setIsBlocked(false)} className={`m-0 clickable ${isBlocked? '': 'blockedMutedSelected'}`}> {t('settings.muted')} </h4>
                        </div>
                    </PageTitle>
                    <SearchInput placeholder={t(isBlocked? 'settings.searchBlocked': 'settings.searchMuted')} searchTxt={searchTxt} setSearchTxt={setSearchTxt} onSearch={debounce(onSearch, 500)} />
                    { !hasData()? <div className="w-100 h-100 d-flex flex-column justify-content-end align-items-center">
                        {
                            loading? loadingSpinner: (
                                <div className="d-flex flex-column justify-content-center align-items-center text-center">
                                    <img src={cloudyNightGrey} alt="cloudyNightGrey" width="50" height="44" />
                                    <h5 className="m-0 bold mt-3">{t(isBlocked? 'settings.noBlockedUsers': 'settings.noMutedUsers')}</h5>
                                    <h5 className="m-0 mt-2 blockedMutedEmptyTxt">{t(isBlocked? 'settings.noBlockedUsersMsg': 'settings.noMutedUsersMsg')}</h5>
                                </div>
                            )
                        }
                    </div>: (
                        <>
                        {content && content.length !== 0 && (
                            <InfiniteScroll
                                className="blockedMutedConttainer w-100 mb-4"
                                dataLength={content.length}
                                next={() => dispatch(isBlocked? getUserBlocked(contentResult.currentPage + 1, searchTxt): getUserMuted(contentResult.currentPage + 1, searchTxt))}
                                hasMore={contentResult.currentPage < contentResult.totalPages}
                                loader={loadingSpinner}
                            >
                                {content.map((record, index) => record && (
                                    <div key={index} className="blockedMutedConttainer-row" style={index === content.length - 1? {borderBottomWidth: 0}: {}}>
                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                            <h5 className="m-0 bold">{record.first_name} {record.last_name} </h5>
                                            <Button
                                                size="sm"
                                                variant="outline-primary"
                                                className="app-value blockedMutedConttainer-btn"
                                                onClick={() => onClick(record.id)}
                                                disabled={userLoading[record.id]}
                                            >
                                                {t(`settings.${isBlocked? 'unblock': 'unmute'}`) + (userLoading[record.id] ? '...' : '')}
                                            </Button>
                                        </div>
                                        <h6 className="m-0 blockedMutedConttainer-slug bold">@{record.slug}</h6>
                                        <h6 className="m-0 mt-2 text-break">{record.bio}</h6>
                                    </div>
                                ))}
                            </InfiniteScroll>
                            )}
                        </>
                    )}
                </Col>
            </Row>
        </Page>
    )
}
