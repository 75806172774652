import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import searchIcon from '../../assets/icons/searchGrey.png';
import { searchHashTags } from '../../store/actions/recordActions';
import remove from '../../assets/icons/remove.png';
import './searchTag.scss';
import { debounce } from 'lodash';

export default function SearchTag({ setSelectedTags, selectedTags, setShowTagInfo }) {
  const [value, setValue] = useState('');
  const [showList, setShowList] = useState(false);
  const dispatch = useDispatch();
  const record = useSelector((state) => state.record);
  const { hashTags, loadingTags } = record;
  const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;
  const addTag = (name) => {
    let n = name;
    while(n.charAt(0) === '#' || n.charAt(0) === ' ')
      {
        n = n.substring(1);
      }
    setSelectedTags([...selectedTags, n]);
    setValue('');
  };
  useEffect(() => {
    if(loadingTags){
      setShowList(true);
    }
  }, [loadingTags]);
  const onSearch = (search) => {
    let s = search;
    while(s.charAt(0) === '#' || s.charAt(0) === ' ')
      {
        s = s.substring(1);
      }
    
    s && search.length && dispatch(searchHashTags(s, 1));
  }
  return (
    <Col className="flex-fill p-0">
      <Row className="no-gutters">
        <div className="container">
          <div className="row">
            {selectedTags.map((tags, i) => (
              <div className="col hashTag d-flex flex-row justify-content-between align-items-center mr-3 mb-3" key={i}>
                #
                {tags}
                <img
                  className="ml-3 clickable"
                  onClick={() => {
                    setSelectedTags(selectedTags.filter((tag, j) => i !== j));
                  }}
                  src={remove}
                  alt="-"
                />
              </div>
            ))}
          </div>
        </div>
      </Row>
      <Row className="no-gutters">
        <div className="input-group w-100 position-relative">
          <div className="input-group-prepend">
            <div className={`input-group-text searchTagIcon pr-0 ${showList && value.length ? 'imgContainerShow' : ''}`}>
              <img width="22" height="22" src={searchIcon} alt="@" />
            </div>
          </div>
          <input
            onFocus={() => setShowList(true)}
            onBlur={() => {
              setTimeout(() => setShowList(false), 1000);
            }}
            type="text"
            value={value}
            onChange={(e) => {
              if(e.target.value.charAt(e.target.value.length - 1) === '#' && value === '#') return;
              setShowTagInfo(false);
              const search = e.target.value;
              if (search.length < 30) {
                setValue(search);
                debounce(onSearch, 500)(search);
              }
            }}
            className={`form-control searchInputTag searchInput${showList && value.length ? 'Show' : ''}`}
            disabled={selectedTags.length >= 3}
            placeholder={selectedTags.length >= 3 ? 'You’ve added 3 topics' : 'Start searching for tags'}
            aria-describedby="basic-addon1"
          />
          {showList && value.length
            ? (
              <div className="position-absolute inputList">
                {!loadingTags? (
                  <>
                      {
                                    hashTags && hashTags.map((tag) => (
                                      <div key={tag.id} className="mb-0 d-flex flex-row justify-content-between align-items-center listRow">
                                        <p className="mb-0">
                                          #
                                          {tag.name}
                                        </p>
                                        <p
                                          onClick={() => addTag(tag.name)}
                                          className="mb-0 clickable listRowSelect"
                                        >
                                          Select
                                        </p>
                                      </div>
                                    ))
                                }
                    <div className="mb-0 d-flex flex-row justify-content-between align-items-center listRow" style={{borderBottom: 'none'}}>
                      <p className="mb-0">
                        {value.charAt(0) === '#'? '':'#'}
                        {value}
                      </p>
                      <p
                        onClick={() => addTag(value)}
                        className="mb-0 clickable listRowSelect"
                      >
                        Create
                      </p>
                    </div>
                  
                  </>
                ): loading}
              </div>
            ): null}
        </div>
      </Row>
    </Col>
  );
}
