const GET_RIFFS = 'GET_RIFFS';
const GET_RIFFS_SUCCESS = 'GET_RIFFS_SUCCESS';
const GET_RIFFS_FAIL = 'GET_RIFFS_FAIL';
const GET_RIFFS_BY_ID = 'GET_RIFFS_BY_ID';
const GET_RIFFS_BY_ID_SUCCESS = 'GET_RIFFS_BY_ID_SUCCESS';
const GET_RIFFS_BY_ID_FAIL = 'GET_RIFFS_BY_ID_FAIL';
const SET_LOADING = 'SET_LOADING';
const ADD_RIFF_COMMENT = 'ADD_RIFF_COMMENT';
const ADD_RIFF_COMMENT_SUCCESS = 'ADD_RIFF_COMMENT_SUCCESS';
const ADD_RIFF_COMMENT_FAIL = 'ADD_RIFF_COMMENT_FAIL';
const GET_RIFF_COMMENTS = 'GET_RIFF_COMMENTS';
const GET_RIFF_COMMENTS_SUCCESS = 'GET_RIFF_COMMENTS_SUCCESS';
const GET_RIFF_COMMENTS_FAIL = 'GET_RIFF_COMMENTS_FAIL';

export {
  GET_RIFFS, GET_RIFFS_SUCCESS, GET_RIFFS_FAIL,
  GET_RIFFS_BY_ID, GET_RIFFS_BY_ID_SUCCESS, GET_RIFFS_BY_ID_FAIL,
  SET_LOADING,
  ADD_RIFF_COMMENT,
  ADD_RIFF_COMMENT_SUCCESS,
  ADD_RIFF_COMMENT_FAIL,
  GET_RIFF_COMMENTS,
  GET_RIFF_COMMENTS_SUCCESS,
  GET_RIFF_COMMENTS_FAIL
};
