import React, { createRef, useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import constants from '../../utils/constants';
import deleteCircle from '../../assets/icons/deleteCircle@3x.png';
import playCircleOrange from '../../assets/icons/playCircleOrange@3x.png';
import pauseFilled from '../../assets/icons/pauseButton.png';
import './drafts.scss';
import { deleteDraft, getDrafts } from '../../store/actions/recordActions';
import { formatDate1 } from '../../utils/common';

export default function Drafts({history}) {
  const { t } = useTranslation();
  const [playingId, setPlayingId] = useState('');
  const [confrimDelete, setConfrimDelete] = useState(-1);
  const [submitDelete, setSubmitDelete] = useState(false);
  const audioRef = createRef();
  const { loading, drafts } = useSelector((state) => state.record);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDrafts(1));
  }, [dispatch]);
  useEffect(() => {
    if (!loading && submitDelete) {
      dispatch(getDrafts(drafts.currentPage));
      setSubmitDelete(false);
    }
  }, [loading, submitDelete, dispatch, drafts.currentPage]);
  const handleClick = (e) => {
    if (!(document.getElementById('draftContainer') && document.getElementById('draftContainer').contains(e.target))) {
      setConfrimDelete(-1);
    }
  }
  window.addEventListener('click', handleClick);
  const loadingDrafts = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;
  return (
    <Page header={<AuthenticatedNavBar activeKey="record" />} hideMargin>
      <Row className="no-gutters mt-4">
        <Col xs={1} sm={3} lg={4} className="flex-fill">
          <PageLeftContent title={t('record.cancel')} navigateToRef={constants.screens.record} />
        </Col>
        <Col xs={10} sm={5} lg={4}>
          {
                        drafts.result && drafts.result.length ? (
                          <>
                            <PageTitle>{t('record.drafts')}</PageTitle>
                            <InfiniteScroll
                              className="infiniteScroll"
                              dataLength={drafts.result && drafts.result.length}
                              next={() => !loading && dispatch(getDrafts(drafts.currentPage + 1))}
                              hasMore={drafts.currentPage < drafts.totalPages}
                              loader={loadingDrafts}
                            >
                              <div className="draftContainer w-100 p-0" id="draftContainer">
                                {
                                            drafts.result.map((draft, i) => (
                                              <div key={i} className="d-flex justify-content-between align-items-center p-3 draftRow" style={i === drafts.result.length - 1 ? { borderWidth: 0 } : {}}>
                                                <p className="m-0 font-weight-bold dateTxt flex-grow-1 clickable" onClick={() => [window.removeEventListener('click', handleClick) ,history.push(constants.screens.tagPost + '/' + draft.id)]}>{formatDate1(draft.dateCreated)}</p>
                                                <div className="d-flex flex-row">
                                                  <img
                                                    className="clickable draftsIcon"
                                                    onClick={() => {
                                                      audioRef.current.src = draft.file;
                                                      audioRef.current.play();
                                                      setPlayingId(draft.id);
                                                    }}
                                                    src={playCircleOrange}
                                                    alt="play"
                                                    hidden={playingId === draft.id}
                                                  />
                                                  <img
                                                    className="clickable draftsIcon"
                                                    onClick={() => {
                                                      audioRef.current.pause();
                                                      audioRef.current.src = '';
                                                      setPlayingId(-1);
                                                    }}
                                                    src={pauseFilled}
                                                    alt="pause"
                                                    hidden={playingId !== draft.id}
                                                  />
                                                  <div className="position-relative">
                                                    <img className="clickable ml-3 draftsIcon" onClick={() => setConfrimDelete(draft.id)} src={deleteCircle} alt="delete" />
                                                    <div hidden={confrimDelete !== draft.id} className="gg-shape-triangle" />
                                                    <div hidden={confrimDelete !== draft.id} onClick={() => [setSubmitDelete(true), dispatch(deleteDraft(draft.id))]} className="clickable position-absolute confirmDelete">
                                                      {' '}
                                                      {t('record.confirmDelete')}
                                                      {' '}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ))
                                        }
                              </div>
                            </InfiniteScroll>
                          </>
                        ) : (
                          <div className="text-center">
                            <PageTitle>{t('record.noAvailableDraft')}</PageTitle>
                          </div>
                        )
                    }
        </Col>
        <div hidden={!loading} className="draftsLoader">
          <Spinner animation="border" className="spinner" />
        </div>
        <audio
          ref={audioRef}
          onEnded={() => setPlayingId(-1)}
          hidden
        />
      </Row>
    </Page>
  );
}
