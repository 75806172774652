import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import constants from '../../utils/constants';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import { clearUpdateUser, getUser, updateUser } from '../../store/actions/userActions';
import Page from '../../components/pageContent/pageContent';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import UpdateProfileForm from '../../components/updateProfileForm/updateProfileForm';
import PageTitle from '../../components/pageTitle/pageTitle';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import './editProfile.scss';

const EditProfile = ({ history }) => {
  const { t } = useTranslation();
  const form = React.createRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.loading && user.result === null) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => () => {
    dispatch(clearUpdateUser());
  }, [dispatch]);

  useEffect(() => {
    if (user.updateUser.result || user.updateUser.error) {
      history.goBack();
    }
  }, [user, history]);

  const onSubmit = async (values) => {
    const { handle, ...rest } = values;
    dispatch(updateUser(rest));
  };
  return (
    <Page header={<AuthenticatedNavBar activeKey="profile" />}>
      <Row className="no-gutters">
        <Col xs={1} sm={3} lg={4} className="flex-fill">
          <PageLeftContent title={t('globals.cancel')} navigateToRef={constants.screens.profile} />
        </Col>
        <Col xs={10} sm={5} lg={4}>
          <PageTitle>{t('globals.editProfile')}</PageTitle>
          {user.loading && <div className="text-center"><Spinner animation="border" className="spinner" /></div>}
          {user.result && (
            <>
              <ErrorBoundary>
                <UpdateProfileForm
                  formReference={form}
                  onSubmit={onSubmit}
                  initialValues={{
                    first_name: user.result.first_name, last_name: user.result.last_name, handle: `@${user.result.handle}`, bio: user.result.bio,
                  }}
                />
              </ErrorBoundary>
              <Row className="no-gutters align-items-center">
                <div className="flex-fill">
                  <Button
                    variant="outline-primary editProfileBtn"
                    className="submit-button app-value float-right"
                    disabled={user.updateUser.loading}
                    onClick={() => form.current.submitForm()}
                  >
                    {t('globals.saveEdits')}
                    {user.updateUser.loading && <Spinner animation="border ml-2" className="spinner spinner-border-sm" />}
                  </Button>
                </div>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Page>
  );
};

EditProfile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default EditProfile;
