import { ADD_RIFF_COMMENT, GET_RIFFS, GET_RIFFS_BY_ID, GET_RIFF_COMMENTS, SET_LOADING } from '../constants/hompageConstants';
import constants from '../../utils/constants';

/**
 * @param {number} page
 * @param {string} sort
 * @param {string} tag
 */
// eslint-disable-next-line import/prefer-default-export
export const getRiffs = (page, sort, tag) => ({
  type: GET_RIFFS,
  payload: {
    request: {
      url: `/riff${tag ? `/tag/${tag}` : ''}`,
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: constants.homepageRiffsLimit,
        page,
        sort,
      },
    },
  },
});

/**
 * @param {string} id
 */
export const getRiffById = (id) => ({
  type: GET_RIFFS_BY_ID,
  payload: {
    request: {
      url: `/riff/${id}`,
      method: 'get',
      authenticated: false,
    },
  },
});

export const setLoading = (bool) => ({
  type: SET_LOADING,
  payload: bool,
});

export const addRiffAudioComment = (data, riffId) => ({
  type: ADD_RIFF_COMMENT,
  payload: {
    request: {
      url: `${constants.uploadUrl}/riff/${riffId}/riffComment`,
      method: 'post',
      authenticated: true,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
    params: {
      riffId,
    }
  },
});

export const getRiffComments = (riffId, page, limit) => ({
  type: GET_RIFF_COMMENTS,
  payload: {
    request: {
      url: `riff/${riffId}/comments`,
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: limit || constants.riffCommentsLimit,
        page,
      },
    },
    params: {
      page,
      riffId
    }
  },
});