import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import ConfirmationDialog from '../../components/confirmationDialog/confirmationDialog';
import Page from '../../components/pageContent/pageContent'
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import { clearAuthentication } from '../../store/actions/authenticationActions';
import { suspendAccount } from '../../store/actions/userActions';
import constants from '../../utils/constants';
import './suspendAccount.scss'

export default function SuspendAccount({history}) {
    const { t } = useTranslation();
    const {loading, suspended} = useSelector(state => state.user);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if(suspended) {
            dispatch(clearAuthentication());
            history.push('/');
        }
    }, [suspended, dispatch, history])

    return (
        <Page header={<AuthenticatedNavBar activeKey="profile" />} hideMargin>
            <ConfirmationDialog
                show={showConfirmationDialog}
                onHide={() => setShowConfirmationDialog(false)}
                handleClose={() => setShowConfirmationDialog(false)}
                onConfirm={() => dispatch(suspendAccount())}
                title={t('settings.suspendYourAccount')}
            />
            <Row className="no-gutters mt-4">
                <Col xs={1} sm={2} md={3}>
                    <PageLeftContent title={t('globals.backToSettings')} navigateToRef={constants.screens.settings} />
                </Col>
                <Col xs={10} sm={8} md={6} lg={3} >
                    <PageTitle>{t('settings.suspendMyAccount')}</PageTitle>
                    <h6 className="m-0 mt-3 suspendingDef"> {t('settings.suspendingDef1')} </h6>
                    <h6 className="m-0 mt-3 suspendingDef"> {t('settings.suspendingDef2')} </h6>
                    <h6 className="m-0 mt-3 suspendingDef"> {t('settings.suspendingDef3')} </h6>
                    <Button 
                            variant="outline-primary" 
                            className="mt-4 suspendBtn d-flex flex-row justify-content-center align-items-center" 
                            onClick={() => setShowConfirmationDialog(true)}
                            disabled={loading}
                            >
                        {t('settings.suspendMyAccount')}
                        {loading && <Spinner animation="border" className="ml-3 spinner spinner-border-sm" />}
                    </Button>
                </Col>
            </Row>
        </Page>
    )
}
