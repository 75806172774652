import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from '../../components/footer/footer';
import logo from '../../assets/icons/riffrLogoWhite.png';
import person from '../../assets/icons/person.png';
import './login.scss';
import constants from '../../utils/constants';
import LoginForm from '../../components/loginForm/loginForm';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import { authenticate } from '../../services/auth';
import { handleError } from '../../utils/http/http';
import { saveAuthentication } from '../../store/actions/authenticationActions';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';

const Login = ({ history }) => {
  const { t } = useTranslation();
  const form = React.createRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const onLogin = async (values) => {
    try {
      setLoading(true);
      setError(null);
      const response = await authenticate(values.username, values.password);
      setLoading(false);
      if (response.error_description) {
        setError(handleError(response, t));
      } else {
        dispatch(saveAuthentication(response));
        setError(null);
        history.push(constants.screens.home);
      }
    } catch (e) {
      setError(t('globals.somethingWentWrong'));
      setLoading(false);
    }
  };
  return (
    <div className="authentication-screen login-screen">
      <ul className="nav" id="homepage-nav">
        <li className="nav-item flex-fill m-auto">
          <a className="nav-link nav-link-logo" href="/">
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </li>
        <Row className="no-gutters">
          <li className="nav-item">
            <a className="nav-link disabled" href="/">
              <Row className="no-gutters align-items-center">
                <img src={person} alt="person" width="20" height="20" />
                <span className="bold">{t('authentication.login')}</span>
              </Row>
            </a>
          </li>
        </Row>
      </ul>
      <div className="page-content">
        <Row className="no-gutters">
          <Col xs={1} sm={3} lg={4} className="flex-fill">
            <PageLeftContent title={t('globals.goBack')} navigateToRef={constants.screens.landing} />
          </Col>
          <Col xs={10} sm={5} lg={4}>
            <h4 className="app-section-header bold">{t('authentication.loginToRiffr')}</h4>
            <h6 className="app-section-sub-header light">{t('authentication.alreadyGotAnAccount')}</h6>
            {error && (
              <Alert variant="danger">
                {error}
              </Alert>
            )}
            <ErrorBoundary>
              <LoginForm formReference={form} onSubmit={onLogin} />
            </ErrorBoundary>
            <Row className="no-gutters align-items-center mt-4 mt-sm-5">
              <a href={constants.screens.forgotPassword} className="forgot-password app-body">
                {t('authentication.forgotPassword?')}
              </a>
              <div className="flex-fill">
                <Button
                  variant="outline-primary"
                  className="submit-button app-value float-right btn-lg d-none d-sm-block"
                  disabled={loading}
                  onClick={() => form.current.submitForm()}
                >
                  {t('authentication.login')}
                  {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
                </Button>
              </div>
            </Row>
            <Button
              variant="outline-primary"
              className="submit-button submit-button-full app-value d-block d-sm-none"
              disabled={loading}
              onClick={() => form.current.submitForm()}
            >
              {t('authentication.login')}
              {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
            </Button>
            <div className="signup-text-container mt-4 mt-sm-5">
              <span className="primary-text app-value">
                {`${t('authentication.newToRiffr')} `}
                <a href={constants.screens.signUp}>
                  {t('authentication.signUpInstead')}
                </a>
              </span>
            </div>
          </Col>
        </Row>
        <div className="authentication-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default Login;
