import { GET_USER_BLOCKED, GET_USER_MUTED, UNBLOCK_USER, UNBLOCK_USER_SUCCESS, UNMUTE_USER, UNMUTE_USER_SUCCESS } from "../constants/blockedMutedConstants";

export const getUserBlocked = (page, search) => ({
    type: GET_USER_BLOCKED,
    payload: {
      request: {
        url: `user/blocked`,
        method: 'get',
        authenticated: true,
        params: {
            page,
            pageLimit: 20,
            search,
        },
      },
      page
    },
});

export const getUserMuted = (page, search) => ({
    type: GET_USER_MUTED,
    payload: {
      request: {
        url: `user/muted`,
        method: 'get',
        authenticated: true,
        params: {
            page,
            pageLimit: 20,
            search,
        },
      },
      page
    },
});

export const unBlockUser = (userId) => ({
  type: UNBLOCK_USER,
  payload: {
    request: {
      url: `user/${userId}/unblock`,
      method: 'post',
      authenticated: true,
    },
    userId
  },
});

export const unBlockUserSuccess = (userId) => ({
  type: UNBLOCK_USER_SUCCESS,
  payload: {
    userId
  },
});

export const unMuteUser = (userId) => ({
  type: UNMUTE_USER,
  payload: {
    request: {
      url: `user/${userId}/unmute`,
      method: 'post',
      authenticated: true,
    },
    userId
  },
});

export const unMuteUserSuccess = (userId) => ({
  type: UNMUTE_USER_SUCCESS,
  payload: {
    userId
  },
});