import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import './recorder.scss';
import MicRecorder from 'mic-recorder-to-mp3';
import { useDispatch, useSelector } from 'react-redux';
import Timer from 'react-compound-timer/build';
import { useTranslation } from 'react-i18next';
import pauseLarge from '../../assets/icons/pauseLarge@3x.png';
import startButton from '../../assets/icons/startButton@3x.png';
import resumeButton from '../../assets/icons/resumeButton@3x.png';
import {
  resetAudio, setAudioFile, setAudioLength, setMicRecorder,
} from '../../store/actions/recordActions';
import constants from '../../utils/constants';
import { mapMinMax } from '../../utils/common';
import AudioRect from '../audioRect/audioRect';
import AudioWave from '../audioWave/audioWave';

export default function Recorder({
  recordingStatus,
  setRecordingStatus,
  counterRef,
  isBtnDisabled,
  setIsBtnDisabled,
  disabledPause,
  setDisabledPause,
  startedRecording,
  setStartedRecording,
  amplitude,
  setAmplitude,
  buffer, 
  setBuffer,
}) {
  const { t } = useTranslation();
  const recordState = useSelector((state) => state.record);
  const [averageAmp, setAverageAmp] = useState(0);
  const { micRecorder } = recordState;
  const dispatch = useDispatch();

  const record = () => {
    if (recordingStatus === 'Start' && !micRecorder) {
      const recorder = new MicRecorder({
        bitRate: 128,
      });
      recorder.start().then((stream) => {
        setStartedRecording(true);
        setAmplitude(5);
        setIsBtnDisabled(true);
        setTimeout(() => {
          counterRef.current.start();
          setIsBtnDisabled(false);
          setRecordingStatus('Pause');
          subscribeToMic(stream);
        }, 500);
      }).catch((e) => {
        alert('This feature requires MICROPHONE PERMISSION');
      });
      dispatch(setMicRecorder(recorder));
    } else {
      micRecorder.start().then((stream) => {
        if(recordingStatus === 'Start') {
          setStartedRecording(true);
          setAmplitude(5);
        }
        setIsBtnDisabled(true);
        setTimeout(() => {
          counterRef.current.start();
          setIsBtnDisabled(false);
          setRecordingStatus('Pause');
          subscribeToMic(stream);
        }, 500);
      });
    }
  };

  const stopRecording = () => {
    setRecordingStatus('Resume');
    // setTimeout(() => setAmplitude(0), 100);
    micRecorder
      .stop()
      .getMp3().then(([buffers, blob]) => {
        dispatch(setAudioLength((counterRef.current.state.m * 60) + counterRef.current.state.s));
        counterRef.current.stop();
        buffers.splice(0, 36);
        Array.prototype.push.apply(buffer, buffers);
        const file = new File(buffer, 'file123.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        const newblob = new Blob(buffer, { type: blob.type });
        setBuffer(buffer);
        dispatch(setAudioFile(URL.createObjectURL(file), newblob, []));
        // const player = new Audio(URL.createObjectURL(file));
        // player.play();
      }).catch((e) => {
        alert('We could not retrieve your message');
        console.log(e);
      });
  }

  const subscribeToMic = (stream) => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const audioContext = new AudioContext();
    const analyser = audioContext.createAnalyser();
    const microphone = audioContext.createMediaStreamSource(stream);
    const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(javascriptNode);
    javascriptNode.connect(audioContext.destination);
    javascriptNode.onaudioprocess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let values = 0;

      const { length } = array;
      for (let i = 0; i < length; i++) {
        values += (array[i]);
      }
      let average = values / length;
      if (average !== 0) {
        if (average < 5) average = 5;
        setAverageAmp(average);
      }
    };
  };

  useEffect(() => {
    if(recordingStatus === 'Pause')
      setAmplitude(mapMinMax(0, 80, 0, constants.maxWaveAmplitude, averageAmp));
  }, [averageAmp, recordingStatus, setAmplitude])

  const getAnimationTransition = (seconds) => {
    const transitionDuration = `${seconds}s`;
    return {
      WebkitTransition: transitionDuration,
      MozTransition: transitionDuration,
      msTransition: transitionDuration,
      OTransition: transitionDuration,
      transition: transitionDuration,
      transitionProperty: 'width',
      transitionTimingFunction: 'linear',
    };
  };

  return (
    <Col className="no-padding d-flex flex-column justify-content-between align-items-center recorder">
      <Row className={`no-padding ${recordingStatus === 'Start' ? 'd-none' : 'd-flex'} flex-row justify-content-center align-items-center timer`}>
        <Timer
          ref={counterRef}
          startImmediately={false}
          checkpoints={[
            {
              time: constants.maxAudioLength * 1000,
              callback: () => [document.getElementById('pauseImg').click(), setIsBtnDisabled(true)],
            },
            {
              time: constants.minAudioLength * 1000,
              callback: () => setDisabledPause(false),
            },
          ]}
        >
          <Timer.Minutes />
          :
          <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
        </Timer>
        /
        <Timer startImmediately={false} initialTime={constants.maxAudioLength * 1000}>
          <Timer.Minutes />
          :
          <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
        </Timer>
      </Row>
      <Row className="w-100 mt-4 d-none d-sm-flex">
        <div className="d-flex w-100 flex-row justify-content-start align-items-center position-relative">
          <AudioRect amplitude={amplitude} 
                     getAnimationTransition={getAnimationTransition} 
                     startedRecording={startedRecording} 
                     withAnimation 
                     bars={7} 
                     width={5.5} 
                     radius={4} 
                     margin={10} 
                     height="25vh"
                    />
          <div className={`verticalLine ${startedRecording ? '' : 'd-none'}`} />
          <AudioWave amplitude={amplitude} 
                     getAnimationTransition={getAnimationTransition} 
                     startedRecording={startedRecording} 
                     withAnimation 
                     isHidden={recordingStatus === 'Resume'} 
                     waves={3} 
                     height="25vh"
                    />
        </div>
      </Row>
      <Row className="w-100 mt-4 d-flex d-sm-none">
        <div className="d-flex w-100 flex-row justify-content-start align-items-center position-relative">
          <AudioRect amplitude={amplitude} 
                     getAnimationTransition={getAnimationTransition} 
                     startedRecording={startedRecording} 
                     withAnimation 
                     bars={4} 
                     width={15} 
                     radius={10} 
                     margin={28} 
                     height="25vh"
                    />
          <div className={`verticalLine ${startedRecording ? '' : 'd-none'}`} />
          <AudioWave amplitude={amplitude} 
                     getAnimationTransition={getAnimationTransition} 
                     startedRecording={startedRecording} 
                     withAnimation 
                     isHidden={recordingStatus === 'Resume'} 
                     waves={3} 
                     height="25vh"
                    />
        </div>
      </Row>
      <Row className="no-padding d-flex flex-row justify-content-center align-items-center recorder position-relative">
        {
                recordingStatus === 'Resume' && (
                <div
                  className="clickable position-absolute startOver"
                  onClick={() => {
                    setDisabledPause(true);
                    dispatch(resetAudio());
                    record();
                    counterRef.current.reset();
                  }}
                >
                  <p className="mb-0">{t('record.startOver')}</p>
                </div>
                )
                }
        { recordingStatus === 'Pause'
          ? (
            <Button
              disabled={disabledPause}
              onClick={() => stopRecording()}
              className="btn bg-transparent border-0 rounded-circle playBtn playBtnImg"
            >
              <img className="pauseBtnImg" id="pauseImg" alt="pause" src={pauseLarge} />
            </Button>
          )
          : (<Button disabled={isBtnDisabled} onClick={() => record()} className="bg-transparent border-0 playBtn">
              <img className="playBtnImg" id="playImg" alt="play/resume" src={recordingStatus === 'Start'? startButton: resumeButton} />
            </Button>)}
      </Row>
    </Col>
  );
}
