import React, { memo } from 'react';
import {
  Col, Modal, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './popup.scss';
import PropTypes from 'prop-types';
import downloadOnAppStore from '../../assets/images/downloadOnAppStore.png';
import downloadOnAppStoreMedium from '../../assets/images/downloadOnAppStore@2x.png';
import downloadOnAppStoreLarge from '../../assets/images/downloadOnAppStore@3x.png';
import getItOnGooglePlay from '../../assets/images/getItOnGooglePlay.png';
import getItOnGooglePlayMedium from '../../assets/images/getItOnGooglePlay@2x.png';
import getItOnGooglePlayLarge from '../../assets/images/getItOnGooglePlay@3x.png';
import riffrLogoBlack from '../../assets/icons/riffrLogoBlack.png';
import constants from '../../utils/constants';

const Popup = (props) => {
  const { t } = useTranslation();
  const { isloading, title, subTitle } = props;
  const loading = <div className="text-center mt-5 mb-0"><Spinner animation="border" className="spinner" /></div>;
  return (
    <Modal
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="popup">
        <Col className="no-padding d-flex flex-column justify-content-between align-items-center">
          <Row className="no-gutters">
            <Col>
              <img
                src={riffrLogoBlack}
                alt="Logo"
                className="riffrLogoBlack mb-4"
              />
            </Col>
          </Row>
          {
            isloading === 'true' ? (
              <>
                {loading}
                <Row className="no-gutters">
                  <p className="message">{t('popup.uploading')}</p>
                </Row>
              </>
            ) : (
              <>
                <Row className="no-gutters">
                  <h3 className="join-content title">{title}</h3>
                </Row>
                <Row className="no-gutters">
                  <p className="message">{subTitle}</p>
                </Row>
                <Row className="no-gutters">
                  <Col className="flex-grow-0">
                    <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer">
                      <img
                        src={downloadOnAppStore}
                        srcSet={`${downloadOnAppStore} 300w, ${downloadOnAppStoreMedium} 768w, ${downloadOnAppStoreLarge} 1280w`}
                        alt="appStore"
                        className="downloadOnAppStore storeImg"
                      />
                    </a>
                  </Col>
                  <Col>
                    <a href={constants.riffrAndroidStore} target="_blank" className="getItOnGooglePlay" rel="noopener noreferrer">
                      <img
                        src={getItOnGooglePlay}
                        srcSet={`${getItOnGooglePlay} 300w, ${getItOnGooglePlayMedium} 768w, ${getItOnGooglePlayLarge} 1280w`}
                        alt="googleStore"
                        className="getItOnGooglePlay storeImg"
                      />
                    </a>
                  </Col>
                </Row>
                <Row className="no-gutters">
                  <span className="mt-2">
                    {`${t('popup.visit')} `}
                    <a className="text-decoration-none riffrCom clickable" href="/">{t('popup.riffrCom')}</a> 
                    {` ${t('popup.forMore')}`}
                  </span>
                </Row>
              </>
            )
          }
        </Col>
      </Modal.Body>
    </Modal>
  );
};

Popup.propTypes = {
  isloading: PropTypes.string,
};

Popup.defaultProps = {
  isloading: 'false',
};

export default memo(Popup);
