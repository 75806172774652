import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';
import { GET_COMMENTS_SUCCESS } from '../constants/commentConstants';

const initialState = {
  byId: {},
  allIds: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMENTS_SUCCESS: {
      const { data } = action.payload;
      const myData = { comments: data.result };
      const comment = new schema.Entity('comment');
      const Schema = { comments: [comment] };
      const normalizedData = normalize(myData, Schema);
      return {
        ...state,
        byId: merge({}, state.byId, normalizedData.entities.comment),
        allIds: state.allIds.concat(normalizedData.result.comments),
      };
    }
    default:
      return state;
  }
};

export default reducer;
