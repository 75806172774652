import { GET_TRENDING_HASH_TAGS, SEARCH_ALL } from '../constants/searchConstants';
/**
 * @param {number} page
 */
export const getTrendingHashTags = (page) => ({
  type: GET_TRENDING_HASH_TAGS,
  payload: {
    request: {
      url: 'hashtag/suggested',
      method: 'get',
      authenticated: false,
    },
  },
});
/**
 * @param {string} search
 */
export const searchAll = (search) => ({
  type: SEARCH_ALL,
  payload: {
    request: {
      url: 'search',
      method: 'post',
      authenticated: false,
      params: {
        cache: 'disabled',
      },
      data: {
        query: search,
      },
    },
  },
});
