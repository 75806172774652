import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './tabs.scss';

const Tabs = ({ data, onClick, activeIndex }) => (
  <span className="tabs">
    {data.map((tab, index) => (
      <Fragment key={index.toString()}>
        <span
          aria-hidden="true"
          className={`app-section-header ${activeIndex === index ? 'primary-text' : 'clickable'}`}
          onClick={() => onClick(index)}
        >
          {tab}
        </span>
        {index !== (data.length - 1) && <span className="titles-separator" />}
      </Fragment>
    ))}
  </span>
);

Tabs.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default Tabs;
