import React from 'react';
import PropTypes from 'prop-types';

const PageTitle = ({ children }) => (<h4 className="app-section-header bold">{children}</h4>);

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default PageTitle;
