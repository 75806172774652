import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import NonAuthenticatedNavBar from '../../components/nonAuthenticatedNavBar/nonAuthenticatedNavBar';
import { getUser, getUserRiff, setFirstLogin, getUserLiked, getUserCommented } from '../../store/actions/userActions';
import Page from '../../components/pageContent/pageContent';
import { commaSeparatedNumber } from '../../utils/common';
import './profile.scss';
import { clearAuthentication } from '../../store/actions/authenticationActions';
import constants from '../../utils/constants';
import person from '../../assets/icons/person.png';
import ClickableText from '../../components/clickableText/clickableText';
import settings from '../../assets/icons/settings.png';
import edit from '../../assets/icons/edit.png';
import logout from '../../assets/icons/logOutOrange.png';
import invite from '../../assets/icons/personBlue.png';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import { follow } from '../../store/actions/connectionsActions';
import RiffsList from '../../components/riffsList/riffsList';

const renderLoading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

const Profile = ({ history, location }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [statuses] = useState([
    t('profile.myRiffs'),
    t('profile.likes'),
    t('profile.replies')
  ]);
  
  const [status, setStatus] = useState(statuses[0]);
  const user = useSelector((state) => state.user);
  const { loading, result, firstLogin } = user;
  const { userId } = useParams();
  const sort = 'recent';
  const authentication = useSelector(state => state.authentication);
  const isAuthenticated = authentication.access_token != null;
  const connections = useSelector(state => state.connections);

  useEffect(() => {
    if(status === statuses[0]) {
      !userId && dispatch(getUserRiff(1, sort));
    }
    if(status === statuses[1]) {
      dispatch(getUserLiked(1, sort));
    }
    if(status === statuses[2]) {
      dispatch(getUserCommented(1, sort));
    }
  }, [status, dispatch, userId, statuses]);

  const followAction = () => {
    dispatch(follow(result.id, !result.actions.follower))
  };

  const getRiffs = (page) => {
    if(status === statuses[1]) {
      return getUserLiked(page, sort);
    }
    if(status === statuses[2]) {
      return getUserCommented(page, sort);
    }
    return getUserRiff(page, sort, userId);
  }

  useEffect(() => {
    dispatch(getUser(userId)).then(() => {
      userId && dispatch(getUserRiff(1, sort, userId));
    });
  }, [dispatch, userId]);

  useEffect(() => {
    if (firstLogin && result) {
      const toastContent = (
        <div>
          <Row className="no-gutters">
            <Col xs={2} className="align-self-center text-center">
              <img src={person} alt="person" width="20" height="20" />
            </Col>
            <Col xs={10}>
              <div>{`${t('authentication.welcome')} ${result.name}`}</div>
              <div className="app-body">{t('authentication.youAreAllSignedUp')}</div>
            </Col>
          </Row>
        </div>
      );
      toast(toastContent, {
        position: 'top-right',
        hideProgressBar: true,
        draggable: true,
        className: 'welcome-toast',
      });
      dispatch(setFirstLogin(false));
    }
  }, [firstLogin, result, t, dispatch]);

  const onLogout = () => {
    dispatch(clearAuthentication());
    history.push(constants.screens.login);
  };

  const renderStatuses = () => (
    <div className="d-flex flex-row align-items-center statusesContainer">
      {
        statuses.map((res, i) => (
          <Fragment key={i}>
            <h4 onClick={() => setStatus(res)} className={`d-none d-md-block bold clickable m-0 ${res === status? 'statusTxt-selected': ''}`}>{res}</h4>
            <h5 onClick={() => setStatus(res)} className={`d-block d-md-none bold clickable m-0 ${res === status? 'statusTxt-selected': ''}`}>{res}</h5>
            { i !==2 && <div className="statusSeparator d-none d-md-block" /> }
            { i !==2 && <div className="statusSeparator statusSeparator-xs d-block d-md-none" /> }
          </Fragment>
        ))
      }
    </div>
  )

  const urlify = (text) => {
    if(!text) return text;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const txtArr = text.split(urlRegex);
    return <>{txtArr.map((res, i) => i%2 === 0? <span key={i}>{res}</span>: <a key={i} href={res}>{res}</a>)}</>
  }

  const renderContent = () => result && (
    <div>
      <ToastContainer autoClose={4000} />
      <h6 className="app-sub-header">{`${result.first_name} ${result.last_name? result.last_name: ''}`}</h6>
      <div className="d-flex flex-row align-items-center">
        <h6 className="app-sub-header-2 secondary-text">{`@${result.handle}`}</h6>
        {userId && userId !== authentication.userId && isAuthenticated && (
        <Button
          variant={`${!(result.actions && result.actions.follower) ? 'outline-primary' : 'primary'}`}
          className={`app-value mb-2 ${!(result.actions && result.actions.follower) ? 'followBtn' : 'followingBtn'} ml-3`}
          onClick={() => followAction()}
          disabled={connections.follow.loading}
        >
          {t(`profile.${(result.actions && result.actions.follower) ? 'following' : 'follow'}`) + (connections.follow.loading ? '...' : '')}
        </Button>
        )}
      </div>
      <h6 className="app-body light bio-text text-break">{urlify(result.bio)}</h6>
      <h6 className="app-body d-flex flex-row">
        <div
          className={`connection ${result.following === 0 ? 'disabled-link' : 'default-link clickable'}`}
          onClick={() => result.following !== 0 && history.push(`${constants.screens.connections}/${userId? `${userId}`: ''}`, location.state? [{backTo: location.pathname, isFollowing: true}, ...location.state]: [{backTo: location.pathname, isFollowing: true}])}
        >
          <span className="app-value primary-text">{`${commaSeparatedNumber(result.following)} `}</span>
          <span className="black-text light">{t('profile.following').toLowerCase()}</span>
        </div>
        <div
          className={`connection ${result.followers === 0 ? 'disabled-link' : 'default-link clickable'}`}
          onClick={() => result.followers !== 0 && history.push(`${constants.screens.connections}/${userId? `${userId}`: ''}`, location.state? [{backTo: location.pathname, isFollowing: false}, ...location.state]: [{backTo: location.pathname, isFollowing: false}])}
        >
          <span className="app-value primary-text followers-number">
            {` ${commaSeparatedNumber(result.followers)} `}
          </span>
          <span className="black-text light">{t('profile.followers').toLowerCase()}</span>
        </div>
      </h6>
      {(!userId || userId === authentication.userId) && renderStatuses()}

      <RiffsList getRiffs={getRiffs} sort={sort} readOnly={!isAuthenticated} goTo={(screen) => screen !== location.pathname && history.push(screen, [{backTo: location.pathname, mainScreen: 'myFeeds'}])}/>:
      }
    </div>
  );

  const settingsItem = () => (<ClickableText text={t('globals.settings')} icon={settings} onClick={() => history.push(constants.screens.settings)} />);

  const editItem = () => (
    <ClickableText
      text={t('globals.edit')}
      icon={edit}
      onClick={() => history.push(constants.screens.editProfile)}
    />
  );

  const inviteFriendsItem = () => (
    <>
      <div className="d-none d-sm-block">
        <ClickableText
          text={t('globals.inviteFriends')}
          icon={invite}
          onClick={() => history.push(constants.screens.inviteFriends)}
          textClass="secondary-text"
        />
      </div>
      <div className="d-sm-none d-block">
        <ClickableText
          text={t('globals.invite')}
          icon={invite}
          onClick={() => history.push(constants.screens.inviteFriends)}
          textClass="secondary-text"
        />
      </div>
    </>
  );

  const logoutItem = () => (<ClickableText text={t('globals.logout')} icon={logout} onClick={onLogout} textClass="error-text" />);

  const handleBack = () => {
    if(location.state && location.state.length) {
      const currentState = location.state.shift();
      history.push(currentState.backTo, location.state.length? location.state: null );
    } else {
      history.push(constants.screens.home);
    }
  }

  return (
    <Page header={isAuthenticated? <AuthenticatedNavBar activeKey="profile" />: <NonAuthenticatedNavBar />} hideMargin>
      {(!userId || userId === authentication.userId)? (
      <Row className="no-gutters justify-content-between justify-content-md-end pr-2 pl-2 pt-2 pb-2 d-lg-none clickable-items">
        <div className="mr-2 mr-md-4">
          {settingsItem()}
        </div>
        <div className="mr-2">
          {editItem()}
        </div>
        <div className="mr-2">
          {inviteFriendsItem()}
        </div>
        <div className="mr-2 align-self-center">
          {logoutItem()}
        </div>
      </Row>
      ): null}
      <Row className="profile-content no-gutters">
        {userId && isAuthenticated
          ? (
            <>
              <Col xs={1} sm={3} lg={4} className="flex-fill">
                {location.state && location.state[0].backTo !== constants.screens.home? 
                  <PageLeftContent 
                    title={t(location.state[0].backTo === constants.screens.search? 'globals.backToSearch': location.state[0].backTo === constants.screens.profile? 'globals.backToProfile': 'globals.back')} 
                    onClick={handleBack} />:              
                  <PageLeftContent title={t('globals.backToFeed')} onClick={handleBack} />
                }
              </Col>
              <Col xs={10} md={8} lg={6} xl={4}>
                {loading
                  ? renderLoading : renderContent()}
              </Col>
            </>
          ) : (
              <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-2 offset-xl-4">
                {loading
                  ? renderLoading : renderContent()}
              </Col>
          )}

        {(!userId || userId === authentication.userId) && (
        <Col md={3} className="d-none d-sm-none d-md-none d-lg-block offset-1">
          <div className="mb-3">
            {settingsItem()}
          </div>
          <div className="mb-3">
            {editItem()}
          </div>
          <div className="mb-3">
            {inviteFriendsItem()}
          </div>
          <div className="mb-3">
            {logoutItem()}
          </div>
        </Col>
        )}

      </Row>
    </Page>
  );
};

Profile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default Profile;
