import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextInput from '../formInputs/textInput/textInput';
import TextArea from '../formInputs/textArea/textArea';

const UpdateProfileForm = ({ onSubmit, formReference, initialValues }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .required(t('validation.fieldIsRequired')),
    last_name: Yup.string(),
      // .required(t('validation.fieldIsRequired')),
    bio: Yup.string().nullable(),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="first_name"
              id="first_name"
              placeholder={t('globals.firstName')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="last_name"
              id="last_name"
              placeholder={t('globals.lastNameInitial')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="handle"
              id="handle"
              placeholder={`@${t('globals.handle')}`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              disabled
              classNames="inputDisabledSlug"
            />
            <TextArea
              label=" "
              name="bio"
              id="bio"
              placeholder=""
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              maxLength="250"
            />
          </>
        )
      }

    </Formik>
  );
};

UpdateProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    handle: PropTypes.string,
    bio: PropTypes.string,
  }).isRequired,
};

export default UpdateProfileForm;
