import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { formatSeconds, toUpperCaseFirstLetter } from '../../utils/common';
import playFilled from '../../assets/icons/playFilled.png';
import pauseFilled from '../../assets/icons/pauseFilled.png';
import waveform from '../../assets/icons/waveform.png';
import waveformActive from '../../assets/icons/waveformActive.png';
import './sideRiff.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setCanSetRemainingTime, setSideRiffTime } from '../../store/actions/riffActions';
import closeIcon from '../../assets/icons/closeCircleOutline@3x.png';

export default function SideRiff({ setPlayingStatus, hide, ismd, playAllMode, setPlayAllMode }) {
    const [marginLeft, setMarginLeft] = useState('0%');
    const [imageWidth, setImageWidth] = useState('100%');
    const [remainingTime, setRemainingTime] = useState(0);
    const [marginLeft1, setMarginLeft1] = useState('0%');
    const [imageWidth1, setImageWidth1] = useState('100%');
    const [remainingTime1, setRemainingTime1] = useState(0);
    const [canSliderMouseMove, setCanSliderMouseMove] = useState(false);
    const [isStopped, setIsStopped] = useState(false);
    const [canDispatch, setCanDispatch] = useState(false);
    const [canDispatch1, setCanDispatch1] = useState(false);
    const [wasInPlayAllMode, setWasInPlayAllMode] = useState(false);
    const dispatch = useDispatch();
    const {id, length, name, ...riff} = useSelector(state => state.riff);

    useEffect(() => {
        setRemainingTime(riff.remainingTime);
        const marginLeftInPercentage = `${((length- riff.remainingTime) / length) * 100}%`;
        setMarginLeft(marginLeftInPercentage);
        setImageWidth(`${100 - ((length- riff.remainingTime) / length) * 100}%`);
      }, [length,  riff.remainingTime]);
    
    useEffect(() => {
      if(wasInPlayAllMode && !canSliderMouseMove && id){
        setPlayAllMode(wasInPlayAllMode);
        setWasInPlayAllMode(false);
      }
    }, [remainingTime, wasInPlayAllMode, setPlayAllMode, canSliderMouseMove, id]);

    const FindPosition = (oElement) => {
        const imgWidth = oElement.width;
        if (typeof (oElement.offsetParent) !== 'undefined') {
          for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
            posX += oElement.offsetLeft;
            posY += oElement.offsetTop;
          }
          return [posX, posY, imgWidth];
        }
    
        return [oElement.x, oElement.y, imgWidth];
      };
      const GetCoordinates = (e) => {
        let PosX = 0;
        let ImgPos;
        const waveImage = document.getElementById(ismd? 'waveFormImgSideMd': 'waveFormImgSide');
        ImgPos = FindPosition(waveImage);
        if (!e) e = window.event;
        if (e.pageX || e.pageY) {
          PosX = e.pageX;
        } else if (e.clientX || e.clientY) {
          PosX = e.clientX + document.body.scrollLeft
              + document.documentElement.scrollLeft;
        }
        PosX -= ImgPos[0];
        if (PosX < 0) PosX = 0;
        if ((PosX / ImgPos[2]) > 1) {
          PosX = ImgPos[2];
        }
        setSlider(PosX, ImgPos[2]);
      };
      const setSlider = useCallback((PosX, imageWidth) => {
        const imgWidthPercent = `${100 - ((PosX / imageWidth) * 100)}%`;
        const marginLeftPercent = `${(PosX / imageWidth) * 100}%`;
        const currentTime = (PosX * length) / imageWidth;
        const remainingLength = length - currentTime;
        if(!hide) {
          if(!id) {
            setRemainingTime(remainingLength);
            setMarginLeft(marginLeftPercent);
            setImageWidth(imgWidthPercent);
            setCanDispatch1(false);
            setCanDispatch(true);
          } else {
            setWasInPlayAllMode(playAllMode);
            dispatch(setCanSetRemainingTime(true));
            setPlayingStatus('stop');
            setRemainingTime1(remainingLength);
            setMarginLeft1(marginLeftPercent);
            setImageWidth1(imgWidthPercent);
            setCanDispatch1(true);
            setIsStopped(true);
          }
        }
      }, [length, dispatch, id, setPlayingStatus, hide, playAllMode]);

      useEffect(() => {
        if(!canSliderMouseMove && canDispatch1){
          dispatch(setSideRiffTime({
            imgWidthPercent: imageWidth1, 
            marginLeftPercent: marginLeft1, 
            remainingLength: remainingTime1,
            shouldPlay: isStopped
          }));
          setIsStopped(false);
          setCanDispatch1(false);
          setCanDispatch(false);
        }
      }, [canSliderMouseMove, canDispatch1, isStopped, imageWidth1, marginLeft1, remainingTime1, dispatch]);

      useEffect(() => {
        if(canDispatch && !canSliderMouseMove) {
          dispatch(setSideRiffTime({
            imgWidthPercent: imageWidth, 
            marginLeftPercent: marginLeft, 
            remainingLength: remainingTime,
            shouldPlay: isStopped
          }));
          setIsStopped(false);
          setCanDispatch(false);
        }
      }, [canDispatch, canSliderMouseMove, imageWidth, marginLeft, remainingTime, dispatch, isStopped]);
    

    const sliderMouseDown = () => {
        setCanSliderMouseMove(true);
      };
    
      const sliderMouseUp = () => {
        setCanSliderMouseMove(false);
      };
    
      const sliderMouseMove = (e, canMove = false) => {
        setCanSliderMouseMove(true);
        if (canSliderMouseMove || canMove) {
          GetCoordinates(e);
        }
      };

    const getAnimationTransition = (seconds) => {
        const transitionDuration = `${seconds}s`;
        return {
          WebkitTransition: transitionDuration,
          MozTransition: transitionDuration,
          msTransition: transitionDuration,
          OTransition: transitionDuration,
          transition: transitionDuration,
          transitionProperty: 'margin',
          transitionTimingFunction: 'linear',
        };
      };
    
      const getAnimationTransitionWaveForm = (seconds) => {
        const transitionDuration = `${seconds}s`;
        return {
          WebkitTransition: transitionDuration,
          MozTransition: transitionDuration,
          msTransition: transitionDuration,
          OTransition: transitionDuration,
          transition: transitionDuration,
          transitionProperty: 'clip-path',
          transitionTimingFunction: 'linear',
        };
      };
    return (
        <div className={`sideRiffContainer${hide? 'hide': ' mb-4'} ${ismd? 'sideRiffContainer-md': 'mr-5'}`}>
            <Row className={`no-gutters audio-container ${ismd? 'd-flex flex-row justify-content-center align-items-center': ''}`}>
                <Col className={`${ismd? 'playPauseBtnMd d-flex flex-row justify-content-start align-items-center': 'flex-grow-0'}`}>
                    {ismd && name? (
                        <div className="d-flex flex-row justify-content-start align-items-center mr-1 mr-md-3">
                            <img src={closeIcon} alt="x" className="clickable mr-3 closeIconSideRiff" onClick={() => setPlayingStatus('close')} />
                            <h4 className="bold pt-1 m-0 mr-2 d-none d-md-block">{toUpperCaseFirstLetter(name)}</h4>
                            <span className="bold pt-1 m-0 mr-1 sideRiffName d-block d-md-none">{toUpperCaseFirstLetter(name)}</span>
                        </div>
                    ): null}
                    <Button
                        className="riffButton rounded-circle d-flex justify-content-center align-items-center"
                        onClick={() => {
                        if (!id) {
                            setPlayingStatus('play');
                        } else if (id) {
                            dispatch(setCanSetRemainingTime(true));
                            setPlayingStatus('stop');
                        }
                        }}
                        aria-hidden="true"
                    >
                        {
                            id ? <img src={pauseFilled} alt="pauseFilled" className={`pauseFilled${ismd? '-md': ''}`} />
                            : <img src={playFilled} alt="playFilled" className={`playFilled${ismd? '-md': ''}`} />
                        }
                    </Button>
                </Col>
                <Col className={`${ismd? 'audio-container-bar-column': 'offset-1 flex-grow-1 text-center'}`}>
                    <div className={`audio-container-bar ${ismd? 'audio-container-bar-md': ''}`}>
                        <div 
                            style={{ width: '100%', position: 'relative', cursor: 'pointer' }}
                            onMouseDown={(e) => [GetCoordinates(e), sliderMouseDown()]}
                            onTouchStart={(e) => [GetCoordinates(e.changedTouches[0]), sliderMouseDown()]}
                            onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
                            onTouchEnd={sliderMouseUp}>
                            <img
                                src={waveformActive}
                                style={{
                                position: 'absolute',
                                margin: 'auto',
                                top: 0,
                                bottom: 0,
                                clipPath: `inset(0% ${imageWidth} 0% 0%)`,
                                ...id? getAnimationTransitionWaveForm(remainingTime) : null,
                                }}
                                alt="waveformActive"
                                className={`waveFormActive noSelect ${id ? 'waveFormActiveTransition' : ''}`}
                            />
                            <img src={waveform} id={`${ismd? 'waveFormImgSideMd': 'waveFormImgSide'}`} alt="waveform" className="waveForm noSelect" />
                        </div>
                        <div
                            id="audioBarId"
                            onMouseDown={sliderMouseDown}
                            onTouchStart={sliderMouseDown}
                            onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
                            onTouchEnd={sliderMouseUp}
                            className={` audioBar ${id ? 'horizTranslate' : ''}`}
                            style={{
                                position: 'absolute',
                                cursor: canSliderMouseMove? 'grabbing': 'grab',
                                marginLeft,
                                ...id? getAnimationTransition(remainingTime) : null,
                                // backgroundColor: (remainingTime === length && !id.length) && 'transparent',
                            }}
                        />
                        {!hide && <div 
                            className="position-absolute audioBarContainerSide" 
                            onMouseEnter={sliderMouseDown} 
                            onMouseLeave={sliderMouseUp}
                            onMouseUp={sliderMouseUp} 
                            onMouseMove={sliderMouseMove}
                            // onTouchStart={sliderMouseDown}
                            // onTouchEnd={sliderMouseUp}
                            // onTouchMove={(e) => sliderMouseMove(e.changedTouches[0])}
                            style={!canSliderMouseMove? {zIndex: -1 }: {}} />}
                    </div>
                </Col>
                {!ismd && <Col className="offset-1 flex-grow-0 timeContainer d-flex flex-row">
                    <span className="app-inner-body light">
                        {formatSeconds(length)}
                    </span>
                </Col>}
            </Row>
            {!ismd && <Row className="no-gutters mt-3">
                {name && !hide ?
                    <>
                        <span className="bold m-0 mr-1">{toUpperCaseFirstLetter(name)}</span>
                        <span>now playing</span>
                    </>: null}
                
            </Row>}
            
        </div>
    )
}
