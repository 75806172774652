import React, {
  useEffect, useState,
} from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import Scroll from 'react-scroll';
import { useTranslation } from 'react-i18next';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import './search.scss';
import SearchInput from '../../components/searchInput/searchInput';
import TrendingTags from '../../components/trendingTags/trendingTags';
import { useDispatch, useSelector } from 'react-redux';
import { getTrendingHashTags, searchAll } from '../../store/actions/searchActions';
import SearchResult from '../../components/searchResult/searchResult';
import personIcon from '../../assets/icons/personSmallOutline.png';
import JumpTo from '../../components/jumpTo/jumpTo';
import constants from '../../utils/constants';
import noRecords from '../../assets/icons/noRecords.png';
import { saveSearchHistory } from '../../store/actions/searchHistoryActions';
import { debounce } from 'lodash';

const renderLoading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

export default function Search({ history, location }) {
  const { t } = useTranslation();
  const [jumpToNames, setJumpToNames] = useState([]);
  const [searchTxt, setSearchTxt] = useState('');
  const [hideSearchInput, setHideSearchInput] = useState(false);
  const [jumpTo, setJumpTo] = useState('');
  const [jumpToTarget, setJumpToTarget] = useState('');
  const { userId } = useSelector(state => state.authentication);
  const dispatch = useDispatch();
  const {
    trendingHashTags, searchedRiffs, searchedTopics, searchedPeople, loading,
  } = useSelector((state) => state.search);
  const { searchHistory } = useSelector((state) => state.searchHistory);
  let isScrolling;
  const scrollHandler = () => {
    clearTimeout( isScrolling );
    isScrolling = setTimeout(() => {
      if(jumpToTarget.length){
        setJumpTo(jumpToTarget);
      }
      setJumpToTarget('');
    }, 200);
    for (const name of jumpToNames) {
      checkScrollElement(name);
    }
    if (window.scrollY > 100) {
      setHideSearchInput(true);
    } else if (window.scrollY < 100) {
      setHideSearchInput(false);
    }
  };

  const checkScrollElement = (element) => {
    if (document.getElementById(element)) {
      const { offsetTop } = document.getElementById(element);
      const pos = offsetTop - window.scrollY + 50;
      if (pos < 0 && pos > -50 && jumpTo !== element) {
        setJumpTo(element);
        if(jumpToTarget.length){
          setJumpTo(jumpToTarget);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return function cleanup() {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  useEffect(() => {
    const namesArr = [];
    if (searchTxt.length && searchedTopics) {
      searchedTopics.length && namesArr.push('Topics');
      searchedPeople.length && namesArr.push('People');
      searchedRiffs.length && namesArr.push('Riffs');
      setJumpToNames(namesArr);
      setJumpTo(namesArr[0]);
    } else {
      setJumpToNames([]);
    }
  }, [searchedRiffs, searchedTopics, searchedPeople, searchTxt.length]);

  useEffect(() => {
    dispatch(getTrendingHashTags());
  }, [dispatch]);

  const onSearch = (search) => {
    search.length && dispatch(searchAll(search));
  }

  const scrollToElement = (elementId) => {
    Scroll.scroller.scrollTo(elementId, {
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      containerId: '',
      offset: -100,
    });
  };
  useEffect(() => {
    if (jumpToTarget && jumpToTarget.length) {
      document.getElementById(jumpToTarget) && scrollToElement(jumpToTarget);
    }
  }, [jumpToTarget]);

  return (
    <Page header={<AuthenticatedNavBar activeKey="search" />} hideMargin>
      { jumpToNames.length
        ? (
          <Row className="no-gutters justify-content-between justify-content-md-end pr-2 pl-2 pt-2 pb-2 d-lg-none clickable-items">
            <JumpTo ismd jumpTo={jumpTo} jumpToTarget={jumpToTarget} setJumpToTarget={setJumpToTarget} jumpToNames={jumpToNames} />
          </Row>
        ) : null }
      <Row className="profile-content no-gutters">
        <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-3 offset-xl-4">
          <SearchInput placeholder="Search for topics, people or riffs" searchTxt={searchTxt} setSearchTxt={setSearchTxt} onSearch={debounce(onSearch, 500)} />
          { !loading
            ? !searchTxt.length || !searchedTopics
              ? (
                <>
                  <TrendingTags hasTitle onClick={(tag) => [dispatch(saveSearchHistory(tag, userId)), history.push(`${constants.screens.home}/${tag.name}`, [{backTo: location.pathname, mainScreen: 'search'}])]} trendingHashTags={trendingHashTags} />
                  {searchHistory && searchHistory[userId] && searchHistory[userId].length ? <div id="Riffs"><SearchResult location={location} isHistory history={history} searchTxt={searchTxt} title="My recent searches" icon={personIcon} data={searchHistory[userId]} /></div> : null}
                </>
              ) : (
                <>
                  {searchedTopics.length ? <div id="Topics"><SearchResult location={location} history={history} searchTxt={searchTxt} title="Topics" data={searchedTopics} /></div> : null}
                  {searchedPeople.length ? <div id="People"><SearchResult location={location} history={history} searchTxt={searchTxt} title="People" icon={personIcon} data={searchedPeople} /></div> : null}
                  {searchedRiffs.length ? <div id="Riffs"><SearchResult location={location} history={history} searchTxt={searchTxt} title="Riffs" icon={personIcon} data={searchedRiffs} /></div> : null}
                  {!(searchedTopics.length || searchedPeople.length || searchedRiffs.length)
                                    && (
                                    <div className="app-value text-center mt-5">
                                      <img src={noRecords} alt="noRecords" className="noRecords" height="70" />
                                      <div className="mt-2">{t('globals.noResultsSorry')}</div>
                                    </div>
                                    )}
                </>
              ) : renderLoading}
        </Col>
        <div md={3} className="d-none d-sm-none d-md-none d-lg-block rightSide position-fixed jumpToContainer">
          { hideSearchInput && <SearchInput placeholder="Search for topics, people or riffs" searchTxt={searchTxt} setSearchTxt={setSearchTxt} onSearch={debounce(onSearch, 500)} /> }
          { jumpToNames.length ? <JumpTo ismd={false} jumpTo={jumpTo} jumpToTarget={jumpToTarget} setJumpToTarget={setJumpToTarget} jumpToNames={jumpToNames} /> : null }
        </div>
      </Row>
    </Page>
  );
}
