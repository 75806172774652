import { CLEAR_USER_BY_ID, GET_USER_BY_ID } from '../constants/resetPasswordConstants';

/**
 * @param {string} user
 */
export const getUserById = (user) => ({
  type: GET_USER_BY_ID,
  payload: {
    request: {
      url: `user/${user}`,
      method: 'get',
      authenticated: false,
    },
    user,
  },
});

export const clearUserById = () => ({
  type: CLEAR_USER_BY_ID,
  payload: null,
});
