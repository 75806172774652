import {
  GET_TRENDING_HASH_TAGS, GET_TRENDING_HASH_TAGS_ERROR, GET_TRENDING_HASH_TAGS_SUCCESS, SEARCH_ALL, SEARCH_ALL_ERROR, SEARCH_ALL_SUCCESS,
} from '../constants/searchConstants';

const initialState = {
  trendingHashTags: [],
  searchedRiffs: [],
  searchedTopics: [],
  searchedPeople: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRENDING_HASH_TAGS:
      return {
        loading: true,
      };
    case GET_TRENDING_HASH_TAGS_SUCCESS:
      return {
        ...state,
        trendingHashTags: action.payload.data.result,
        loading: false,
      };
    case GET_TRENDING_HASH_TAGS_ERROR:
      return {
        loading: false,
      };
    case SEARCH_ALL:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_ALL_SUCCESS:
      return {
        ...state,
        searchedRiffs: action.payload.data.riff && action.payload.data.riff.result,
        searchedTopics: action.payload.data.hashtag && action.payload.data.hashtag.result,
        searchedPeople: action.payload.data.user && action.payload.data.user.result,
        loading: false,
      };
    case SEARCH_ALL_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
