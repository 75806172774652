const SET_AUDIO_FILE = 'SET_AUDIO_FILE';
const SET_MIC_RECORDER = 'SET_MIC_RECORDER';
const RESET_AUDIO = 'RESET_AUDIO';
const SET_AUDIO_LENGTH = 'SET_AUDIO_LENGTH';
const SET_UPLOAD_MODE = 'SET_UPLOAD_MODE';
const SEARCH_HASH_TAGS = 'SEARCH_HASH_TAGS';
const SEARCH_HASH_TAGS_SUCCESS = 'SEARCH_HASH_TAGS_SUCCESS';
const SEARCH_HASH_TAGS_FAIL = 'SEARCH_HASH_TAGS_FAIL';
const ADD_RIFF = 'ADD_RIFF';
const ADD_RIFF_SUCCESS = 'ADD_RIFF_SUCCESS';
const ADD_RIFF_FAIL = 'ADD_RIFF_FAIL';
const GET_DRAFTS = 'GET_DRAFTS';
const GET_DRAFTS_SUCCESS = 'GET_DRAFTS_SUCCESS';
const GET_DRAFTS_FAIL = 'GET_DRAFTS_FAIL';
const DELETE_DRAFT = 'DELETE_DRAFT';
const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
const DELETE_DRAFT_FAIL = 'DELETE_DRAFT_FAIL';
const SET_SUCCESSFULLY_POSTED = 'SET_SUCCESSFULLY_POSTED';
const SET_IS_DRAFT = 'SET_IS_DRAFT';

export {
  SET_AUDIO_FILE,
  SET_MIC_RECORDER,
  RESET_AUDIO,
  SET_AUDIO_LENGTH,
  SET_UPLOAD_MODE,
  SEARCH_HASH_TAGS,
  SEARCH_HASH_TAGS_SUCCESS,
  SEARCH_HASH_TAGS_FAIL,
  ADD_RIFF,
  ADD_RIFF_SUCCESS,
  ADD_RIFF_FAIL,
  GET_DRAFTS,
  GET_DRAFTS_SUCCESS,
  GET_DRAFTS_FAIL,
  DELETE_DRAFT,
  DELETE_DRAFT_SUCCESS,
  DELETE_DRAFT_FAIL,
  SET_SUCCESSFULLY_POSTED,
  SET_IS_DRAFT
};
