import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';
import { ADD_HOST_RIFF, ADD_HOST_RIFF_FAIL, ADD_HOST_RIFF_SUCCESS, GET_HOST_NAMES_SUCCESS, GET_HOST_PAGE, GET_HOST_PAGE_FAIL, GET_HOST_PAGE_SUCCESS, GET_HOST_RIFFS, GET_HOST_RIFFS_FAIL, GET_HOST_RIFFS_SUCCESS, SET_IS_SUBMITTED } from '../constants/openMicConstants';

const initialState = {
  loading: false,
  result: {
    currentPage: 0,
  },
  byId: {},
  allIds: [],
  error: null,
  hasMore: false,
  loadingSubmit: false,
  submitted: false,
  page: null,
  loadingPage: false,
  hostNames: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOST_PAGE: 
        return {
            ...state,
            loadingPage: true,
        }
    case GET_HOST_PAGE_SUCCESS: 
        return {
            ...state,
            loadingPage: false,
            page: action.payload.data,
        }
    case GET_HOST_PAGE_FAIL: 
        return {
            ...state,
            loadingPage: false,
            page: null,
        }
    case GET_HOST_NAMES_SUCCESS: 
        return {
            ...state,
            hostNames: action.payload.data.result,
        }
    case SET_IS_SUBMITTED: 
        return {
            ...state,
            submitted: action.payload,
        }
    case ADD_HOST_RIFF: 
        return {
            ...state,
            loadingSubmit: true,
            submitted: false,
        }
    case ADD_HOST_RIFF_SUCCESS: 
        return {
            ...state,
            loadingSubmit: false,
            submitted: true,
        }
    case ADD_HOST_RIFF_FAIL: 
        return {
            ...state,
            loadingSubmit: false,
            submitted: true,
        }
    case GET_HOST_RIFFS: {
      const { page } = action.payload.request.params;
      const isFirstPage = page === 1;
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
        byId: isFirstPage ? {} : state.byId,
        allIds: isFirstPage ? [] : state.allIds,
      };
    }
    case GET_HOST_RIFFS_SUCCESS: {
      const { data } = action.payload;
      const riff = new schema.Entity('riff');
      const Schema = { riffs: [riff] };
      const normalizedData = normalize({ riffs: data.result }, Schema);
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        hasMore: action.payload.data.currentPage < action.payload.data.totalPages,
        byId: merge({}, state.byId, normalizedData.entities.riff),
        allIds: action.payload.data.result ? state.allIds.concat(normalizedData.result.riffs) : state.allIds,
      };
    }
    case GET_HOST_RIFFS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
