const GET_COMMENTS = 'GET_COMMENTS';
const GET_COMMENTS_SUCCESS = 'GET_COMMENTS_SUCCESS';
const GET_COMMENTS_FAIL = 'GET_COMMENTS_FAIL';
const LIKE_COMMENT = 'LIKE_COMMENT';
const REPORT_COMMENT = 'REPORT_COMMENT';
const ADD_COMMENT = 'ADD_COMMENT';
const DELETE_COMMENT = 'DELETE_COMMENT';

export {
  GET_COMMENTS, LIKE_COMMENT, REPORT_COMMENT, ADD_COMMENT, DELETE_COMMENT, GET_COMMENTS_SUCCESS, GET_COMMENTS_FAIL
};
