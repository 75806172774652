import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import logo from '../../assets/icons/riffrLogoWhite.png';
import constants from '../../utils/constants';

const NavBar = ({ children }) => (
  <ul className="nav">
    <li className="nav-item flex-fill m-auto">
      <a className="nav-link nav-link-logo" href={constants.screens.home}>
        <img src={logo} alt="Logo" className="logo" />
      </a>
    </li>
    {children && <Row className="no-gutters">{children}</Row>}
  </ul>
);

NavBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

NavBar.defaultProps = {
  children: null,
};

export default NavBar;
