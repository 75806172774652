import React from 'react';
import { Col, Row } from 'react-bootstrap';
import sort from '../../assets/icons/sort@3x.png';
import checkmarkCircle from '../../assets/icons/checkmarkCircleOrange.png';
import './jumpTo.scss';

export default function JumpTo({
  jumpTo, setJumpToTarget, jumpToNames, ismd, jumpToTarget
}) {
  const isChecked = (name) => (jumpToTarget.length && name === jumpToTarget) || (!jumpToTarget.length && name === jumpTo);
  return (
    <Col className="no-gutters">
      <div className={`${ismd ? 'd-flex flex-row justify-content-end align-items-center' : ''}`}>
        <Row className="no-gutters">
          <img src={sort} alt="sort" className="mr-3 jumpToIcons" />
          <div className="jumpToTxt">Jump to:</div>
        </Row>
        {
                    jumpToNames.map((name) => (
                      <Row key={name} className={`no-gutters clickable ${ismd ? 'ml-3' : 'mt-4'}`}>
                        {isChecked(name) ? <img src={checkmarkCircle} alt="check" className="mr-3 jumpToIcons" /> : <div className="mr-3 jumpToIcons" />}
                        <div onClick={() => setJumpToTarget(name)} className={`jumpToTxt ${isChecked(name) ? 'jumpToTxtSelected' : ''}`}>{name}</div>
                      </Row>
                    ))
                }
      </div>
    </Col>
  );
}
