import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import './riffSlider.scss';
import Col from 'react-bootstrap/Col';
import playFilled from '../../assets/icons/playFilled.png';
import pauseFilled from '../../assets/icons/pauseFilled.png';
import waveform from '../../assets/icons/waveform.png';
import waveformActive from '../../assets/icons/waveformActive.png';
import constants from '../../utils/constants';

const RiffSlider = ({
  name,
  // length,
  file,
  onPlay,
  classNames,
  readOnly,
  onActionRiff,
  isPlayed,
  data,
  isPlaying,
  hide,
  buffer,
  setFilePreview,
}) => {
  const [marginLeft, setMarginLeft] = useState('0%');
  const [marginLeftSlider, setMarginLeftSlider] = useState('0%');
  const [length, setlength] = useState(1);
  const [canSliderMouseMove, setCanSliderMouseMove] = useState(false);
  const [imageStart, setImageStart] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [imageEnd, setImageEnd] = useState(100);
  const [played, setPlayed] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);
  const [containerHeight, setContainerHeight] = useState('');
  const [maxTime, setMaxTime] = useState(0);
  const audioRef = React.createRef();
  const boxRef = React.createRef();
  const audioContainerRef = React.createRef();
  const firstTime = useRef(true);
  useEffect(() => {
    audioRef.current.onloadedmetadata = () => {
      if (audioRef.current) {
        let len = Math.round(audioRef.current.duration);
        if(len < 5) len = 5;
        setlength(len);
        setRemainingTime(len);
      }
    };
  }, [audioRef, setlength, setRemainingTime]);
  const FindPosition = (oElement) => {
    const imgWidth = oElement.width;
    if (typeof (oElement.offsetParent) !== 'undefined') {
      for (var posX = 0, posY = 0; oElement; oElement = oElement.offsetParent) {
        posX += oElement.offsetLeft;
        posY += oElement.offsetTop;
      }
      return [posX, posY, imgWidth];
    }

    return [oElement.x, oElement.y, imgWidth];
  };
  const GetCoordinates = (e) => {
    if (!audioRef.current.paused && !audioRef.current.ended) {
      audioRef.current.play();
      onPlay('');
    }
    let PosX = 0;
    let ImgPos;
    const waveImage = document.getElementById('waveFormImg');
    ImgPos = FindPosition(waveImage);
    if (!e) e = window.event;
    if (e.pageX || e.pageY) {
      PosX = e.pageX;
    } else if (e.clientX || e.clientY) {
      PosX = e.clientX + document.body.scrollLeft
          + document.documentElement.scrollLeft;
    }
    PosX -= ImgPos[0];
    PosX -= sliderWidth / 2;
    if (PosX < 0) PosX = 0;
    if ((((PosX + (length > 5? 8: 0)) / ImgPos[2]) + (5 / length)) > 1) {
      PosX = ImgPos[2] - ((5 * ImgPos[2]) / length) - (length > 5? 8: 0);
    }
    setSlider(PosX, ImgPos[2]);
  };
  const setSlider = useCallback((PosX, imageWidth) => {
    setImageStart((PosX / imageWidth) * 100);
    setImageEnd(100 - (((PosX + sliderWidth) / imageWidth) * 100));
    setMarginLeft(`${(PosX / imageWidth) * 100}%`);
    setMarginLeftSlider(`${(PosX / imageWidth) * 100}%`);
    const currentTime = (PosX * length) / imageWidth;
    setRemainingTime(length - currentTime);
    audioRef.current.currentTime = currentTime;
    setMaxTime((PosX + sliderWidth) * length / imageWidth);
  }, [audioRef, length, sliderWidth]);

  useEffect(() => {
    const firstIndex = imageStart * buffer.length / 100;
    const lastIndex = (100 - imageEnd) * buffer.length / 100;
    const newBuffer = buffer.slice(firstIndex, lastIndex);
    const newblob = new Blob(newBuffer, { type: 'audio/mp3' });
    setFilePreview(newblob);
  }, [imageStart, imageEnd, buffer, setFilePreview]);

  const stopAudio = () => {
    onPlay('');
    setPlayed(false);
    // setRemainingTime(length);
    setMarginLeft(marginLeftSlider);
  };

  useEffect(() => {
    const handleResize = () => {
      const height = window.getComputedStyle(audioContainerRef.current).getPropertyValue('height');
      setContainerHeight(height);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const sliderMouseDown = () => {
    if (!audioRef.current.paused && !audioRef.current.ended) {
      audioRef.current.play();
      onPlay('');
    }
    setCanSliderMouseMove(true);
  };

  const sliderMouseUp = () => {
    setCanSliderMouseMove(false);
  };

  const sliderMouseMove = (e, canMove = false) => {
    if (canSliderMouseMove || canMove) {
      GetCoordinates(e);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      if ((audioRef.current.paused && !audioRef.current.ended) || audioRef.current.ended) {
        if (!isPlayed && !readOnly) {
          onActionRiff({ ...data, triggeredAction: constants.riffActions.play });
        }
      }
    } else if (!audioRef.current.paused) {
      audioRef.current.pause();
    }
  }, [isPlaying, isPlayed]); // eslint-disable-line

  const getAnimationTransition = (seconds) => {
    const transitionDuration = `${seconds}s`;
    return {
      WebkitTransition: transitionDuration,
      MozTransition: transitionDuration,
      msTransition: transitionDuration,
      OTransition: transitionDuration,
      transition: transitionDuration,
      transitionProperty: 'margin',
      transitionTimingFunction: 'linear',
    };
  };

  useEffect(() => {
    if(length <= 1) return;
    if(!firstTime.current) return;
    const imgWidth = document.getElementById('waveFormImg').width;
    setSliderWidth((5 * imgWidth / length) + (length > 5? 8: 0));
    setSlider(0, imgWidth);
    if(!sliderWidth) return;
    firstTime.current = false;
  }, [length, setSlider, sliderWidth]);

  return (
    <div className={`riffSlider ${classNames} ${hide ? 'hide' : ''}`}>
      <Row className="no-gutters audio-container">
        <Col className="flex-grow-0">
          <div
            className="riffButton"
            id="playPause"
            onClick={() => {
              if ((audioRef.current.paused && !audioRef.current.ended) || audioRef.current.ended) {
                audioRef.current.play();
                onPlay(data.id);
              } else if (!audioRef.current.paused && !audioRef.current.ended) {
                audioRef.current.play();
                onPlay('');
              }
            }}
            aria-hidden="true"
          >
            {
              isPlaying ? <img src={pauseFilled} alt="pauseFilled" className="pauseFilled" />
                : <img src={playFilled} alt="playFilled" className="playFilled" />
            }
          </div>
        </Col>
        <Col className="offset-1 flex-grow-1 text-center">
          <div 
              onMouseLeave={sliderMouseUp}
              className="audio-container-bar"  ref={audioContainerRef}>
            <div style={{ width: '100%', position: 'relative' }}>
              <img
                src={waveformActive}
                style={{
                  position: 'absolute',
                  margin: 'auto',
                  top: 0,
                  bottom: 0,
                  clipPath: `inset(0% ${imageEnd}% 0% ${imageStart}%)`,
                  // ...isPlaying ? getAnimationTransitionWaveForm(remainingTime) : null,
                }}
                alt="waveformActive"
                className={`waveFormActive`}
              />
              <img src={waveform} id="waveFormImg" onClick={(e) => GetCoordinates(e)} alt="waveform" className="waveForm" />
            </div>
            <div
              className={` audioBar ${isPlaying ? 'horizTranslate' : ''}`}
              style={{
                position: 'absolute',
                height: containerHeight,
                marginLeft,
                ...isPlaying ? getAnimationTransition(remainingTime) : null,
                backgroundColor: !played && 'transparent',
              }}
              ref={boxRef}
            />
            <div
              id="sliderBar"
              className={` audioBarSlider ${isPlaying ? 'horizTranslate' : ''}`}
              onMouseDown={sliderMouseDown}
              onMouseUp={sliderMouseUp}
              onMouseMove={sliderMouseMove}
              onTouchMove={(e) => sliderMouseMove(e.changedTouches[0], true)}
              style={{
                position: 'absolute',
                height: containerHeight,
                marginLeft: marginLeftSlider,
                width: sliderWidth,
              }}
              ref={boxRef}
            />
          </div>
        </Col>
        <Col className="offset-1 flex-grow-0 timeContainer d-none d-sm-none d-md-block d-lg-block" />
      </Row>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        src={file}
        ref={audioRef}
        onTimeUpdate={({ target }) => {
          if (target.currentTime - maxTime >= -0.4) {
            audioRef.current.pause();
            onPlay('');
          }
        }}
        onEnded={stopAudio}
        onPlay={() => setPlayed(true)}
        onPause={() => {
          setMarginLeft(marginLeftSlider);
          audioRef.current.currentTime = length - remainingTime;
        }}
      />
    </div>
  );
};

RiffSlider.propTypes = {
  name: PropTypes.string,
  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  file: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool,
  onPlay: PropTypes.func.isRequired,
  classNames: PropTypes.string,
  readOnly: PropTypes.bool,
  onActionRiff: PropTypes.func,
  isPlayed: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  hide: PropTypes.bool,
};

RiffSlider.defaultProps = {
  name: '',
  classNames: '',
  readOnly: true,
  isPlaying: false,
  onActionRiff: undefined,
  isPlayed: false,
  data: null,
  hide: false,
};

export default memo(RiffSlider);
