import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import logger from 'redux-logger';
import authenticationReducer from './reducers/authenticationReducer';
import userReducer from './reducers/userReducer';
import connectionsReducer from './reducers/connectionsReducers/connectionsReducer';
import resetPasswordReducer from './reducers/resetPasswordReducer';
import homepageReducer from './reducers/homepageReducer';
import sharedRiffReducer from './reducers/sharedRiffReducers';
import commentsReducer from './reducers/commentsReducer';
import debateReducer from './reducers/debateReducers/debateReducer';
import recordReducer from './reducers/recordReducer';
import searchReducer from './reducers/searchReducer';
import searchHistoryReducer from './reducers/searchHistoryReducer';
import openMicReducer from './reducers/openMicReducer';
import riffReducer from './reducers/riffReducer';
import blockedMutedReducer from './reducers/blockedMutedReducers';
import httpMiddleware from '../middlewares/http';

const appReducer = combineReducers({
  authentication: authenticationReducer,
  user: userReducer,
  sharedRiff: sharedRiffReducer,
  connections: connectionsReducer,
  resetPassword: resetPasswordReducer,
  home: homepageReducer,
  comments: commentsReducer,
  debate: debateReducer,
  record: recordReducer,
  search: searchReducer,
  searchHistory: searchHistoryReducer,
  openMic: openMicReducer,
  blockedMuted: blockedMutedReducer,
  riff: riffReducer
});

const persistConfig = {
  key: 'authentication',
  storage,
  whitelist: ['authentication', 'searchHistory'],
};
const pReducer = persistReducer(persistConfig, appReducer);
const store = createStore(pReducer, applyMiddleware(thunk, httpMiddleware));
const persistor = persistStore(store);

export { persistor, store };
