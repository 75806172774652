import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { toast, ToastContainer } from 'react-toastify';
import Page from '../../components/pageContent/pageContent';
import NonAuthenticatedNavBar from '../../components/nonAuthenticatedNavBar/nonAuthenticatedNavBar';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import constants from '../../utils/constants';
import PageTitle from '../../components/pageTitle/pageTitle';
import ContactUsForm from '../../components/contactUsForm/contactUsForm';
import './contactUs.scss';
import { contactUs } from '../../services/common';
import person from '../../assets/icons/person.png';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';

const ContactUs = ({ match }) => {
  const { t } = useTranslation();
  const isAuthenticated = match.url === constants.screens.getInTouch;
  const form = React.createRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const toastContent = (
    <div>
      <Row className="no-gutters">
        <Col xs={2} className="align-self-center text-center">
          <img src={person} alt="person" width="20" height="20" />
        </Col>
        <Col xs={10}>
          <div>{t('homepage.thankYouForGettingInTouch')}</div>
          <div className="app-body">{t('homepage.messageSent')}</div>
        </Col>
      </Row>
    </div>
  );

  const onSubmit = async (data, formRef) => {
    try {
      setLoading(true);
      await contactUs(data);
      formRef.resetForm();
      setLoading(false);
      toast(toastContent, {
        position: 'top-center',
        hideProgressBar: true,
        draggable: true,
        className: 'welcome-toast',
      });
    } catch (e) {
      setError(t('globals.somethingWentWrong'));
      setLoading(false);
    }
  };

  return (
    <Page header={isAuthenticated? <AuthenticatedNavBar activeKey="profile" />: <NonAuthenticatedNavBar />}>
      <Row className="no-gutters mt-5 mt-sm-0 contact-us">
        <Col xs={1} sm={3} lg={4} className="flex-fill">
          <PageLeftContent title={t(isAuthenticated? 'globals.backToSettings': 'globals.back')} navigateToRef={isAuthenticated? constants.screens.settings: constants.screens.landing} />
        </Col>
        <Col xs={10} sm={6} lg={4}>
          <PageTitle>{t('homepage.getInTouch')}</PageTitle>
          <div className="light mb-3">{t('homepage.getInTouchSentence1')}</div>
          <div className="light">{t('homepage.getInTouchSentence2')}</div>
          {error && (
            <Alert variant="danger">
              {error}
            </Alert>
          )}
          <ContactUsForm formReference={form} onSubmit={onSubmit} />
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right mt-4 pt-3 pb-3 pl-5 pr-5"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('globals.submit')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </Col>
      </Row>
      <ToastContainer autoClose={4000} />
    </Page>
  );
};

export default ContactUs;
