import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Col, Row, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import ClickableText from '../../components/clickableText/clickableText';
import Page from '../../components/pageContent/pageContent';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import constants from '../../utils/constants';
import warningLargeBlack from '../../assets/icons/warningLargeBlack.png';
import Riff from '../../components/riff/riff';
import './tagPost.scss';
import SearchTag from '../../components/searchTag/searchTag';
import helpCircleOutline from '../../assets/icons/helpCircleOutline.png';
import RecordDescription from '../../components/recordDescription/recordDescription';
import RiffSlider from '../../components/riffSlider/riffSlider';
import { addRiff, deleteDraft, getDrafts, resetAudio, setAudioFile, setIsDraft, updateRiff } from '../../store/actions/recordActions';
import { useParams } from 'react-router-dom';
import { getRiffById } from '../../store/actions/homepageActions';
import ConfirmationDialog from '../../components/confirmationDialog/confirmationDialog';

export default function TagPost({ history }) {
  const { t } = useTranslation();
  const record = useSelector((state) => state.record);
  const form = React.createRef();
  const [audioPlaying, setAudioPlaying] = useState(0);
  const [audioPlaying1, setAudioPlaying1] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showTagInfo, setShowTagInfo] = useState(true);
  const [filePreview, setFilePreview] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [draft, setDraft] = useState(null);
  const [showRiff, setShowRiff] = useState(true);
  const [showDescriptionInfo, setShowDescriptionInfo] = useState(true);
  const firstTime = useRef(true);
  const { draftId, riffId } = useParams();
  const home = useSelector((state) => state.home);
  const riff = {
    id: '1',
    file: record.audioFile,
    currentTime: 0,
  };
  const dispatch = useDispatch();
  const { isUploadMode, loading, drafts } = record;
  useEffect(() => {
    if(draftId) {
      dispatch(getDrafts(1));
    }
    if(riffId){
      dispatch(getRiffById(riffId));
    }
  }, [dispatch, draftId, riffId]);

  useEffect(() => {
    if(riffId && home && home.allIds && home.allIds[0]) {
      const riff = home.byId[home.allIds[0]];
      if(riff) {
        setDraft(riff);
        setSelectedTags(riff.tags);
        dispatch(setAudioFile(riff.file, null, []));
      }
    }
  },[home, riffId, dispatch])
  useEffect(() => {
    if(drafts.result && draftId) {
      let isNotValid = true;
      for(const draft of drafts.result) {
        if(draft.id === draftId) {
          isNotValid = false;
          setDraft(draft);
          setSelectedTags(draft.tags);
          fetch(draft.file)
            .then(res => res.blob())
            .then(blob => {
              dispatch(setAudioFile(draft.file, blob, []));
          });
        }
      }
      if(isNotValid) {
        history.push(constants.screens.record);
      }
    }
  }, [drafts, dispatch, draftId, history]);
  useEffect(() => {
    if((!draft || !(form.current && form.current.values)) && (draftId || riffId)) {
      return
    };
    if(!firstTime.current) {
      return;
    }
    if(form.current && form.current.values) {
      form.current.values.description = "";
    }
    if(form.current && form.current.values && draft) {
      form.current.values.description = draft.name;
    }
    firstTime.current = false;
  },[record, draft, form, firstTime, draftId, riffId]);

  const cancelRecording = () => (
    <ClickableText
      text={t(riffId? 'record.deleteRiff': ((isUploadMode || draftId)? 'record.cancelRiff': 'record.cancelRecording'))}
      textClass="error-text"
      icon={warningLargeBlack}
      onClick={() => {
        removeEventListener();
        window.removeEventListener('scroll', handleScroll, false);
        if(riffId){
          setShowConfirmationDialog(true);
        } else {
          dispatch(resetAudio());
          history.push(constants.screens.record);
        }
      }}
    />
  );
  useEffect(() => {
    if (!record.audioFile && !draftId && !riffId) {
      history.goBack();
    }
  }, [history, record.audioFile, draftId, riffId]);
  useEffect(() => {
    if (isSubmitted && !loading) {
      if(riffId) {
        history.push(constants.screens.home);
      } else {
        history.push(constants.screens.record);
      }
    }
  }, [loading, isSubmitted, history, riffId]);
  const removeEventListener = () => window.removeEventListener('scroll', handleScroll, { passive: true });
  const submitRiff = () => {
    removeEventListener();
    dispatch(setIsDraft(false));
    setIsSubmitted(true);
    const formData = new FormData();
    formData.append('publish', '1');
    formData.append('name', form.current.values.description);
    formData.append('tags', selectedTags);
    formData.append('file', record.blob, 'file1.mp3');
    formData.append('filePreview', filePreview, 'file2.mp3');
    dispatch(addRiff(formData)).then(() => {
      draftId && dispatch(deleteDraft(draftId));
    });
  };
  const saveAsDraft = () => {
    removeEventListener();
    dispatch(setIsDraft(true));
    setIsSubmitted(true);
    const formData = new FormData();
    formData.append('publish', '0');
    formData.append('public', '0');
    draftId && formData.append('id', draftId);
    formData.append('name', form.current.values.description);
    formData.append('tags', selectedTags);
    formData.append('file', record.blob, 'file1.mp3');
    formData.append('filePreview', filePreview, 'file2.mp3');
    dispatch(addRiff(formData)).then(() => {
      // draftId && dispatch(deleteDraft(draftId));
    });
  };

  const upDateDraft = () => {
    removeEventListener();
    // dispatch(setIsDraft(true));
    setIsSubmitted(true);
    const formData = new FormData();
    formData.append('publish', '1');
    formData.append('id', riffId);
    formData.append('name', form.current.values.description);
    formData.append('tags', selectedTags);
    formData.append('filePreview', filePreview, 'file2.mp3');
    dispatch(updateRiff(formData, draftId || riffId));
  };

  const handleScroll = () => {
    if (document.getElementById('audioRiff')) {
      const domRect = document.getElementById('audioRiff').getBoundingClientRect();
      if (domRect.top < -30) {
        setShowRiff(false);
      } else {
        setShowRiff(true);
      }
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);
  if (record.audioFile) {
    return (
      <Page header={<AuthenticatedNavBar activeKey="record" />} hideMargin>
        <ConfirmationDialog
                show={showConfirmationDialog}
                onHide={() => setShowConfirmationDialog(false)}
                handleClose={() => setShowConfirmationDialog(false)}
                onConfirm={() => {
                  setIsSubmitted(true);
                  dispatch(deleteDraft(riffId));
                }}
                title={t('record.deleteRiff').toLowerCase()}
              />
        <Row className="no-gutters justify-content-between align-items-center pr-2 pl-2 pt-2 pb-2 d-lg-none clickable-items topRowFixed w-100 position-fixed">
          <div>
          {
            riffId? (
              <PageLeftContent showMd title={t('globals.backToFeed')} navigateToRef={constants.screens.home} />
            ) :(
              // isUploadMode? (
              //   <PageLeftContent showMd title={t('record.backToRecord')} navigateToRef={constants.screens.record} />
              // ) : (
                <PageLeftContent showMd onClick={() => saveAsDraft()} title={t('record.backToRecord')} />
              // )
            )
          }
          </div>
          <div className="mr-2 align-self-center">
            {cancelRecording()}
          </div>
        </Row>
        <Row className="no-gutters mt-5 pt-4 pt-lg-0 mt-lg-4">
          <Col xs={1} sm={3} lg={4} className="flex-fill d-none d-sm-none d-md-none d-lg-block">
            {
              riffId? (
                <PageLeftContent showMd title={t('globals.backToFeed')} navigateToRef={constants.screens.home} />
              ) :(
                // isUploadMode? (
                //   <PageLeftContent title={t('record.backToRecord')} navigateToRef={constants.screens.record} />
                // ) : (
                  <PageLeftContent onClick={() => saveAsDraft()} title={t('record.backToRecord')} />
                // )
              )
            }
          </Col>
          <Col xs={10} sm={6} lg={4} className="text-center offset-1 offset-sm-3 offset-lg-0 offset-xl-0">
            <PageTitle>{t(riffId? 'record.editYourRiff': 'record.tag&Post')}</PageTitle>
            <div className="d-flex flex-row justify-content-center w-100">
              <h6 className="subTitle">
                {t('tagPost.subTitle')}
              </h6>
            </div>
            <div className="text-left" id="audioRiff">
              <div className={riff.id === audioPlaying && !showRiff ? 'sideRiff' : ''}>
                <Riff
                  name={showRiff ? 'My riff' : 'Your riff'}
                  description={showRiff ? '' : 'now playing'}
                  file={record.audioFile}
                  key="1"
                  index="1"
                  indexPlaying=""
                  classNames="feed-riff-item"
                  readOnly
                  hideActions
                  commentCount="0"
                  length={record.audioLength}
                  playCount="0"
                  echoCount="0"
                  likes="0"
                  data={riff}
                  onPlay={(playId) => {
                    setAudioPlaying(playId);
                  }}
                  isPlaying={`${riff.id}-1` === audioPlaying}
                  isSideRiff={!showRiff}
                />
                <div className="d-none d-sm-block d-md-block d-lg-block no-gutters">
                  {
                                        !showRiff && riff.id === audioPlaying && cancelRecording()
                                    }
                </div>
              </div>
            </div>
            <div className="text-left">
              <h6 className={`${showDescriptionInfo ? 'mb-0' : 'mb-3'} font-weight-bold p-2`}>{t('tagPost.choose5Sec')}</h6>
              <h6 className="font-weight-light pl-2 pr-2">{t('tagPost.moveSlider')}</h6>
              <RiffSlider
                name="My riff"
                file={record.audioFile}
                key="1"
                index={0}
                indexPlaying=""
                classNames="feed-riff-item"
                readOnly
                length={record.audioLength}
                data={riff}
                onPlay={(playId) => {
                  setAudioPlaying1(playId);
                }}
                isPlaying={riff.id === audioPlaying1}
                buffer={record.buffer}
                setFilePreview={setFilePreview}
              />
            </div>
            <div className="text-left mt-3">
              <h6 className={`${showTagInfo ? 'mb-0' : 'mb-3'} font-weight-bold p-2`}>
                {t('tagPost.add3Topics')}
                { !showTagInfo ? <img className="pl-2 clickable" onClick={() => setShowTagInfo(true)} src={helpCircleOutline} alt="info" /> : null}
              </h6>
              { showTagInfo
                ? (
                  <h6 className="font-weight-light pl-2 pr-2">
                    {t('tagPost.topicDescription')}
                    {' '}
                  </h6>
                ) : null}
              <SearchTag selectedTags={selectedTags} setSelectedTags={setSelectedTags} setShowTagInfo={setShowTagInfo} />
            </div>
            <div className="text-left mt-4" id="description">
              <h6 className={`${showDescriptionInfo ? 'mb-0' : 'mb-3'} font-weight-bold p-2`}>
                {t('tagPost.shortDescription')}
                { !showDescriptionInfo ? <img className="pl-2 clickable" onClick={() => setShowDescriptionInfo(true)} src={helpCircleOutline} alt="info" /> : null}
              </h6>
              { showDescriptionInfo
                ? <h6 className="font-weight-light topicDesc pl-2 pr-2">{t('tagPost.shortDescriptionDescription')}</h6> : null}
              <RecordDescription formReference={form} />
            </div>
            <Button onClick={() => riffId? upDateDraft(): submitRiff()} disabled={selectedTags.length === 0} className={`btn postRiff mt-4 w-100 bold ${selectedTags.length > 0 ? 'postRiff-active' : ''}`}>{t(riffId? 'record.editRiff': 'record.postRiff')}</Button>
            <div style={{ height: '25vh' }} />
          </Col>
          <Col lg={4} className="d-none d-sm-none d-md-none d-lg-block">
          {
            (showRiff || riff.id !== audioPlaying) && cancelRecording()
          }
          </Col>
        </Row>
        <div hidden={!loading} className="loader">
          <Spinner animation="border" className="spinner" />
        </div>
      </Page>
    );
  }
  return (<div></div>);
}
