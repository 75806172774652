const ADD_HOST_RIFF = 'ADD_HOST_RIFF';
const ADD_HOST_RIFF_SUCCESS = 'ADD_HOST_RIFF_SUCCESS';
const ADD_HOST_RIFF_FAIL = 'ADD_HOST_RIFF_FAIL';

const GET_HOST_RIFFS = 'GET_HOST_RIFFS';
const GET_HOST_RIFFS_SUCCESS = 'GET_HOST_RIFFS_SUCCESS';
const GET_HOST_RIFFS_FAIL = 'GET_HOST_RIFFS_FAIL';

const GET_HOST_PAGE = 'GET_HOST_PAGE';
const GET_HOST_PAGE_SUCCESS = 'GET_HOST_PAGE_SUCCESS';
const GET_HOST_PAGE_FAIL = 'GET_HOST_PAGE_FAIL';

const GET_HOST_NAMES = 'GET_HOST_NAMES';
const GET_HOST_NAMES_SUCCESS = 'GET_HOST_NAMES_SUCCESS';
const GET_HOST_NAMES_FAIL = 'GET_HOST_NAMES_FAIL';

const SET_IS_SUBMITTED = 'SET_IS_SUBMITTED'

export {
  ADD_HOST_RIFF,
  ADD_HOST_RIFF_SUCCESS,
  ADD_HOST_RIFF_FAIL,
  GET_HOST_RIFFS,
  GET_HOST_RIFFS_SUCCESS,
  GET_HOST_RIFFS_FAIL,
  SET_IS_SUBMITTED,
  GET_HOST_PAGE,
  GET_HOST_PAGE_SUCCESS,
  GET_HOST_PAGE_FAIL,
  GET_HOST_NAMES,
  GET_HOST_NAMES_SUCCESS,
  GET_HOST_NAMES_FAIL
};
