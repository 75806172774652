import {
  ADD_DEBATE_RIFF, DEBATE_VOTE,
  GET_DEBATE_RIFFS_BIDEN,
  GET_DEBATE_RIFFS_TRUMP,
} from '../constants/debateConstants';
import constants from '../../utils/constants';

/**
 * @param {string} user
 * @param {number} page
 */
export const getDebateRiffsByUser = (user, page) => ({
  type: user === constants.debates.users.trump ? GET_DEBATE_RIFFS_TRUMP : GET_DEBATE_RIFFS_BIDEN,
  payload: {
    request: {
      url: `host/debate2020/riff/${user}`,
      method: 'get',
      authenticated: false,
      params: {
        pageLimit: constants.homepageRiffsLimit,
        page,
      },
    },
  },
});

/**
 * @param {string} user
 * @param {object} data
 */
export const addDebateRiffsByUser = (user, data) => ({
  type: ADD_DEBATE_RIFF,
  payload: {
    request: {
      url: `${constants.uploadUrl}/host/debate2020/riff/${user}`,
      method: 'post',
      authenticated: false,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  },
});

/**
 * @param {string} user
 */
export const voteByUser = (user) => ({
  type: DEBATE_VOTE,
  payload: {
    request: {
      url: 'host/debate2020/vote',
      method: 'post',
      authenticated: false,
      data: {
        groupName: user,
      },
    },
    user,
  },
});
