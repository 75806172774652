import React from 'react';

/* eslint-disable */
const PrivacyPolicy = () => (
  <>
    <p className="p1"><strong>Privacy Policy</strong></p>
    <p className="p1">Date of Last Revision: November 9, 2018</p>
    <p className="p2">
      Riffr, (&ldquo;Riffr&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;) provides a social network powered
      by sound.
      <span className="Apple-converted-space">&nbsp; </span>
      This Privacy Policy (&ldquo;Policy&rdquo;) governs
      the way that Riffr collects, uses, maintains, and discloses information from you through your use of our website
      located at
      <a href="http://www.riffr.com"><span className="s1"> www.riffr.com</span></a>
      {' '}
      (the &ldquo;Site&rdquo;) and
      through our mobile application and software platform (collectively, such services, including any new features and
      applications, and the Site, are referred to as the &ldquo;Service(s)&rdquo;).
    </p>
    <p className="p2">
      Riffr&rsquo;s Services are directed at and only intended for U.S. users 18 years and older.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      By accessing or using the Services, you agree to this Policy and to
      the Terms of Use.
      <span className="Apple-converted-space">&nbsp; </span>
      If you do not agree with this Policy or the
      Terms of Use, you may NOT use the Services
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>What Personal Information We Collect and Why</strong></span></li>
    </ol>
    <p className="p4">
      We may collect your information directly from you as well as automatically through your use of our
      Services:
    </p>
    <p className="p5">
      <span className="s3">Information You Choose to Give Us</span>
      : When you use our Services, we collect
      the information that you choose to share with us. For example, portions of our Services require you to set up a
      basic Riffr account, requiring you to provide a username, password, email address and phone number. You may also
      have the option of providing a profile picture. In addition, you will provide Riffr whatever information you send
      through our Services, such as Riffs and comments to your friends. Keep in mind that the users you send Riffs and any
      other content to can always save that content or copy it outside Riffr&rsquo;s Services. So, you should not send
      messages or share content that you would not want someone to save or share. Finally, when you contact customer
      support or communicate with us in any other way, we&rsquo;ll collect whatever information you volunteer.
    </p>
    <p className="p5">
      <span className="s3">Information We Get When You Use Our Services</span>
      : When you use our Services,
      we collect information about which services you use and how you use them. We might know, for instance, that you
      listened to a particular Riff or the number of messages you exchange with your friends through the Services.
    </p>
    <p className="p5">
      <span className="s3">Cookies and Other Tracking Mechanisms</span>
      . A cookie is a small file placed
      on your computer or mobile device. We may use cookies and similar technologies to support the functionality of our
      Services. This provides a better experience when you use our Services and allows us to improve our Services. It may
      be possible to refuse to accept cookies or mobile cookies by activating the appropriate setting on your browser or
      smartphone. However, if you select this setting you may be unable to use certain functionalities of our Services.
    </p>
    <p className="p6">&nbsp;</p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>How We Use Your Personal Information</strong></span></li>
    </ol>
    <p className="p4">We use your personal information for the following purposes:</p>
    <ul className="ul1">
      <li className="li2">
        <span className="s2">To provide the Services you request, to communicate with you about your use of our Services and to respond to your inquiries.</span>
      </li>
      <li className="li2"><span className="s2">To personalize your experiences while using the Services.</span></li>
      <li className="li2">
        <span className="s2">For marketing purposes, such as using your email address, to contact you about services or information we think may interest you. In addition, you may receive communications from us if you have contacted us for a particular reason, and/or to receive the information or Service you have requested.</span>
      </li>
      <li className="li2">
        <span className="s2">
          We may send you emails from time to time in order to provide you with the Services and to manage your account.
          <span
            className="Apple-converted-space"
          >
&nbsp;
          </span>
          We may send you a welcoming message, account confirmations, notifications and reminders, and we may also contact you on behalf of other users sending you messages. We may also send you promotional emails and Service-related emails.
          <span
            className="Apple-converted-space"
          >
&nbsp;
          </span>
          You may be able to opt out from some, but not all, of these communications.
          <span
            className="Apple-converted-space"
          >
&nbsp;
          </span>
          If you do not want to receive any communications from us, you have the option to deactivate your account.&nbsp;
        </span>
      </li>
      <li className="li2">
        <span className="s2">To better understand how users access and use our Services, both on an aggregated and individualized basis, in order to improve our Services and respond to member desires and preferences, and for other research and analytical purposes.</span>
      </li>
      <li className="li3">
        <span className="s2">We may also use your personal information for administrative reasons, including but not limited to, addressing account issues, troubleshooting issues, responding to your inquiries and enforcing and applying our Terms of Use.</span>
      </li>
    </ul>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>How We Share Your Information</strong></span></li>
    </ol>
    <p className="p4">
      In general, we will not share your personal information with third parties unless it is necessary to
      provide Services to you, to comply with any legal processes and/or law enforcement requests, and/or in order to
      conduct any business that Riffr, in its sole subjective discretion, deems reasonable. We may share personal
      information with affiliated companies, but we will require them to honor this Privacy Policy.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      If another company acquires our company, that company will own
      your information and will assume the rights and obligations with respect to that information as described in this
      Privacy Policy.
      <span className="Apple-converted-space">&nbsp; </span>
      To the maximum extent allowable by law, this
      Privacy Policy will apply to your personal information when transferred to the successor entity. &nbsp;However,
      personal information submitted after a transfer to a successor entity may be subject to a new privacy policy adopted
      by the successor entity.
    </p>
    <p className="p4">We may also share aggregate data about users that does not identify individual users.</p>
    <p className="p7">
      We do not share your personal information with third parties for direct marketing purposes without
      first obtaining your explicit consent to do so.&nbsp;
    </p>
    <ol className="ol1">
      <li className="li3">
        <span
          className="s2"
        >
          <strong>What Information May be Viewed by Other Service Users</strong>
        </span>
      </li>
    </ol>
    <p className="p4">
      Personal information from your profile will be visible to other users.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      The type of information shared with another user depends on your
      level of connection with such user.
      <span className="Apple-converted-space">&nbsp; </span>
      The type of information
      shared with other users is subject to change.
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>How We Keep Your Information Secure</strong></span></li>
    </ol>
    <p className="p4">
      We attempt to securely store information in on our Services, but we cannot guarantee that security,
      nor can we guarantee that information you provide to us will not be intercepted while being transmitted through the
      internet because data that is transported over an open network is accessible to others.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      Any information you submit to our Services is at your own risk and
      Riffr does not accept any liability for direct or indirect losses resulting from the security of your personal
      information. This information may be lost during transmission or accessed by unauthorized parties.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      We suggest that you do not share your password and that you change
      it immediately if you believe that your account has been accessed by an unauthorized party.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      We make no guarantee that your personal information will not be
      accessed, altered, disclosed, or destroyed by a security breach and by using our Services you agree to, understand,
      and assume these risks.&nbsp;
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>Hyperlinks to Other Websites</strong></span></li>
    </ol>
    <p className="p4">
      Riffr does not and cannot guarantee the security of a linked site that you access through the
      Services.
      <span className="Apple-converted-space">&nbsp; </span>
      All third-party sites have independent privacy
      policies and we cannot guarantee the accuracy of any information contained on a linked site, nor do we have any
      responsibility or liability for the content of any linked site.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      If you click a link to an outside website, you will leave our
      Services and are subject to the privacy and security policies of the outside website. &nbsp;Riffr is not responsible
      or liable for any transmissions you may receive from linked websites, or any issues users have with linked websites.
    </p>
    <ol className="ol1">
      <li className="li3">
        <span
          className="s2"
        >
          <strong>What Choices Do I Have Regarding Use of My Personal Information?</strong>
        </span>
      </li>
    </ol>
    <p className="p4">
      If you do not want your personal information collected or stored, please do not submit it to us. If
      you have already submitted personal information and would like us to correct or remove your information from our
      records, please contact us at
      <span className="s6">support@riffr.com</span>
      <span className="s7">
        .
        <span
          className="Apple-converted-space"
        >
&nbsp;
        </span>
      </span>
      Unsubscribing from our emails is not equivalent to deleting
      your account, but will instead discontinue your receipt of automatically generated emails from our Service.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      You may remove personal information from your profile by editing
      your profile, but some personal information is required in order for you to have an account.
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>Children&rsquo;s Online Privacy Protection Act</strong></span></li>
    </ol>
    <p className="p4">
      Our Services are not directed at and should not be used by children under the age of 13.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      We support and comply with the Children&rsquo;s Online Privacy
      Protection Act (COPPA).
      <span className="Apple-converted-space">&nbsp; </span>
      Riffr does not solicit or collect
      personal information from children under the age of 13.
      <span className="Apple-converted-space">&nbsp; </span>
      By
      registering for the Services, you represent and warrant that you are over 18 years of age.&nbsp;
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong> &ldquo;Do Not Track&rdquo; Signals</strong></span></li>
    </ol>
    <p className="p4">
      Some web browsers have &ldquo;Do Not Track&rdquo; or similar features that enable you to tell
      websites that you do not want your activities on that website tracked. In general, our Site does not respond
      to &ldquo;Do Not Track&rdquo; signals and consequently, the Site will continue to collect information about you even
      if your browser&rsquo;s &ldquo;Do Not Track&rdquo; feature is activated.
    </p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>Changes to Privacy Policy</strong></span></li>
    </ol>
    <p className="p8">
      Riffr may revise this privacy policy as needed and any revisions will apply to information supplied
      before and after any changes.
      <span className="Apple-converted-space">&nbsp; </span>
      Please re-read this policy from
      time to time.&nbsp;
    </p>
    <p className="p8">This Privacy Policy was last modified on November 9, 2018</p>
    <ol className="ol1">
      <li className="li3"><span className="s2"><strong>Contacting Us</strong></span></li>
    </ol>
    <p className="p8">If you have any questions regarding these terms or wish to contact us for any matter:</p>
    <p className="p9">Riffr</p>
    <p className="p9">
      4 Faneuil Hall, 4
      <span className="s8"><sup>th</sup></span>
      {' '}
      Floor
    </p>
    <p className="p9">Boston, MA 02109</p>
    <p className="p10">
      <span className="s9">Email</span>
      <span className="s7">: </span>
      support@riffr.com
    </p>
    <p className="p11">&nbsp;</p>
    <p className="p11">&nbsp;</p>
    <p className="p12">4828-8780-8378.1</p>
  </>
);
/* eslint-enable */

export default PrivacyPolicy;
