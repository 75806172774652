import { CLEAR_SEARCH_HISTORY, SAVE_SEARCH_HISTORY } from '../constants/searchConstants';

const saveSearchHistory = (data, userId) => ({
  type: SAVE_SEARCH_HISTORY,
  payload: {data, userId},
});

const clearSearchHistory = (userId) => ({
  type: CLEAR_SEARCH_HISTORY,
  payload: {userId},
});

export { saveSearchHistory, clearSearchHistory };
