import {
  GET_FOLLOWERS,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL, GET_USER_SUCCESS,
} from '../../constants/connectionsConstants';

const initialState = {
  result: {
    currentPage: 0,
  },
  items: [],
  loading: false,
  error: null,
  hasMore: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOLLOWERS: {
      const { params: { page } } = action.payload.request;
      return {
        ...state,
        items: page === 1 ? [] : state.items,
        result: null,
        loading: true,
        error: null,
      };
    }
    case GET_FOLLOWERS_SUCCESS:
      if (action.payload.data.length === 0) {
        return {
          ...state,
          result: null,
          items: [],
          loading: false,
          hasMore: false,
        };
      }
      return {
        ...state,
        result: action.payload.data,
        items: action.payload.data.result ? state.items.concat(action.payload.data.result) : state.items,
        loading: false,
        hasMore: action.payload.data.currentPage < action.payload.data.totalPages,
      };
    case GET_FOLLOWERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_SUCCESS: {
      const { id, actions } = action.payload.data;
      const updatedItems = [...state.items];
      for (let i = 0; i < updatedItems.length; i += 1) {
        if (updatedItems[i].id === id) {
          updatedItems[i].actions = actions;
          break;
        }
      }
      return {
        ...state,
        items: updatedItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;
