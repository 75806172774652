import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from 'react';
import { useTranslation } from 'react-i18next';
import riffrLogoBlack from '../../assets/icons/riffrLogoBlack.png';
import './footer.scss';
import constants from '../../utils/constants';

const { screens } = constants;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="homepage-footer">
      <div className="d-none d-sm-block">
        <Row>
          <img src={riffrLogoBlack} alt="riffrLogoBlack" width="20" height="20" className="align-self-center" />
          <a className="nav-link" href={screens.blog}><span className="app-value">{t('homepage.blog')}</span></a>
          <a className="nav-link" href={screens.contactUs}><span className="app-value">{t('homepage.contact')}</span></a>
          <a className="nav-link" href={screens.privacyPolicy}><span className="app-value">{t('homepage.privacyPolicy')}</span></a>
          <a className="nav-link" href={screens.terms}>
            <span
              className="app-value"
            >
              {t('homepage.termsOfUse')}
            </span>
          </a>
          <div className="flex-fill">
            <a className="nav-link disabled float-right" href="/#"><span className="app-body light">{t('homepage.copyrights')}</span></a>
          </div>

        </Row>
      </div>
      <div className="d-block d-sm-none">
        <Row className="no-gutters nav-links-container justify-content-between">
          <span>
            <a className="nav-link" href={screens.blog}><span className="app-value">{t('homepage.blog')}</span></a>
          </span>
          <span>
            <a className="nav-link" href={screens.contactUs}><span className="app-value">{t('homepage.contact')}</span></a>
          </span>
          <span>
            <a className="nav-link" href={screens.privacyPolicy}><span className="app-value">{t('homepage.privacyPolicy')}</span></a>
          </span>
          <span>
            <a className="nav-link text-right" href={screens.terms}>
              <span
                className="app-value"
              >
                {t('homepage.termsOfUse')}
              </span>
            </a>
          </span>
        </Row>
        <Row className="no-gutters copyrights-container">
          <Col xs={2}>
            <img src={riffrLogoBlack} alt="riffrLogoBlack" width="15" height="15" className="align-self-center" />
          </Col>
          <Col xs={10}>
            <a className="nav-link disabled" href="/#"><span className="app-body light">{t('homepage.copyrights')}</span></a>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
