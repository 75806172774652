import * as Yup from 'yup';
import constants from './constants';
import moment from "moment";

export const formatSeconds = (totalSeconds) => {
  const seconds = totalSeconds % 60;
  return `${Math.floor(totalSeconds / 60)}:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`;
};

export const formatDate = (date) => {
  return moment.utc(date).local().format('Do MMM');
};

export const formatDate1 = (date) => {
  return moment.utc(date).local().format('Do MMM YYYY HH:mm');
};

export const dateDifferenceWithOffset = (date) => {
  const oldDate = new Date(date);
  const pureDate = oldDate.getTime() - oldDate.getTimezoneOffset()*60*1000;
  return dateDifference(pureDate);
}
export const dateDifference = (date) => {
  const dateTemp = new Date(date);
  const today = new Date();
  const diff = Math.abs(today - dateTemp);
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  let result = '';
  if (days > 364) {
    const years = Math.floor(days / 365);
    result = `${years} year${years > 1 ? 's' : ''} ago`;
  } else if (days > 29) {
    const months = Math.floor(days / 30);
    result = `${months} month${months > 1 ? 's' : ''} ago`;
  } else if (days > 0) {
    result = `${days} day${days > 1 ? 's' : ''} ago`;
  } else if (hours > 0) {
    result = `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (minutes > 0) {
    result = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else {
    result = `${seconds} second${seconds > 1 ? 's' : ''} ago`;
  }
  return result;
};

export const getClientInformation = () => ({
  client_id: constants.clientId,
  client_secret: constants.clientSecret,
});

export const mapMinMax = (xMin, xMax, yMin, yMax, value) => {
  let temp = value / (xMax - xMin);
  temp = temp > 1? 1: temp;
  return temp * (yMax - yMin);
};

export const commaSeparatedNumber = (number) => number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

/**
 * @param {string} username
 */
export const isEmail = async (username) => {
  try {
    const formSchema = Yup.object({
      username: Yup.string().email(),
    });
    await formSchema.validate({ username });
    return true;
  } catch {
    return false;
  }
};

// eslint-disable-next-line max-len
export const formatTime = (minutes, seconds) => (`${minutes.toString().length === 1 ? `0${minutes}` : minutes}:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`);

export const toUpperCaseFirstLetter = (text) => {
  if(!text) return text;
  const txtArr = text.split(' ');
  const result = [];
  for(const txt of txtArr) {
    const chars = txt.split('');
    if(chars[0])
      chars[0] = chars[0].toUpperCase();
    result.push(chars.join(''));
  }
  return result.join(' ');
}