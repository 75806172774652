import {
  GET_COMMENTS, LIKE_COMMENT, REPORT_COMMENT, ADD_COMMENT, DELETE_COMMENT,
} from '../constants/commentConstants';

/**
 * @param {string|number} riffId
 * @param {number} page
 */
export const getComments = (riffId, page) => ({
  type: GET_COMMENTS,
  payload: {
    request: {
      url: `riff/${riffId}/comments`,
      method: 'get',
      authenticated: true,
      params: {
        page,
        pageLimit: 5,
      },
    },
    page,
  },
});

/**
 * @param {string|number} commentId
 * @param {boolean} isLike
 */
export const likeComment = (commentId, isLike) => ({
  type: LIKE_COMMENT,
  payload: {
    request: {
      url: `comment/${commentId}/${isLike ? 'like' : 'unlike'}`,
      method: 'post',
      authenticated: true,
    },
  },
});

/**
 * @param {string|number} commentId
 */
export const reportComment = (commentId) => ({
  type: REPORT_COMMENT,
  payload: {
    request: {
      url: `comment/${commentId}/report`,
      method: 'post',
      authenticated: true,
    },
  },
});

/**
 * @param {string|number} riffId
 * @param {string} comment
 */
export const addComment = (riffId, comment) => ({
  type: ADD_COMMENT,
  payload: {
    request: {
      url: `riff/${riffId}/comments`,
      method: 'post',
      authenticated: true,
      data: {
        comment,
      },
    },
    params: {
      riffId
    }
  },
});

/**
 * @param {string|number} commentId
 */
export const deleteComment = (commentId, riffId) => ({
  type: DELETE_COMMENT,
  payload: {
    request: {
      url: `comment/${commentId}`,
      method: 'delete',
      authenticated: true,
    },
    params: {
      commentId,
      riffId
    }
  },
});
