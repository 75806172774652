import React, { createRef, Fragment, useState } from 'react';
import { Row } from 'react-bootstrap';
import { dateDifference } from '../../utils/common';
import AudioPlayer from '../audioPlayer/audioPlayer';
import './searchResult.scss';
import waveform from '../../assets/icons/waveform.png';
import waveformActive from '../../assets/icons/waveformActive.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearSearchHistory, saveSearchHistory } from '../../store/actions/searchHistoryActions';
import constants from '../../utils/constants';

export default function SearchResult({
  title, icon, data, searchTxt, history, isHistory, location
}) {
  const [playingId, setPlayingId] = useState(0);
  const dispatch = useDispatch();
  const { userId } = useSelector(state => state.authentication);
  const getCommonTxt = (riff) => {
    const tags = `#${riff.tags.join(' #')}`;
    const searchArr = searchTxt.split(' ');
    let keyWord = searchTxt;
    let txtArr = [];
    if (riff.name) {
      for (const s of searchArr) {
        txtArr = riff.name.toLowerCase().split(s.toLowerCase());
        if (txtArr.length > 1) {
          keyWord = s;
          break;
        }
      }
    }
    if (txtArr.length < 2) {
      for (const s of searchArr) {
        txtArr = tags.toLowerCase().split(s.toLowerCase());
        if (txtArr.length > 1) {
          keyWord = s;
          break;
        }
      }
    }
    return { txtArr, keyWord };
  };
  const onClickRow = (res) => {
    const directTo = res.slug ? constants.screens.profile : constants.screens.home;
    !res.user && dispatch(saveSearchHistory(res, userId));
    !res.user && history.push(`${directTo}/${res.slug ? res.id : res.name}`, [{backTo: location.pathname, mainScreen: 'search'}]);
  };
  const onClickRowRiff = (res) => {
    const directTo = constants.screens.home;
    dispatch(saveSearchHistory(res, userId));
    history.push(`${directTo}/${res.id}`, [{backTo: location.pathname, mainScreen: 'search'}]);
  };
  return (
    <Row className="no-gutters">
      <div className="d-flex mt-4 mb-2 flex-row justify-content-between align-items-center w-100">
        <p className="resultTitle">{title}</p>
        {isHistory && <p onClick={() => dispatch(clearSearchHistory(userId))} className="clickable clearAll">Clear all</p>}
      </div>

      <div className="resultContainer w-100 p-0">
        {
                        data.map((res, i) => (
                          <div onClick={() => onClickRow(res)} className={`resultRow ${res.user ? '' : 'clickable'} d-flex justify-content-start align-items-center p-3`} key={i} style={i === data.length - 1 ? { borderWidth: 0 } : {}}>
                            {res.user ? (
                              <div className="d-flex flex-column">
                                <div className="d-flex flex-column flex-sm-row">
                                  <div onClick={() => onClickRowRiff(res)} className="clickable d-flex justify-content-between align-items-start flex-column w-100 w-sm-50 w-md-50 w-lg-50 order-2 order-sm-1 order-md-1 order-lg-1">
                                    <div className="font-weight-normal mb-2">
                                      {
                                                  getCommonTxt(res).txtArr.map((txt, i) => (
                                                    <Fragment key={i}>
                                                      <span>{txt}</span>
                                                      {i < getCommonTxt(res).txtArr.length - 1 && <span className="highlighted">{getCommonTxt(res).keyWord}</span>}
                                                    </Fragment>
                                                  ))
  }
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-end flex-column w-100 w-sm-50 w-md-50 w-lg-50 order-1 order-sm-2 order-md-2 order-lg-2">
                                    <AudioPlayer
                                      className="mb-3"
                                      file={res.file}
                                      length={res.length}
                                      waveform={waveform}
                                      waveformActive={waveformActive}
                                      audioRef={createRef()}
                                      isPlaying={`${res.id}-${i.toString()}` === playingId}
                                      onPlay={setPlayingId}
                                      id={res.id}
                                      index={i}
                                    />
                                  </div>
                                </div>
                                <div className="d-flex flex-row align-items-center clickable" onClick={() => onClickRowRiff(res)}>
                                    {icon && <img className="mr-2 searchIconImg" src={icon} alt="icon" />}
                                    {res.user && res.user.name}
                                    {res.user && (
                                    <div className="ml-2 greyText">
                                      @
                                      {res.user.slug}
                                    </div>
                                    )}
                                  </div>
                                <div className="d-flex flex-row justify-content-between align-items-center clickable" onClick={() => onClickRowRiff(res)}>
                                  <div className="font-weight-normal mt-2 d-none d-sm-block d-md-block d-lg-block">{dateDifference(res.dateCreated)}</div>
                                  <div className="d-none d-sm-block d-md-block d-lg-block">See riff</div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {icon && !(res.priority || res.priority === 0 ? '#' : '') && <img className="mr-2 searchIconImg" src={icon} alt="icon" />}
                                <div className={`${res.sortPriority === '2' ? 'markedBlue' : ''}`}>{(res.priority || res.priority === 0 ? '#' : '') + (res.name ? res.name : `${res.first_name} ${res.last_name}`) }</div>
                                {res.slug && (
                                <div className="ml-2 greyText">
                                  @
                                  {res.slug}
                                </div>
                                )}
                              </>
                            )}
                          </div>
                        ))
                    }
      </div>
    </Row>
  );
}
