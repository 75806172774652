import React from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import chevronLeft from '../../assets/icons/chevronLeft.png';
import './pageLeftContent.scss';

const PageLeftContent = ({ title, navigateToRef, onClick, showMd }) => (
  <div className="page-left-content clickable" onClick={onClick}>
    <a className="float-lg-right back-container" href={navigateToRef}>
      <Row className={`no-gutters ${showMd? 'pt-1': ''}`}>
        <span className={`offset-5 ${showMd? 'offset-md-0': ''} offset-lg-0`}>
          <img src={chevronLeft} alt="chevronLeft" className="mr-2" height="18" />
        </span>
        <span className={`app-section-title black-text d-none ${showMd? 'd-md-block': ''} d-lg-block mr-5`}>{title}</span>
      </Row>
    </a>
  </div>
);

PageLeftContent.propTypes = {
  title: PropTypes.string.isRequired,
  navigateToRef: PropTypes.string,
};

export default PageLeftContent;
