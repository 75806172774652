import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import OTP from '../formInputs/otp/otp';

const initialValues = {
  otp: '',
};

const SignUpOTPForm = ({ onSubmit, formReference }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    otp: Yup.string()
      .required(t('validation.fieldIsRequired'))
      .test('match', t('validation.fieldIsRequired'), (val) => val && val.length === 6),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          setFieldValue,
          touched,
          errors,
        }) => (
          <OTP
            name="otp"
            id="otp"
            handleChange={(val) => setFieldValue('otp', val)}
            value={values.otp}
            touched={touched && touched.otp}
            error={errors && errors.otp}
          />
        )
      }
    </Formik>
  );
};

SignUpOTPForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default SignUpOTPForm;
