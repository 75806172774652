import { CLEAR_SEARCH_HISTORY, SAVE_SEARCH_HISTORY } from '../constants/searchConstants';

const initialState = {
  searchHistory: {},
};

const reducer = (state = initialState, action) => {
  const { searchHistory } = state;
  switch (action.type) {
    case SAVE_SEARCH_HISTORY:
      if(searchHistory && searchHistory[action.payload.userId])
        for(let i = 0; i < searchHistory[action.payload.userId].length; i++) {
          if (searchHistory[action.payload.userId][i].id === action.payload.data.id) {
            searchHistory[action.payload.userId].splice(i, 1);
            break;
          }
        };
      if(!searchHistory[action.payload.userId]) searchHistory[action.payload.userId] = [];
      searchHistory[action.payload.userId] = [action.payload.data, ...searchHistory[action.payload.userId]];
      return {
        ...state,
        ...searchHistory,
      };
    case CLEAR_SEARCH_HISTORY:
      searchHistory[action.payload.userId] = [];
      return {
        ...state,
        ...searchHistory
      };
    default:
      return state;
  }
};

export default reducer;
