import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar'
import Page from '../../components/pageContent/pageContent'
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent'
import PageTitle from '../../components/pageTitle/pageTitle'
import chevronRight from '../../assets/icons/chevronRight@3x.png';
// import logoFacebook from '../../assets/icons/logoFacebook@3x.png';
// import logoInstagram from '../../assets/icons/logoInstagram@3x.png';
// import logoTiktok from '../../assets/icons/logoTiktok@3x.png';
// import logoTwitter from '../../assets/icons/logoTwitter@3x.png';
import constants from '../../utils/constants'
import './settings.scss'
// import { useDispatch } from 'react-redux'
// import { getUserSettings } from '../../store/actions/userActions'

export default function Settings({history}) {
    const { t } = useTranslation();
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getUserSettings());
    // }, [dispatch]);

    const settingsArr = [
        {title: t('settings.blockedAndMuted'), goto: constants.screens.blockedMuted, isRed: false},
        {title: t('settings.changePassword'), goto: constants.screens.changePassword, isRed: false},
        {title: t('settings.getInTouch'), goto: constants.screens.getInTouch, isRed: false},
        {title: t('settings.suspendAccount'), goto: constants.screens.suspendAccount, isRed: true},
        {title: t('settings.terms'), goto: constants.screens.settingsTerms, isRed: false},
    ]

    // const socialNetworksArr = [
    //     {title: t('settings.twitter'), icon: logoTwitter},
    //     {title: t('settings.facebook'), icon: logoFacebook},
    //     {title: t('settings.instagram'), icon: logoInstagram},
    //     {title: t('settings.tiktok'), icon: logoTiktok},
    // ]
    return (
        <Page header={<AuthenticatedNavBar activeKey="profile" />} hideMargin>
            <Row className="no-gutters mt-4">
                <Col xs={1} sm={2} md={3}>
                    <PageLeftContent title={t('globals.backToProfile')} navigateToRef={constants.screens.profile} />
                </Col>
                <Col xs={10} sm={8} md={6} lg={5} >
                    <PageTitle>{t('settings.settings')}</PageTitle>
                    <div className="w-100 settingsContainer">
                        {
                            settingsArr.map((res, i) => (
                                <div onClick={() => history.push(res.goto)} key={i} className={`settingsContainer-row clickable d-flex flex-row justify-content-between align-items-center ${res.isRed? 'settingsContainer-row-red': ''} ${i === 0? 'settingsContainer-row-first': ''} ${i === settingsArr.length-1? 'settingsContainer-row-last': ''}`}>
                                    <h5 className="bold m-0 d-none d-sm-block"> {res.title} </h5>
                                    <h6 className="bold m-0 d-block d-sm-none"> {res.title} </h6>
                                    <img src={chevronRight} alt=">" width="12" height="20" />
                                </div>
                            ))
                        }
                    </div>
                    {/* <div className="mt-4 px-4 settings-social-networks-txt">
                        <h5 className="m-0 bold">{t('settings.connectedSocialNetworks')}</h5>
                        <h5 className="m-0 mt-2">{t('settings.connectedSocialNetworksMsg')}</h5>
                    </div>
                    <div className="w-100 settingsContainer mb-5">
                        {
                            socialNetworksArr.map((res, i) => (
                                <div key={i} className={`settingsContainer-row bg-transparent d-flex flex-row justify-content-between align-items-center ${res.isRed? 'settingsContainer-row-red': ''} ${i === 0? 'settingsContainer-row-first': ''} ${i === socialNetworksArr.length-1? 'settingsContainer-row-last': ''}`}>
                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                        <img src={res.icon} alt=">" width="28" height="28" className="mr-3" />
                                        <h5 className="bold m-0 pt-1 d-none d-sm-block"> {res.title} </h5>
                                        <h6 className="bold m-0 pt-1 d-block d-sm-none"> {res.title} </h6>
                                    </div>
                                    <h5 className="bold m-0 pt-1 d-none d-sm-block clickable settingsContainer-row-connectTxt"> {t('settings.connect')} </h5>
                                    <h6 className="bold m-0 pt-1 d-block d-sm-none clickable settingsContainer-row-connectTxt"> {t('settings.connect')} </h6>
                                </div>
                            ))
                        }
                    </div> */}
                </Col>
            </Row>
        </Page>
    )
}
