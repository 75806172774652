import React, { useEffect, useState } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import logo from '../../assets/icons/riffrLogoBlack.png';
import downloadOnAppStore from '../../assets/images/downloadOnAppStore.png';
import downloadOnAppStoreMedium from '../../assets/images/downloadOnAppStore@2x.png';
import downloadOnAppStoreLarge from '../../assets/images/downloadOnAppStore@3x.png';
import getItOnGooglePlay from '../../assets/images/getItOnGooglePlay.png';
import getItOnGooglePlayMedium from '../../assets/images/getItOnGooglePlay@2x.png';
import getItOnGooglePlayLarge from '../../assets/images/getItOnGooglePlay@3x.png';
import constants from '../../utils/constants';
import { getSharedRiff } from '../../store/actions/sharedRiffActions';
import './sharedRiff.scss';
import { toUpperCaseFirstLetter } from '../../utils/common';
import Riff from '../../components/riff/riff';

export default function SharedRiff() {
    const { t } = useTranslation();
    const [audioPlaying, setAudioPlaying] = useState(0)
    const dispatch = useDispatch();
    const { id } = useParams();
    const sharedRiff = useSelector(state => state.sharedRiff);
    const { riff, error } = sharedRiff;

    const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

    useEffect(() => {
        if(id) {
            dispatch(getSharedRiff(id));
        }
    }, [dispatch, id]);
    
    return (
        <div className="sharedRiff">
            <Row className="no-gutters sharedRiffHeader">
                <a className="nav-link clickable" href="/">
                    <img src={logo} alt="Logo" className="logo" />
                    <span className="downloadApp learnMoreTitle bold d-none d-sm-inline">
                        {t('sharedRiff.learnMoreAboutRiffr')}
                    </span>
                </a>
            </Row>
            <Row className="no-gutters">
                <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-3 offset-xl-4 text-center">
                    <img src={logo} alt="Logo" className="logoHeader" />
                    {
                        sharedRiff.loading && loading
                    }
                    {
                        riff && (
                            <>
                                {riff.user && <h1 className="bold mt-5 mb-5"> {toUpperCaseFirstLetter(riff.user.name)} </h1>}
                                <Riff
                                    name={ riff.user && toUpperCaseFirstLetter(riff.user.name)}
                                    description={riff.name}
                                    commentCount={riff.commentCount}
                                    playCount={riff.playCount}
                                    echoCount={riff.echoCount}
                                    likes={riff.likes}
                                    length={riff.length}
                                    file={riff.file}
                                    key={`${riff.id}-1`}
                                    classNames="text-left"
                                    readOnly
                                    hideActions
                                    liked={riff.actions?.liked}
                                    isPlayed={riff.actions?.played}
                                    commented={riff.actions?.commented}
                                    echoed={riff.actions?.echoed}
                                    data={riff}
                                    onPlay={(playId) => {
                                    setAudioPlaying(playId);
                                    }}
                                    isPlaying={`${riff.id}-1` === audioPlaying}
                                    tags={riff.tags}
                                    hide={riff.hide}
                                    date={riff.dateCreated}
                                    index={1}
                                />
                            </>
                        )
                    }
                    {
                        error && <h1 className="bold mt-5">{error}</h1>
                    }
                    <h4 className="bold mt-5">{t('sharedRiff.joinRiffCommunity')}</h4>
                </Col>
            </Row>
            <Row className="no-gutters">
                <Col xs={10} md={8} lg={6} xl={6} className="offset-1 offset-md-2 offset-lg-3 offset-xl-3 text-center">
                    <h5 className="mt-3 descriptionTxt"> {t('sharedRiff.downloadRiffApp')} </h5>
                    <div className="d-flex justify-content-center align-items-center mt-4">
                        <Row>
                            <Col className="flex-sm-grow-1 flex-md-grow-0">
                                <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={downloadOnAppStoreLarge}
                                        srcSet={`${downloadOnAppStore} 300w, ${downloadOnAppStoreMedium} 768w, ${downloadOnAppStoreLarge} 1280w`}
                                        alt="appStore"
                                        className="downloadOnAppStore"
                                    />
                                </a>
                            </Col>
                            <Col className="no-padding flex-sm-grow-1 flex-md-grow-0">
                                <a href={constants.riffrAndroidStore} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={getItOnGooglePlay}
                                        srcSet={`${getItOnGooglePlay} 300w, ${getItOnGooglePlayMedium} 768w, ${getItOnGooglePlayLarge} 1280w`}
                                        alt="googleStore"
                                        className="getItOnGooglePlay"
                                    />
                                </a>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
