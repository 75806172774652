import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import TextArea from '../formInputs/textArea/textArea';

const initialValues = {
  bio: '',
};

const SignUpBioForm = ({ onSubmit, formReference }) => {
  const validationSchema = Yup.object({
    bio: Yup.string(),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <TextArea
            name="bio"
            id="bio"
            placeholder=""
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            maxLength="250"
          />
        )
      }

    </Formik>
  );
};

SignUpBioForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default SignUpBioForm;
