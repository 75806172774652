import React from 'react'
import constants from '../../utils/constants'

export default function Apps({history}) {
        if( /Android/i.test(navigator.userAgent) ) {
            window.location.href = constants.riffrAndroidStore;        
        } else if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
            window.location.href = constants.riffrAppStore;
        } else {
            history.push('/');
        }
    return (
        <div>
            redirecting...
        </div>
    )
}
