import { combineReducers } from 'redux';
import followingReducer from './followingReducer';
import followersReducer from './followersReducer';
import followReducer from './followReducer';

export default combineReducers({
  following: followingReducer,
  followers: followersReducer,
  follow: followReducer,
});
