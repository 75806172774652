import React from 'react';

/* eslint-disable */
const TermsOfUse = () => (
  <>
    <p className="p2">
      Riffr (&ldquo;Riffr,&rdquo; &ldquo;we,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;) provides its
      services (described below) to you through its website located at {' '}
      <a href="http://www.riffr.com">
        <span
          className="s1"
        >
          www.riffr.com
        </span>
      </a>
      {' '}
      (the &ldquo;Site&rdquo;) and through its mobile application and software
      platform (collectively, such services, including any new features and applications, and the Site, are referred to
      as the &ldquo;Service(s)&rdquo;).
    </p>
    <p className="p2">
      The following Terms of Service (&ldquo;Terms&rdquo;) govern your use of our Services.
      <span
        className="Apple-converted-space"
      >
&nbsp;
      </span>
      By using or accessing the Services, you agree to and accept these
      Terms. We reserve the right, at our sole discretion, to change or modify portions of these Terms at any time. If
      we do this, we will post the changes on this page and will indicate at the top of this page the date these terms
      were last revised. We will also notify you, either on the Site, through the Services or in an email notification
      or through other reasonable means. Any such changes will become effective immediately after posting and your
      continued use of the Services after the date any such changes become effective constitutes your acceptance of the
      new Terms.
    </p>
    <p className="p2">
      By using the Services, you agree to the Terms. If you do not agree to these Terms, you should not
      use the Services.&nbsp;
    </p>
    <p className="p2">
      In addition, your use of certain functions or features of the service may be subject to additional
      terms, including, without limitation, the Privacy Policy. All such terms are hereby incorporated by reference into
      these Terms.&nbsp;
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Description of Riffr&rsquo;s Site and Services </strong></li>
    </ol>
    <p className="p2">
      Riffr is a social network powered by sound. Through the Riffr mobile app, users speak their mind,
      listen to their heroes, educate others and become informed through the power of audio.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Who Can Use the Services</strong></li>
    </ol>
    <p className="p2">You must be 18 or older in order create an account or use the Services.</p>
    <p className="p2">By using the Services, you represent that:</p>
    <ul className="ul1">
      <li className="li2">You are a U.S. resident over the age of 18;</li>
      <li className="li2">You can form a binding contract with Riffr; and</li>
      <li className="li2">
        You will comply with these Terms and all applicable local, state, national, and international
        laws, rules, and regulations.
      </li>
    </ul>
    <ol className="ol1">
      <li className="li2"><strong>Rights We Grant You</strong></li>
    </ol>
    <p className="p2">
      Riffr grants you a personal, worldwide, royalty-free, non-assignable, nonexclusive, revocable, and
      non-sublicensable license to access and use the Services. This license is for the sole purpose of letting you use
      and enjoy the Services&rsquo; benefits in a way that these Terms allow. Any software that we provide you may
      automatically download and install upgrades, updates, or other new features. You may be able to adjust these
      automatic downloads through your device&rsquo;s settings.
    </p>
    <p className="p2">
      You may not copy, modify, distribute, sell, lease, reverse engineer or attempt to extract the
      source code for any part of our Services, unless such restriction is prohibited by law or you have our written
      permission to do so.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Rights You Grant Us to Your Content</strong></li>
    </ol>
    <p className="p2">
      The Riffr Service lets you create, upload, post, send, receive, and store content. You retain
      whatever ownership rights in that content you had to begin with but you grant Riffr a license to use that content.
      For all content you submit to the Services, you grant Riffr and our affiliates a worldwide, royalty-free,
      sublicensable, and transferable license to host, store, use, display, reproduce, modify, adapt, edit, publish, and
      distribute that content. This license is for the limited purpose of operating, developing, providing, promoting,
      and improving the Services and researching and developing new ones.
    </p>
    <p className="p2">
      To the extent applicable, when your name, likeness, or voice appears in content you submit to the
      Services you also grant Riffr, our affiliates, and our business partners the unrestricted, worldwide, perpetual
      right and license to use your name, likeness, and voice, including in connection with commercial or sponsored
      content. This means, among other things, that you will not be entitled to any compensation from Riffr or our
      affiliates if your name, likeness, or voice is conveyed through the Services.
    </p>
    <p className="p2">
      We may&ndash; but are not required to&ndash; access, review, screen, and delete your content at
      any time and for any reason, including to provide and develop the Services or if we think your content violates
      these Terms. You alone, though, remain responsible for the content you create, upload, post, send, or store
      through the Services.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Content of Others</strong></li>
    </ol>
    <p className="p2">
      Much of the content on our Services is produced by users, publishers, and other third parties. The
      content is the sole responsibility of the party that submitted it. Although Riffr reserves the right to review or
      remove all content that appears on the Services, we do not necessarily review all of it. So we cannot&mdash;and do
      not&mdash;take responsibility for any content that others provide through the Services.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Riffr Community Guidelines</strong></li>
    </ol>
    <p className="p2">You may not use the Services, or enable anyone else to use the Services, in a manner that:</p>
    <ul className="ul1">
      <li className="li2">violate these Terms or any applicable law, rules or regulations;</li>
      <li className="li2">
        impersonate any person or entity or falsely state or otherwise misrepresent your affiliation
        with a person or entity;
      </li>
      <li className="li2">record someone without their knowledge or consent;</li>
      <li className="li2">
        upload, post, email or otherwise transmit content that is unlawful, harmful, defamatory,
        inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening,
        <span
          className="Apple-converted-space"
        >
&nbsp;
        </span>
        harassing, tortuous, vulgar, libelous, invasive of another`s
        privacy, hateful, or racially, ethnically or otherwise objectionable;
      </li>
      <li className="li2">
        infringe, misappropriate or violate any third party&rsquo;s patent, trademark, trade secret,
        copyright or other proprietary rights of any party, including, those of Riffr;
      </li>
      <li className="li2">
        upload, post, email or otherwise transmit any content that you do not have a right to transmit
        under any law or contractual or fiduciary relationships;
      </li>
      <li className="li2">
        reproduce, duplicate, copy, modify, sell, re-sell or exploit any Services content for any
        commercial, educational, or any other non-personal purpose or any for any purpose unrelated to your personal
        purchasing decisions, without the express written consent of Riffr, which consent may be withheld by Riffr in
        our sole discretion;
      </li>
      <li className="li2">
        upload, post, email or otherwise transmit any unsolicited or unauthorized advertising,
        promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of
        solicitation or content for any purposes not authorized by these Terms;
      </li>
      <li className="li2">
        upload, post, email or otherwise transmit any material that contains software viruses, Trojan
        horses, worms, time bombs, spiders, cancelbots or any other computer code, files or programs designed to
        interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
        equipment;
      </li>
      <li className="li2">
        disrupt the normal flow of dialogue or otherwise act in a manner that negatively affects other
        users` ability to engage in real time exchanges;
      </li>
      <li className="li2">
        interfere with or disrupt the Services or servers or networks connected to the Services, or
        disobey any requirements, procedures, policies or regulations of networks connected to the Services.
      </li>
      <li className="li2">
        harass, threaten, intimidate, impersonate, or attempt to impersonate, any other person,
        falsify your contact or other information, misrepresent a relationship with any person or entity, including
        misrepresenting a relationship with Riffr, or otherwise attempt to mislead others as to the identity of the
        sender or the origin of a review or rating;
      </li>
      <li className="li2">knowingly provide or submit false or misleading information; or</li>
      <li className="li2">
        attempt to gain unauthorized access to the Site and Services, other user accounts, or other
        computer systems or networks connected to the Site and Services.
      </li>
    </ul>
    <ol className="ol1">
      <li className="li2"><strong>Respecting Copyright</strong></li>
    </ol>
    <p className="p2">
      We respect the intellectual property rights of others and expect our users to do the same. We may
      remove a user&rsquo;s content that in our sole discretion appears to infringe the copyrights of others. In
      addition, in our sole and absolute discretion, we may terminate the accounts of users who infringe the copyrights
      of others.
    </p>
    <p className="p2">
      If you believe that a user of the Services has infringed your copyrights, please notify our
      Copyright Agent, and provide the following information: (a) a physical or electronic signature of the person
      authorized to act on behalf of the owner of the copyright; (b) an identification of the copyright claimed to have
      been infringed; (c) a detailed description of the material that you claim is infringing, so that we may locate it,
      including the URL where the infringing material appears; (d) your address, telephone number, and email address;
      (e) a statement by you that you have a good faith belief that the allegedly infringing use is not authorized by
      the copyright&rsquo;s owner, its agent, or the law; and, (f) a statement by you, made under penalty of perjury,
      that the foregoing information is accurate and that you are authorized to act on behalf of the owner of the
      copyrights involved.
    </p>
    <p className="p2">Our Copyright Agent is and can be reached at:</p>
    <ol className="ol1">
      <li className="li3">Riffr</li>
    </ol>
    <p className="p4">Attn: Copyright Agent</p>
    <p className="p4">
      4 Faneuil Hall, 4
      <span className="s3"><sup>th</sup></span>
      {' '}
      Floor
    </p>
    <p className="p4">Boston, MA 02109</p>
    <p className="p5">
      <span className="s4">Email</span>
      <span className="s5">: </span>
      support@riffr.com
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Safety</strong></li>
    </ol>
    <p className="p2">
      While we try to keep our Services safe for all users, we cannot guarantee it. By using the
      Services, you agree that:
    </p>
    <ul className="ul1">
      <li className="li2">
        You will not use the Services for any purpose that is illegal or prohibited in these Terms.
      </li>
      <li className="li2">
        You will not use any robot, spider, crawler, scraper, or other automated means or interface to
        access the Services or extract other user&rsquo;s information.
      </li>
      <li className="li2">
        You will not use or develop any third-party applications that interact with the Services or
        other users&rsquo; content or information without our written consent.
      </li>
      <li className="li2">
        You will not use the Services in a way that could interfere with, disrupt, negatively affect,
        or inhibit other users from fully enjoying the Services, or that could damage, disable, overburden, or impair
        the functioning of the Services.
      </li>
      <li className="li2">
        You will not use or attempt to use another user&rsquo;s account, username, or password without
        their permission.
      </li>
      <li className="li2">You will not solicit login credentials from another user.</li>
      <li className="li2">
        You will not post content that contains or links to pornography, graphic violence, threats,
        hate speech, or incitements to violence.
      </li>
      <li className="li2">
        You will not upload viruses or other malicious code or otherwise compromise the security of
        the Services.
      </li>
      <li className="li2">
        You will not attempt to circumvent any content-filtering techniques we employ, or attempt to
        access areas or features of the Services that you are not authorized to access.
      </li>
      <li className="li2">
        You will not probe, scan, or test the vulnerability of our Services or any system or
        network.
      </li>
      <li className="li2">You will not encourage or promote any activity that violates these Terms.</li>
    </ul>
    <ol className="ol1">
      <li className="li2"><strong>Your Account</strong></li>
    </ol>
    <p className="p2">
      You are solely responsible for any activity that occurs in your Riffr account. You are responsible
      for maintaining the security and confidentiality of your username and password. If you think that someone has
      gained access to your account, please immediately contact us.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Data Charges and Mobile Phones</strong></li>
    </ol>
    <p className="p2">
      You are responsible for any mobile charges that you may incur for using our Services, including
      data charges. If you are unsure what those charges may be, you should ask your service provider before using the
      Services.&nbsp;
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Third-Party Sites and Linked Content</strong></li>
    </ol>
    <p className="p2">
      Our Services may include links to other sites on the Internet that are owned and operated by
      online merchants and other third parties. You acknowledge that Riffr is not responsible for the availability of,
      or the content located on or through, any third-party site. You should contact the site administrator or webmaster
      for those third-party sites if you have any concerns regarding the content located on such sites. Use of
      third-party sites is subject to the terms of use and privacy policies of each site, and we are not responsible
      therein. Riffr encourages you to review terms of use and privacy policies of third-parties sites.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Modifying the Services and Termination</strong></li>
    </ol>
    <p className="p2">
      We may add or remove features, products, or functionalities, and we may also suspend or stop the
      Services altogether. We may take any of these actions at any time, and when we do, we may not provide you with any
      notice beforehand. You may terminate these Terms at any time and for any reason by deleting your account. Riffr
      may also terminate these Terms with you at any time, for any reason, and without advanced notice. That means that
      we may stop providing you with any Services, or impose new or additional limits on your ability to use our
      Services. For example, we may deactivate your account due to prolonged inactivity, and we may reclaim your
      username at any time for any reason.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Indemnity</strong></li>
    </ol>
    <p className="p2">
      You agree, to the extent permitted by law, to indemnify, defend, and hold harmless Riffr, our
      affiliates, directors, officers, stockholders, employees, licensors, and agents from and against any and all
      complaints, charges, claims, damages, losses, costs, liabilities, and expenses (including attorneys&rsquo; fees)
      due to, arising out of, or relating in any way to: (a) your access to or use of the Services; (b) your content;
      and (c) your breach of these Terms.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Disclaimers</strong></li>
    </ol>
    <p className="p2">
      We try to keep the Services up and running and free of annoyances. But we make no promises that we
      will succeed.
    </p>
    <p className="p2">
      THE SERVICES ARE PROVIDED &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; AND TO THE EXTENT
      PERMITTED BY LAW WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
      WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IN ADDITION, WHILE
      RIFFR ATTEMPTS TO PROVIDE A GOOD USER EXPERIENCE, WE DO NOT REPRESENT OR WARRANT THAT: (A) THE SERVICES WILL
      ALWAYS BE SECURE, ERROR-FREE, OR TIMELY; (B) THE SERVICES WILL ALWAYS FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR
      IMPERFECTIONS; OR (C) THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR THROUGH THE SERVICES WILL BE
      TIMELY OR ACCURATE.
    </p>
    <p className="p2">
      RIFFR TAKES NO RESPONSIBILITY AND ASSUMES NO LIABILITY FOR ANY CONTENT THAT YOU, ANOTHER USER, OR
      A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES, OR STORES ON OR THROUGH OUR SERVICES. YOU UNDERSTAND AND
      AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE, ILLEGAL, MISLEADING, OR OTHERWISE INAPPROPRIATE,
      NONE OF WHICH RIFFR WILL BE RESPONSIBLE FOR.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Limitation of Liability</strong></li>
    </ol>
    <p className="p2">
      TO THE MAXIMUM EXTENT PERMITTED BY LAW, RIFFR AND OUR MANAGING MEMBERS, SHAREHOLDERS, EMPLOYEES,
      AFFILIATES, LICENSORS, AGENTS, AND SUPPLIERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
      CONSEQUENTIAL, PUNITIVE, OR MULTIPLE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR
      INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (A) YOUR ACCESS TO OR
      USE OF OR INABILITY TO ACCESS OR USE THE SERVICES; (B) THE CONDUCT OR CONTENT OF OTHER USERS OR THIRD PARTIES ON
      OR THROUGH THE SERVICES; OR (C) UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN IF RIFFR HAS BEEN
      ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL RIFFR&rsquo;S AGGREGATE LIABILITY FOR ALL CLAIMS
      RELATING TO THE SERVICES EXCEED THE GREATER OF $100 USD OR THE AMOUNT YOU PAID RIFFR, IF ANY, IN THE LAST 12
      MONTHS.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Governing Jurisdiction of the Laws of Massachusetts</strong></li>
    </ol>
    <p className="p2">
      Massachusetts law governs these Terms, as well as any claim, cause of action or dispute that might
      arise between you and Riffr, without regard to conflict of law or choice of law rules. We make no representation
      that our Services are appropriate, legal or available for use in locations outside of the United States. If you
      choose to access our Services you agree to do so subject to the laws of Massachusetts and the United States.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Severability</strong></li>
    </ol>
    <p className="p2">
      If any provision of these Terms is found unenforceable, then that provision will be severed from
      these Terms and not affect the validity and enforceability of any remaining provisions.
    </p>
    <ol className="ol1">
      <li className="li2"><strong>Miscellaneous Terms</strong></li>
    </ol>
    <p className="p2">
      These Terms (together with any additional terms applicable to specific Services you use) make up
      the entire agreement between you and Riffr, and supersede any prior agreements.
    </p>
    <p className="p2">These Terms do not create or confer any third-party beneficiary rights.</p>
    <p className="p2">If we do not enforce a provision in these Terms, it will not be considered a waiver.</p>
    <p className="p2">We reserve all rights not expressly granted to you.</p>
    <p className="p2">You may not transfer any of your rights or obligations under these Terms without our consent.</p>
    <ol className="ol1">
      <li className="li2"><strong>Contacting Us</strong></li>
    </ol>
    <p className="p2">If you have any questions regarding these terms or wish to contact us for any matter:</p>
    <p className="p4">Riffr</p>
    <p className="p4">
      4 Faneuil Hall, 4
      <span className="s3"><sup>th</sup></span>
      {' '}
      Floor
    </p>
    <p className="p4">Boston, MA 02109</p>
    <p className="p5">
      <span className="s4">Email</span>
      <span className="s5">: </span>
      support@riffr.com
    </p>
    <p className="p2">These Terms were last modified on November 9, 2018</p>
  </>
);
/* eslint-enable */

export default TermsOfUse;
