import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Footer from '../../components/footer/footer';
import logo from '../../assets/icons/riffrLogoWhite.png';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import constants from '../../utils/constants';
import SignUpInfoForm from '../../components/signUpForms/signUpInfoForm';
import SignUpPasswordForm from '../../components/signUpForms/signUpPasswordForm';
import SignUpBioForm from '../../components/signUpForms/signUpBioForm';
import SignUpVerificationForm from '../../components/signUpForms/signUpVerificationForm';
import './signUp.scss';
import SignUpOTPForm from '../../components/signUpForms/signUpOTPForm';
import ProgressBar from '../../components/progressBar/progressBar';
import {
  createUser, handleExists, resendVerification, verifyUser,
} from '../../services/user';
import { handleError } from '../../utils/http/http';
import { saveAuthentication } from '../../store/actions/authenticationActions';
import { setFirstLogin } from '../../store/actions/userActions';

const signUpSteps = {
  info: 'info',
  setPassword: 'setPassword',
  bio: 'bio',
  verification: 'verification',
  otp: 'otp',
};

const stepsIndexes = {
  [signUpSteps.info]: 1,
  [signUpSteps.setPassword]: 2,
  [signUpSteps.bio]: 3,
  [signUpSteps.verification]: 4,
  [signUpSteps.otp]: 4,
};

const SignUp = ({ history }) => {
  const { t } = useTranslation();
  const form = React.createRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [step, setStep] = useState(signUpSteps.info);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      setError(null);
      setFormValues({ ...formValues, ...data });
      setLoading(false);
      switch (step) {
        case signUpSteps.setPassword:
          setStep(signUpSteps.bio);
          break;
        case signUpSteps.bio:
          setStep(signUpSteps.verification);
          break;
        default:
          break;
      }
    } catch (e) {
      setError(t('globals.somethingWentWrong'));
      setLoading(false);
    }
  };

  const onInfoSubmit = async (data) => {
    try {
      setLoading(true);
      setError(null);
      await handleExists(data.handle);
      setFormValues({ ...formValues, ...data });
      setLoading(false);
      setStep(signUpSteps.setPassword);
    } catch (e) {
      setError(handleError(e, t));
      setLoading(false);
    }
  };

  const onUsernameSubmit = async (data) => {
    try {
      setLoading(true);
      setError(null);
      const updateValues = { ...formValues, ...data };
      setFormValues(updateValues);
      await createUser(updateValues);
      await resendVerification(updateValues.username);
      setLoading(false);
      setStep(signUpSteps.otp);
    } catch (e) {
      if (e && e.status === 403) {
        setError(null);
        setStep(signUpSteps.otp);
      } else {
        setError(handleError(e, t));
      }
      setLoading(false);
    }
  };

  const onResendVerification = async () => {
    try {
      setError(null);
      setLoading(true);
      await resendVerification(formValues.username);
      setInfo(t('authentication.verificationCodeSent'));
      setLoading(false);
    } catch (e) {
      setError(t('globals.somethingWentWrong'));
      setLoading(false);
    }
  };

  const onOTPSubmit = async (data) => {
    try {
      setLoading(true);
      setInfo(null);
      setError(null);
      const updatedFormValues = { ...formValues, pin: Number(data.otp) };
      setFormValues(updatedFormValues);
      const authenticationData = await verifyUser(updatedFormValues);
      dispatch(saveAuthentication(authenticationData));
      dispatch(setFirstLogin(true));
      setLoading(false);
      history.push(constants.screens.profile);
    } catch (e) {
      setError(handleError(e, t));
      setLoading(false);
    }
  };

  const renderInfo = () => (
    <>
      <h4 className="app-sub-header-2">{t('authentication.signUpToRiffr')}</h4>
      <Row className="no-gutters">
        <Col xs={10}>
          <h6 className="app-section-sub-header light">{t('authentication.signUpInfoStepHeader')}</h6>
        </Col>
      </Row>

      {error && (
        <Alert variant="danger">
          {error}
        </Alert>
      )}
      <ErrorBoundary>
        <SignUpInfoForm formReference={form} onSubmit={onInfoSubmit} />
      </ErrorBoundary>
      <Row className="no-gutters align-items-center submit-container">
        <a href={constants.screens.login} className="app-value">
          {t('authentication.alreadyHaveAnAccount')}
        </a>
        <div className="flex-fill">
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('globals.next')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </div>
      </Row>
    </>
  );

  const renderSetPassword = () => (
    <>
      <h4 className="app-sub-header-2">{t('authentication.signUpToRiffr')}</h4>
      <Row className="no-gutters">
        <Col xs={10}>
          <h6 className="app-section-sub-header light">{t('authentication.signUpSetPasswordHeader')}</h6>
        </Col>
      </Row>

      {error && (
        <Alert variant="danger">
          {t('globals.somethingWentWrong')}
        </Alert>
      )}
      <ErrorBoundary>
        <SignUpPasswordForm formReference={form} onSubmit={onSubmit} />
      </ErrorBoundary>
      <Row className="no-gutters align-items-center submit-container">
        <div className="flex-fill">
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('globals.next')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </div>
      </Row>
    </>
  );

  const renderBio = () => (
    <>
      <h4 className="app-sub-header-2">{t('authentication.signUpToRiffr')}</h4>
      <Row className="no-gutters">
        <Col xs={10}>
          <h6 className="app-section-sub-header light">{t('authentication.addShortBio')}</h6>
        </Col>
      </Row>

      {error && (
        <Alert variant="danger">
          {t('globals.somethingWentWrong')}
        </Alert>
      )}
      <ErrorBoundary>
        <SignUpBioForm formReference={form} onSubmit={onSubmit} />
      </ErrorBoundary>
      <Row className="no-gutters align-items-center submit-container">
        <div className="flex-fill">
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('globals.next')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </div>
      </Row>
    </>
  );

  const renderVerification = () => (
    <>
      <h4 className="app-sub-header-2">{t('authentication.signUpToRiffr')}</h4>
      <Row className="no-gutters">
        <Col xs={10}>
          <h6 className="app-section-sub-header light">{t('authentication.howWouldYouLikeToBeVerified')}</h6>
        </Col>
      </Row>

      {error && (
        <Alert variant="danger">
          {error}
        </Alert>
      )}
      <ErrorBoundary>
        <SignUpVerificationForm formReference={form} onSubmit={onUsernameSubmit} />
      </ErrorBoundary>
      <Row className="no-gutters align-items-center submit-container">
        <div className="flex-fill">
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('globals.submit')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </div>
      </Row>
    </>
  );

  const renderOTP = () => (
    <>
      <h4 className="app-sub-header-2">{t('authentication.signUpToRiffr')}</h4>
      <Row className="no-gutters">
        <Col xs={8}>
          <h6 className="app-body sub-header-title">{t('authentication.enter6digits')}</h6>
        </Col>
      </Row>

      {info && (
        <Alert variant="info">
          {info}
        </Alert>
      )}

      {error && (
        <Alert variant="danger">
          {t('globals.somethingWentWrong')}
        </Alert>
      )}
      <ErrorBoundary>
        <SignUpOTPForm formReference={form} onSubmit={onOTPSubmit} />
      </ErrorBoundary>
      <Row className="no-gutters align-items-center submit-container">
        <div className="resend-code app-body-light" aria-hidden="true" onClick={() => onResendVerification()}>
          {t('authentication.resendCode?')}
        </div>
        <div className="flex-fill">
          <Button
            variant="outline-primary"
            className="submit-button app-value float-right"
            disabled={loading}
            onClick={() => form.current.submitForm()}
          >
            {t('authentication.verify')}
            {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
          </Button>
        </div>
      </Row>
    </>
  );

  const renderStep = () => {
    switch (step) {
      case signUpSteps.info:
        return renderInfo();
      case signUpSteps.setPassword:
        return renderSetPassword();
      case signUpSteps.bio:
        return renderBio();
      case signUpSteps.verification:
        return renderVerification();
      case signUpSteps.otp:
        return renderOTP();
      default:
        return <></>;
    }
  };

  return (
    <div className="authentication-screen">
      <ul className="nav" id="homepage-nav">
        <li className="nav-item flex-fill">
          <a className="nav-link nav-link-logo" href={constants.screens.default}>
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </li>
        <Row className="no-gutters">
          <li className="nav-item">
            <a className="nav-link" href={constants.screens.login}>
              <Row className="no-gutters align-items-center">
                <span className="cancel">{t('globals.cancel')}</span>
              </Row>
            </a>
          </li>
        </Row>
      </ul>
      <div className="page-content">
        <Row className=" no-gutters">
          <Col xs={10} sm={5} lg={4} className="offset-1 offset-sm-3 offset-lg-4">
            <div className="progress-bar-wrapper">
              <ProgressBar steps={4} activeStep={stepsIndexes[step]} />
            </div>
            {renderStep()}
          </Col>
        </Row>
        <div className="authentication-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
};

export default SignUp;
