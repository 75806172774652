import React, { Suspense } from 'react';
import './App.scss';
import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from 'react-ga';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Homepage from './scenes/homepage/homepage';
import Login from './scenes/login/login';
import ForgotPassword from './scenes/forgotPassword/forgotPassword';
import constants from './utils/constants';
import ResetPassword from './scenes/resetPassword/resetPassword';
import SignUp from './scenes/signUp/signUp';
import Home from './scenes/home/home';
import { persistor, store } from './store/configureStore';
import Profile from './scenes/profile/profile';
import ProtectedRoute from './routes/protectedRoute';
import EditProfile from './scenes/editProfile/editProfile';
import Connections from './scenes/connections/connections';
import PolicyAndTerms from './scenes/policyAndTerms/policyAndTerms';
import AccountDeletionPage from './scenes/accountDeletion/accountDeletion';
import ContactUs from './scenes/contactUs/contactUs';
import Debates from './scenes/debates/debates';
import Record from './scenes/record/record';
import TagPost from './scenes/tagPost/tagPost';
import Drafts from './scenes/drafts/drafts';
import Search from './scenes/search/search';
import SharedRiff from './scenes/sharedRiff/sharedRiff';
import Apps from './scenes/apps/apps';
import OpenMic from './scenes/openMic/openMic';
import EchoComment from './scenes/echoComment/echoComment';
import Settings from './scenes/settings/settings';
import ChangePassword from './scenes/changePassword/changePassword';
import SuspendAccount from './scenes/suspendAccount/suspendAccount';
import BlockedMuted from './scenes/blockedMuted/blockedMuted';
import InviteFriends from './scenes/inviteFriends/inviteFriends';

const { screens, googleAnalyticsId } = constants;

ReactGA.initialize(googleAnalyticsId);

const App = () => (
  <Suspense fallback={<Row className="vh-100"><Spinner animation="border" className="m-auto" /></Row>}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route exact path={screens.default} component={Homepage} />
            <Route path={screens.login} component={Login} />
            <Route path={screens.forgotPassword} component={ForgotPassword} />
            <Route path={screens.resetPassword} component={ResetPassword} />
            <Route path={screens.signUp} component={SignUp} />
            <Route path={screens.privacyPolicy} component={PolicyAndTerms} />
            <Route path={screens.accountDeletion} component={AccountDeletionPage} />
            <Route path={screens.terms} component={PolicyAndTerms} />
            <Route path={screens.riff} component={SharedRiff} />
            <Route path={screens.contactUs} component={ContactUs} />
            <Route path={screens.debates} component={Debates} />
            <Route path={screens.apps} component={Apps} />
            <Route path={`${screens.profile}/:userId`} component={Profile} />
            <ProtectedRoute path={screens.homeTag} component={Home} />
            <ProtectedRoute path={screens.home} component={Home} />
            <ProtectedRoute path={`${screens.echoComment}/:riffId`} component={EchoComment} />
            <Route path={`${screens.profile}/:userId`} component={Profile} />
            <ProtectedRoute path={screens.profile} component={Profile} />
            <ProtectedRoute path={screens.inviteFriends} component={InviteFriends} />
            <ProtectedRoute path={screens.settings} component={Settings} />
            <ProtectedRoute path={screens.getInTouch} component={ContactUs} />
            <ProtectedRoute path={screens.settingsTerms} component={PolicyAndTerms} />
            <ProtectedRoute path={screens.changePassword} component={ChangePassword} />
            <ProtectedRoute path={screens.blockedMuted} component={BlockedMuted} />
            <ProtectedRoute path={screens.suspendAccount} component={SuspendAccount} />
            <ProtectedRoute path={screens.editProfile} component={EditProfile} />
            <ProtectedRoute path={`${screens.connections}/:userId`} component={Connections} />
            <ProtectedRoute path={screens.connections} component={Connections} />
            <ProtectedRoute path={screens.record} component={Record} />
            <ProtectedRoute path={`${screens.editRiff}/:riffId`} component={TagPost} />
            <ProtectedRoute path={`${screens.tagPost}/:draftId`} component={TagPost} />
            <ProtectedRoute path={screens.tagPost} component={TagPost} />
            <ProtectedRoute path={screens.drafts} component={Drafts} />
            <ProtectedRoute path={screens.search} component={Search} />
            <Route path={screens.openMic} component={OpenMic} />
            <Redirect to={screens.default} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </Suspense>
);

export default App;
