import React, { Fragment, useCallback, useMemo } from 'react'
import { mapMinMax } from '../../utils/common';
import constants from '../../utils/constants';

export default function AudioRect({amplitude, getAnimationTransition, startedRecording, withAnimation, bars, width, radius, margin, height }) {
  const svgRect = [];
  const createRect = useCallback(() => {
        const marginTop = 45;
        let h1; let h2; let h3; let h4; let h5; let h6; let h7; let h8;
        const w = width;
        const r = radius;
        const m = margin;
    
        for (let i = m; i <= m * bars * 8; i += m * 8) {
          h1 = mapMinMax(0, constants.maxWaveAmplitude, w, w, amplitude);
          h2 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 2.6, amplitude);
          h3 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 6, amplitude);
          h4 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 12.6, amplitude);
          h5 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 6, amplitude);
          h6 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 7.1, amplitude);
          h7 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 9.3, amplitude);
          h8 = mapMinMax(0, constants.maxWaveAmplitude, w, w * 3.8, amplitude);
          if (h1 < w) h1 = w;
          if (h2 < w) h2 = w;
          if (h3 < w) h3 = w;
          if (h4 < w) h4 = w;
          if (h5 < w) h5 = w;
          if (h6 < w) h6 = w;
          if (h7 < w) h7 = w;
          if (h8 < w) h8 = w;
          svgRect.push(
            <Fragment key={i}>
              <rect id="audioBars" x={i} y={marginTop} width={w} height={h1} rx={r} />
              <rect id="audioBars" x={i + (m)} y={marginTop - (h2 - w) / 2} width={w} height={h2} rx={r} />
              <rect id="audioBars" x={i + (m * 2)} y={marginTop - (h3 - w) / 2} width={w} height={h3} rx={r} />
              <rect id="audioBars" x={i + (m * 3)} y={marginTop - (h4 - w) / 2} width={w} height={h4} rx={r} />
              <rect id="audioBars" x={i + (m * 4)} y={marginTop - (h5 - w) / 2} width={w} height={h5} rx={r} />
              <rect id="audioBars" x={i + (m * 5)} y={marginTop - (h6 - w) / 2} width={w} height={h6} rx={r} />
              <rect id="audioBars" x={i + (m * 6)} y={marginTop - (h7 - w) / 2} width={w} height={h7} rx={r} />
              <rect id="audioBars" x={i + (m * 7)} y={marginTop - (h8 - w) / 2} width={w} height={h8} rx={r} />
            </Fragment>,
          );
        }
      }, [svgRect, amplitude, bars, margin, radius, width]);

      const path = useMemo(() => {
        createRect();
        return (
          svgRect.map((res) => res)
        );
      }, [svgRect, createRect]);
    
    return (
        <svg
            viewBox="0 0 500 100"
            style={{
              width: '0%',
              height,
              ...startedRecording && withAnimation ? getAnimationTransition(0.5) : null,
            }}
            className={`waveForm ${startedRecording || !withAnimation ? 'waveAmination' : ''}`}
          >
            {
                path
            }
          </svg>
    )
}
