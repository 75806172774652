import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import Riff from '../../components/riff/riff';
// import { getRiffs } from '../../store/actions/homepageActions';
import { echoRiff, hideRiff, hideUserRiffs, likeRiff, playRiff, reportRiff, setRiffIdName } from '../../store/actions/riffActions';
import { blockUser, muteUser } from '../../store/actions/usersActions';
import noRecords from '../../assets/icons/noRecords.png';
import constants from '../../utils/constants';
import './riffsList.scss';
import { deleteDraft } from '../../store/actions/recordActions';

export default function RiffsList({playAllMode, setPlayAllMode, tag, goTo, sort, playingStatus, setPlayingStatus, setShowSideRiff, getRiffs, history, readOnly}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [audioPlaying, setAudioPlaying] = useState(0);
    const [lastAudioPlaying, setLastAudioPlaying] = useState(0);
    const home = useSelector((state) => state.home);
    const { userId } = useSelector(state => state.authentication);
    const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

    useEffect(() => {
      if(playingStatus==='play') {
        setAudioPlaying(lastAudioPlaying);
        dispatch(setRiffIdName(lastAudioPlaying));
        setPlayingStatus('');
      } else if(playingStatus==='stop'){
        setAudioPlaying(0);
        dispatch(setRiffIdName(''));
        setPlayingStatus('');
        setPlayAllMode && setPlayAllMode(false);
      } else if(playingStatus==='close'){
        setAudioPlaying(0);
        dispatch(setRiffIdName(''));
        setLastAudioPlaying('');
        setPlayingStatus('');
        setPlayAllMode && setPlayAllMode(false);
      }
    },[playingStatus, lastAudioPlaying, dispatch, setPlayingStatus, setPlayAllMode]);

    useEffect(() => {
      if(!setShowSideRiff) return;
      const handleScroll = () => {
        if (document.getElementById(audioPlaying || lastAudioPlaying)) {
          const domRect = document.getElementById(audioPlaying || lastAudioPlaying).getBoundingClientRect();
          if (domRect.top < -100 || (window.innerHeight - domRect.top < 0)) {
            setShowSideRiff(true);
          } else {
            setShowSideRiff(false);
            setLastAudioPlaying(audioPlaying);
          }
        } else {
          setShowSideRiff(false);
        }
      }
      handleScroll();
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => window.removeEventListener("scroll", handleScroll);
    }, [audioPlaying, setShowSideRiff, lastAudioPlaying]);

    useEffect(() => {
        if(playAllMode && !audioPlaying) {
          setAudioPlaying(`${home.allIds[0]}-0`);
        }
      }, [playAllMode, home.allIds, audioPlaying]);
    
      useEffect(() => {
        if(!playAllMode){
          setAudioPlaying(0);
        }
      }, [playAllMode]);

    const onActionRiff = useCallback((item) => {
        const {
          id, userId, actions, triggeredAction, screen
        } = item;
        switch (triggeredAction) {
          case constants.riffActions.like:
            actions.liked = !actions.liked;
            dispatch(likeRiff(id, actions));
            break;
          case constants.riffActions.play:
            actions.played = true;
            dispatch(playRiff(id, actions));
            break;
          case constants.riffActions.echo:
            actions.echoed = true;
            dispatch(echoRiff(id));
            break;
          case constants.riffActions.delete:
            dispatch(deleteDraft(id)).then(() => dispatch(hideRiff(id)));
            break;
          case constants.riffActions.report:
            dispatch(reportRiff(id));
            dispatch(hideRiff(id));
            tag && !isNaN(tag) && history.push(constants.screens.search);
            break;
          case constants.riffActions.block:
            dispatch(blockUser(userId));
            dispatch(hideUserRiffs(userId));
            tag && !isNaN(tag) && history.push(constants.screens.search);
            break;
          case constants.riffActions.mute:
            dispatch(muteUser(userId));
            dispatch(hideUserRiffs(userId));
            tag && !isNaN(tag) && history.push(constants.screens.search);
            break;
          case constants.riffActions.goTo:
            goTo(screen);
            break;
          default:
            break;
        }
      }, [dispatch, goTo, tag, history]);
    
    const notAllHidden = (allIds) => {
        let result = false;
        for(const id of allIds){
          if(!home.byId[id].hide){
            result = true;
            break;
          }
        } 
        return result;
      }
    return (
        <>
            {home.loading && home.allIds.length === 0 && loading}
            {home.allIds && home.allIds.length !== 0 && notAllHidden(home.allIds)? (
            <InfiniteScroll
                className="feeds-scroll-container"
                dataLength={home.allIds.length}
                next={() => !home.loading && dispatch(getRiffs(home.result.currentPage + 1, sort, tag))}
                hasMore={home.hasMore}
                loader={loading}
            >
                {home.allIds.map((id, index) => {
                const riff = home.byId[id];
                    return (
                    <Riff
                        id={`${riff.id}-${index.toString()}`}
                        name={riff.user && riff.user.name}
                        description={riff.name}
                        commentCount={riff.commentCount}
                        playCount={riff.playCount}
                        echoCount={riff.echoCount}
                        likes={riff.likes}
                        length={riff.length}
                        file={riff.file}
                        key={`${riff.id}-${index.toString()}`}
                        index={index}
                        indexPlaying=""
                        classNames="feed-riff-item"
                        readOnly={readOnly}
                        liked={riff.actions?.liked}
                        isPlayed={riff.actions?.played}
                        commented={riff.actions?.commented}
                        echoed={riff.actions?.echoed}
                        data={riff}
                        onActionRiff={onActionRiff}
                        onPlay={(id) => {
                          setAudioPlaying(id);
                          if(!setShowSideRiff) return;
                          dispatch(setRiffIdName(""));
                          setTimeout(() => {
                            dispatch(setRiffIdName(id, riff.user && riff.user.name));
                          },100);
                          id? setLastAudioPlaying(id): setShowSideRiff(false);
                        }}
                        isPlaying={`${riff.id}-${index.toString()}` === audioPlaying}
                        tags={riff.tags}
                        hide={riff.hide}
                        next={home.allIds[index + 1]}
                        playAllMode={playAllMode}
                        setPlayAllMode={setPlayAllMode}
                        isMyRiff={riff.user && userId === riff.user.id}
                        comments={riff.comments}
                        canComment
                        echoObj={home.result && home.result.result && home.result.result[index] && home.result.result[index].echo}
                        commentObj={home.result && home.result.result && home.result.result[index] && home.result.result[index].comment}
                        likeObj={home.result && home.result.result && home.result.result[index] && home.result.result[index].like}
                        dateRiff={riff.dateCreated}
                    />
                    );
                })}
            </InfiniteScroll>
            ): !home.loading && (
                <div className="app-value text-center mt-5">
                <img src={noRecords} alt="noRecords" className="noRecords" height="70" />
                <div className="mt-2">{t('globals.noResultsSorry')}</div>
                </div>
            )}
        </>
    )
}

RiffsList.defaultProps = {
  readOnly: false
}