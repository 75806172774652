const GET_FOLLOWING = 'GET_FOLLOWING';
const GET_FOLLOWING_SUCCESS = 'GET_FOLLOWING_SUCCESS';
const GET_FOLLOWING_FAIL = 'GET_FOLLOWING_FAIL';
const GET_FOLLOWERS = 'GET_FOLLOWERS';
const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
const GET_FOLLOWERS_FAIL = 'GET_FOLLOWERS_FAIL';
const FOLLOW = 'FOLLOW';
const FOLLOW_SUCCESS = 'FOLLOW_SUCCESS';
const FOLLOW_FAIL = 'FOLLOW_FAIL';
const GET_USER = 'GET_USER';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAIL = 'GET_USER_FAIL';

export {
  GET_FOLLOWING,
  GET_FOLLOWING_SUCCESS,
  GET_FOLLOWING_FAIL,
  GET_FOLLOWERS,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  FOLLOW,
  FOLLOW_SUCCESS,
  FOLLOW_FAIL,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
};
