import { GET_SHARED_RIFF, GET_SHARED_RIFF_FAIL, GET_SHARED_RIFF_SUCCESS } from '../constants/sharedRiffConstants';

const initialState = {
  loading: false,
  riff: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SHARED_RIFF: {
      return {
        ...state,
        loading: true,
        riff: null,
        error: null,
      };
    }
    case GET_SHARED_RIFF_SUCCESS: {
        return {
          ...state,
          loading: false,
          riff: action.payload.data,
          error: null,
        };
      }
    case GET_SHARED_RIFF_FAIL: {
        return {
          ...state,
          loading: false,
          riff: null,
          error: action.error.response.data.error_description,
        };
      }
    default:
      return state;
  }
};

export default reducer;
