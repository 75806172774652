import constants from '../../utils/constants';
import {
  CLEAR_UPDATE_USER, GET_USER, GET_USER_BY_ID, GET_USER_RIFF, GET_USER_SETTINGS, SET_FIRST_LOGIN, SUSPEND_ACCOUNT, UPDATE_USER,
} from '../constants/userConstants';

const getUser = (userId) => ({
  type: userId? GET_USER_BY_ID: GET_USER,
  payload: {
    request: {
      url: `/user/${userId || ''}`,
      method: 'get',
      authenticated: true,
    },
  },
});
/**
 * @param {number} userId
 * @param {number} page
 * @param {string} sort
 */
const getUserRiff = (page, sort, userId) => ({
  type: GET_USER_RIFF,
  payload: {
    request: {
      url: !userId? `user/riff`: `riff/user/${userId}`,
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: constants.homepageRiffsLimit,
        page,
        sort,
      },
    },
  },
});

/**
 * @param {number} page
 * @param {string} sort
 */
const getUserLiked = (page, sort) => ({
  type: GET_USER_RIFF,
  payload: {
    request: {
      url: 'user/liked',
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: constants.homepageRiffsLimit,
        page,
        sort,
      },
    },
  },
});

/**
 * @param {number} page
 * @param {string} sort
 */
const getUserCommented = (page, sort) => ({
  type: GET_USER_RIFF,
  payload: {
    request: {
      url: 'user/commented',
      method: 'get',
      authenticated: true,
      params: {
        pageLimit: constants.homepageRiffsLimit,
        page,
        sort,
      },
    },
  },
});

const updateUser = (data) => ({
  type: UPDATE_USER,
  payload: {
    request: {
      url: '/user',
      method: 'put',
      authenticated: true,
      data,
    },
  },
});

const clearUpdateUser = () => ({
  type: CLEAR_UPDATE_USER,
  payload: null,
});

const setFirstLogin = (data) => ({
  type: SET_FIRST_LOGIN,
  payload: data,
});

const suspendAccount = () => ({
  type: SUSPEND_ACCOUNT,
  payload: {
    request: {
      url: '/user/suspend',
      method: 'post',
      authenticated: true,
    },
  },
})

const getUserSettings = () => ({
  type: GET_USER_SETTINGS,
  payload: {
    request: {
      url: '/user/settings',
      method: 'get',
      authenticated: true,
      params: {
        cache: 'disabled'
      }
    },
  },
})

export {
  getUser, setFirstLogin, updateUser, clearUpdateUser, getUserRiff, getUserLiked, getUserCommented, suspendAccount, getUserSettings
};
