import constants from '../../utils/constants';
import {
  ECHO_RIFF, PLAY_RIFF, LIKE_RIFF, REPORT_RIFF, HIDE_RIFF, HIDE_USER_RIFFS, LIKE_COMMENT, SET_RIFF_AUDIO_LENGTH, SET_RIFF_NAME_ID, SET_RIFF_AUDIO_REMAINING_TIME, SET_CAN_SET_REMAINING_TIME, SET_SIDE_RIFF_TIME,
} from '../constants/riffConstants';

/**
 * @param {string} id
 * @param {object} actions
 */
export const playRiff = (id, actions) => ({
  type: PLAY_RIFF,
  payload: {
    request: {
      url: `riff/${id}/play`,
      method: 'get',
      authenticated: true,
    },
    params: {
      id,
      actions,
    },
  },
});

/**
 * @param {string} id
 * @param {object} actions
 */
export const likeRiff = (id, actions) => ({
  type: LIKE_RIFF,
  payload: {
    request: {
      url: `riff/${id}/${actions.liked ? 'like' : 'unlike'}`,
      method: 'post',
      authenticated: true,
    },
    params: {
      id,
      actions,
    },
  },
});

/**
 * @param {string} id
 * @param {object} actions
 */
export const likeComment = (comment) => ({
  type: LIKE_COMMENT,
  payload: {
    request: {
      url: `comment/${comment.id}/${comment.actions.liked ? 'unlike' : 'like'}`,
      method: 'post',
      authenticated: true,
    },
    comment
  },
});

/**
 * @param {string} id
 * @param {object} data
 */
export const echoRiff = (id, data) => ({
  type: ECHO_RIFF,
  payload: {
    request: {
      url: `riff/${id}/echo`,
      method: 'post',
      authenticated: true,
      data: data? data: {}
    },
    params: {
      id,
    },
  },
});

/**
 * @param {string} id
 * @param {object} data
 */
export const echoRiffAudio = (id, data) => ({
  type: ECHO_RIFF,
  payload: {
    request: {
      url: `${constants.uploadUrl}/riff/${id}/riffEcho`,
      method: 'post',
      authenticated: true,
      data: data
    },
    params: {
      id,
    },
  },
});

/**
 * @param {string} id
 */
export const reportRiff = (id) => ({
  type: REPORT_RIFF,
  payload: {
    request: {
      url: `riff/${id}/report`,
      method: 'post',
      authenticated: true,
    },
    params: {
      id,
    },
  },
});

/**
 * @param {string} riffId
 */
export const hideRiff = (riffId) => ({
  type: HIDE_RIFF,
  payload: {
    riffId,
  },
});

/**
 * @param {string} userId
 */
export const hideUserRiffs = (userId) => ({
  type: HIDE_USER_RIFFS,
  payload: {
    userId,
  },
});

/**
 * @param {number} remainingTime
 */
export const setRiffAudioRemainingTime = (remainingTime) => ({
  type: SET_RIFF_AUDIO_REMAINING_TIME,
  payload: {
    remainingTime,
  },
});

/**
 * @param {number} length
 */
export const setRiffAudioLength = (length) => ({
  type: SET_RIFF_AUDIO_LENGTH,
  payload: {
    length,
  },
});

/**
 * @param {string} id
 * @param {string} name
 */
export const setRiffIdName = (id, name) => ({
  type: SET_RIFF_NAME_ID,
  payload: {
    id,
    name
  },
});

/**
 * @param {boolean} canSetRemainingTime
 */
export const setCanSetRemainingTime = (canSetRemainingTime) => ({
  type: SET_CAN_SET_REMAINING_TIME,
  payload: {
    canSetRemainingTime
  },
});

/**
 * @param {object} sideRiffTime
 */
export const setSideRiffTime = (sideRiffTime) => ({
  type: SET_SIDE_RIFF_TIME,
  payload: sideRiffTime
})