const GET_USER_BY_ID = 'GET_USER_BY_ID';
const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
const GET_USER_BY_ID_FAIL = 'GET_USER_BY_ID_FAIL';
const CLEAR_USER_BY_ID = 'CLEAR_USER_BY_ID';

export {
  GET_USER_BY_ID,
  GET_USER_BY_ID_SUCCESS,
  GET_USER_BY_ID_FAIL,
  CLEAR_USER_BY_ID,
};
