import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { toUpperCaseFirstLetter } from '../../utils/common';
import './trendingTags.scss';

export default function TrendingTags({ trendingHashTags, onClick, hasTitle }) {
  const [limit, setLimit] = useState(6);
  return (
    <Row className="no-gutters">
      {hasTitle && <p className="trendingTitle mt-2 mb-3">Trending Searches</p>}
      <div className="container">
        <div className="row">
          {trendingHashTags.map((tag, i) => {
            if (i < limit) {
              return (
                tag.name && <div onClick={() => onClick(tag)} className={`col clickable hashTagTrending d-flex flex-row justify-content-between align-items-center mr-2 mb-2 ${tag.sortPriority === '2' ? 'markedBlue' : ''}`} key={i}>
                  #
                  {toUpperCaseFirstLetter(tag.name)}
                </div>
              );
            } else return null;
          })}
          {
                            (limit < 10 && trendingHashTags.length) ? (
                              <div onClick={() => setLimit(10)} className="col clickable hashTagTrending d-flex flex-row justify-content-between align-items-center mr-3 mb-3 seeMore" key="seeMore">
                                See more
                              </div>
                            ) : null
                        }
        </div>
      </div>
    </Row>
  );
}
