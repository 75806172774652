import { GET_SHARED_RIFF } from "../constants/sharedRiffConstants";

/**
 * @param {string} id
 */
// eslint-disable-next-line import/prefer-default-export
export const getSharedRiff = (id) => ({
    type: GET_SHARED_RIFF,
    payload: {
      request: {
        url: `/riff/${id}`,
        method: 'get',
        authenticated: false,
      },
    },
  });
  