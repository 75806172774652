import constants from "../../utils/constants";
import { ADD_HOST_RIFF, GET_HOST_NAMES, GET_HOST_PAGE, GET_HOST_RIFFS, SET_IS_SUBMITTED } from "../constants/openMicConstants";

/**
 * @param {string} host
 * @param {object} data
 */
export const addRiffsByHost = (host, data) => ({
    type: ADD_HOST_RIFF,
    payload: {
      request: {
        url: `${constants.uploadUrl}/host/${host}/riff`,
        method: 'post',
        authenticated: false,
        data,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    },
  });

/**
 * @param {string} host
 * @param {number} page
 */
export const getRiffsByHost = (host, page) => ({
    type: GET_HOST_RIFFS,
    payload: {
      request: {
        url: `host/${host}/riff`,
        method: 'get',
        authenticated: false,
        params: {
          pageLimit: constants.homepageRiffsLimit,
          page,
        },
      },
    },
  });
/**
 * @param {boolean} isSubmitted
 */
  export const setIsSubmitted = (isSubmitted) => ({
    type: SET_IS_SUBMITTED,
    payload: isSubmitted,
  });

/**
 * @param {string} host
 */
export const getPageByHost = (host) => ({
    type: GET_HOST_PAGE,
    payload: {
      request: {
        url: `host/${host}`,
        method: 'get',
        authenticated: false,
      },
    },
  });

export const getHostNames = () => ({
    type: GET_HOST_NAMES,
    payload: {
      request: {
        url: `host`,
        method: 'get',
        authenticated: false,
      },
    },
  });