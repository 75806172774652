import axios from 'axios';
import constants from '../constants';

export const publicHttpClient = axios.create({
  baseURL: constants.apiUrl,
  headers: {
    'x-api-key': constants.apiKey,
  },
});

export const handleError = (e, translation) => {
  try {
    if (e.data) {
      return e.data ? e.data.error_description : translation('globals.somethingWentWrong');
    }
    return e.error_description ? e.error_description : translation('globals.somethingWentWrong');
  } catch {
    return translation('globals.somethingWentWrong');
  }
};
