import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextArea from '../formInputs/textArea/textArea';
import ErrorBoundary from '../errorBoundary/errorBoundary';
import TextInput from '../formInputs/textInput/textInput';

const initialValues = {
  name: '',
  email: '',
  message: '',
};

const ContactUsForm = ({ onSubmit, formReference }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    name: Yup.string().required(t('validation.fieldIsRequired')),
    email: Yup.string().email(t('validation.invalidEmail')).required(t('validation.fieldIsRequired')),
    message: Yup.string()
      .required(t('validation.fieldIsRequired')),
  });

  return (
    <ErrorBoundary>
      <Formik
        innerRef={formReference}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="name"
              id="name"
              placeholder={t('homepage.yourName')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="email"
              id="email"
              placeholder={t('homepage.yourEmail')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextArea
              name="message"
              id="message"
              label=" "
              placeholder={t('homepage.writeYourMessage')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              maxLength=""
            />
          </>
        )
      }
      </Formik>
    </ErrorBoundary>
  );
};

ContactUsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default ContactUsForm;
