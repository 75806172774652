const PLAY_RIFF = 'PLAY_RIFF';
const LIKE_RIFF = 'LIKE_RIFF';
const ECHO_RIFF = 'ECHO_RIFF';
const REPORT_RIFF = 'REPORT_RIFF';
const HIDE_RIFF = 'HIDE_RIFF';
const HIDE_USER_RIFFS = 'HIDE_USER_RIFFS';
const LIKE_COMMENT = 'LIKE_COMMENT';
const SET_RIFF_AUDIO_REMAINING_TIME = 'SET_RIFF_AUDIO_REMAINING_TIME';
const SET_RIFF_AUDIO_LENGTH = 'SET_RIFF_AUDIO_LENGTH';
const SET_RIFF_NAME_ID = 'SET_RIFF_NAME_ID';
const SET_CAN_SET_REMAINING_TIME = 'SET_CAN_SET_REMAINING_TIME';
const SET_SIDE_RIFF_TIME = 'SET_SIDE_RIFF_TIME';

export {
  PLAY_RIFF, 
  LIKE_RIFF, 
  ECHO_RIFF, 
  REPORT_RIFF, 
  HIDE_RIFF, 
  HIDE_USER_RIFFS, 
  LIKE_COMMENT, 
  SET_RIFF_AUDIO_REMAINING_TIME, 
  SET_RIFF_AUDIO_LENGTH, 
  SET_RIFF_NAME_ID,
  SET_CAN_SET_REMAINING_TIME,
  SET_SIDE_RIFF_TIME
};
