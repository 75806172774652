import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextInput from '../formInputs/textInput/textInput';

const initialValues = {
  firstName: '',
  lastName: '',
  handle: '',
};

const SignUpInfoForm = ({ onSubmit, formReference }) => {
  const { t } = useTranslation();
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(t('validation.fieldIsRequired')),
    lastName: Yup.string(),
      // .required(t('validation.fieldIsRequired')),
    handle: Yup.string()
      .required(t('validation.fieldIsRequired')),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="firstName"
              id="firstName"
              placeholder={t('globals.firstName')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="lastName"
              id="lastName"
              placeholder={t('globals.lastNameInitial')}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="handle"
              id="handle"
              placeholder={`@${t('globals.handle')}`}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
            />
          </>
        )
      }

    </Formik>
  );
};

SignUpInfoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default SignUpInfoForm;
