import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import ClickableText from '../../components/clickableText/clickableText';
import Page from '../../components/pageContent/pageContent';
import saveOutline from '../../assets/icons/saveOutline.png';
import arrowDownCircleOutline from '../../assets/icons/arrowDownCircleOutline.png';
import warningLargeBlack from '../../assets/icons/warningLargeBlack.png';
import sendLargeWhite from '../../assets/icons/sendLargeWhite@3x.png';
import saveLargeWhite from '../../assets/icons/saveLargeWhite@3x.png';
import './record.scss';
import Recorder from '../../components/recorder/recorder';
import constants from '../../utils/constants';
import {
  resetAudio, setAudioFile, setAudioLength, setIsDraft, setSuccessfullyPosted, setUploadMode,
} from '../../store/actions/recordActions';
import { toast, ToastContainer } from 'react-toastify';

export default function Record({ history }) {
  const [amplitude, setAmplitude] = useState(constants.idleWaveAmplitude);
  const [disabledPause, setDisabledPause] = useState(true);
  const [recordingStatus, setRecordingStatus] = useState('Start');
  const [startedRecording, setStartedRecording] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [formateError, setFormateError] = useState(null);
  const [buffer, setBuffer] = useState([]);
  const dispatch = useDispatch();
  const counterRef = useRef();
  const { t } = useTranslation();
  const recordState = useSelector((state) => state.record);
  const { micRecorder, successfullyPosted, isDraft } = recordState;

  useEffect(() => {
    if (successfullyPosted) {
      const toastContent = (
        <div>
          <Row className="no-gutters">
            <Col xs={2} className="align-self-center text-center">
              <img src={isDraft? saveLargeWhite: sendLargeWhite} alt="person" width="20" height="20" />
            </Col>
            <Col xs={10}>
              <div>{isDraft? t('record.riffSavedAsDraft'): t('record.riffPosted')}</div>
              <div className="app-body">{isDraft? t('record.resumeRecordingAnyTime'): t('record.itWillAppearInYourFeed')}</div>
            </Col>
          </Row>
        </div>
      );
      toast(toastContent, {
        position: 'top-right',
        hideProgressBar: true,
        draggable: true,
        className: 'welcome-toast m-2',
      });
      dispatch(setSuccessfullyPosted(false));
      dispatch(setIsDraft(false));
    }
  }, [successfullyPosted, t, dispatch, isDraft]);

  const saveDraft = () => (
    <ClickableText 
      text={t('record.drafts')}  
      icon={saveOutline} 
      iconWidth="20"
      iconHeight="20"
      onClick={() => history.push('drafts')} 
      />);
  const importAudio = () => (
    <ClickableText
      text={t('record.importAudio')}
      textClass="secondary-text"
      errorMsg={formateError}
      icon={arrowDownCircleOutline}
      iconWidth="22"
      iconHeight="22"
      onClick={() => {
        document.getElementById('uploadAudio').click();
      }}
    />
  );
  const cancelRecording = () => (
    <ClickableText
      text={t('record.cancelRecording')}
      textClass="error-text"
      icon={warningLargeBlack}
      iconWidth="20"
      iconHeight="20"
      onClick={() => {
        setRecordingStatus('Start');
        setDisabledPause(true);
        setTimeout(() => setAmplitude(constants.idleWaveAmplitude), 50);
        setStartedRecording(false);
        setIsBtnDisabled(false);
        counterRef.current.stop();
        counterRef.current.reset();
        micRecorder.stop();
        setBuffer([]);
        dispatch(resetAudio());
      }}
    />
  );
  const renderContent = () => (
    <div className="record__header">
      <h2 className="record__header-title">{t('record.title')}</h2>
      <p className="record__header-subtitle">
        {t('record.subTitle1')}
        <br />
        {t('record.subTitle2')}
      </p>
    </div>
  );
  const uploadAudio = () => {
    const res = document.getElementById('uploadAudio');
    const file = res.files[0];
    const type = file.type.split('/')[1];
    if (type === 'mpeg' || type === 'mp3') {
      const sizeKb = file.size/1000;
      if(sizeKb > 4500){
        setFormateError('File size is too large');
      } else if(sizeKb < 140){
        setFormateError('File size is too small');
      } else {
        setFormateError(null);
        const url = URL.createObjectURL(file);
        dispatch(setAudioFile(url, file, []));
        const player = new Audio(url);
        player.setAttribute('preload', 'metadata');
        player.onloadedmetadata = () => {
          dispatch(setAudioLength(Math.floor(player.duration)));
          dispatch(setUploadMode(true));
          history.push(constants.screens.tagPost);
        };
      };
    } else setFormateError('Invalid File Extension');
  };
  return (
    <Page header={<AuthenticatedNavBar activeKey="record" />} hideMargin>
      <ToastContainer autoClose={4000} />
      <input type="file" accept="audio/mp3" id="uploadAudio" hidden onChange={uploadAudio} />
      <Row className="no-gutters justify-content-end justify-content-md-end pr-2 pl-2 pt-2 pb-2 d-lg-none clickable-items">
        {recordingStatus === 'Start' ? (
          <>
            <div className="mr-2 align-self-center">
              {saveDraft()}
            </div>
            <div className="mr-2 align-self-center">
              {importAudio()}
            </div>
          </>
        ) : (
          <div className="mr-2 align-self-center">
            {cancelRecording()}
          </div>
        )}
      </Row>
      <Row className="profile-content no-gutters">
        <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-3 offset-xl-4">
          {renderContent()}
        </Col>
        <Col lg={3} xl={4} className="d-none d-sm-none d-md-none d-lg-block">
          {recordingStatus === 'Start' ? (
            <>
              <div className="mb-3">
                {saveDraft()}
              </div>
              <div className="mb-3">
                {importAudio()}
              </div>
            </>
          ) : (
            <div className="mb-3">
              {cancelRecording()}
            </div>
          )}
        </Col>
      </Row>
      <Row className="no-gutters">
        <Recorder
          recordingStatus={recordingStatus}
          setRecordingStatus={setRecordingStatus}
          buffer={buffer}
          setBuffer={setBuffer}
          counterRef={counterRef}
          isBtnDisabled={isBtnDisabled}
          setIsBtnDisabled={setIsBtnDisabled}
          disabledPause={disabledPause}
          setDisabledPause={setDisabledPause}
          startedRecording={startedRecording}
          setStartedRecording={setStartedRecording}
          amplitude={amplitude}
          setAmplitude={setAmplitude}
        />
      </Row>
      <Row className="no-gutters mt-5">
        {
                    recordingStatus === 'Resume' && (
                    <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-3 offset-xl-4 text-center">
                      <Button
                        onClick={() => {
                          dispatch(setUploadMode(false));
                          history.push(constants.screens.tagPost);
                        }}
                        className="btn btn-lg w-100 record-finishBtn mb-4"
                      >
                        {t('record.finishRecording')}
                      </Button>
                    </Col>
                    )
                }
        <Col xs={10} md={8} lg={6} xl={4} className="offset-1 offset-md-2 offset-lg-3 offset-xl-4">
          <div className="d-flex justify-content-center align-items-center">
            <div className="record__footer">
              <span>{t('record.downloadMsg1')}</span>
              <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer" className="record__footer-link">{t('record.ios')}</a>
              <span>{t('record.and')}</span>
              <a href={constants.riffrAndroidStore} target="_blank" rel="noopener noreferrer" className="record__footer-link">{t('record.android')}</a>
              <span>{t('record.downloadMsg2')}</span>
            </div>
          </div>
        </Col>
      </Row>
    </Page>
  );
}
