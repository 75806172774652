import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import constants from '../../utils/constants';
import './inviteFriends.scss';

export default function InviteFriends() {
    const { t } = useTranslation();
    const [copied, setCopied] = useState(false);

    const copyLink = () => {
        const textarea = document.createElement('textarea');
        textarea.textContent = 'https://riffr.com/';
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 99999);
        document.execCommand("copy");
        document.body.removeChild(textarea);
        setCopied(true);
    }

    return (
        <Page header={<AuthenticatedNavBar activeKey="profile" />} hideMargin>
            <Row className="no-gutters mt-4">
                <Col xs={1} sm={2} md={3}>
                    <PageLeftContent title={t(copied? 'globals.backToProfile': 'globals.cancel')} navigateToRef={constants.screens.profile} />
                </Col>
                <Col xs={10} sm={8} md={6} lg={3} >
                    <PageTitle>{t('profile.inviteFriends')}</PageTitle>
                    <h6 className="m-0 mt-3 inviteFriendsDef"> {t('profile.inviteFriendsMsg')} </h6>
                    <Button
                            variant="outline-primary" 
                            className="mt-4 copyInviteLinkBtn d-flex flex-row justify-content-center align-items-center" 
                            onClick={copyLink}
                            >
                        {t(copied? 'profile.inviteLinkCopied': 'profile.copyInviteLink')}
                    </Button>
                </Col>
            </Row>
        </Page>
    )
}
