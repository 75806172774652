import React from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';

const NavItem = ({
  imageSrc, text, screen, showUnderline, width, height,
}) => (
  <li className="nav-item">
    <a className="nav-link" href={screen}>
      <Row className="no-gutters align-items-center">
        <img src={imageSrc} alt="person" width={width} height={height} />
        <span className="d-none d-sm-block menu-item-text" style={{ textDecoration: showUnderline ? 'underline' : 'none' }}>{text}</span>
      </Row>
    </a>
  </li>
);

NavItem.propTypes = {
  text: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  screen: PropTypes.string.isRequired,
  showUnderline: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

NavItem.defaultProps = {
  showUnderline: false,
  width: 20,
  height: 20,
};

export default NavItem;
