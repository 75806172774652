import { CLEAR_AUTHENTICATION, SAVE_AUTHENTICATION } from '../constants/authenticationConstants';
import { GET_USER_SUCCESS } from '../constants/userConstants';

const initialState = {
  access_token: null,
  expires_in: null,
  token_type: null,
  scope: null,
  refresh_token: null,
  userId: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_AUTHENTICATION:
      return {
        ...state, ...action.payload,
      };
    case CLEAR_AUTHENTICATION:
      return initialState;
    case GET_USER_SUCCESS:
      return {
        ...state,
        userId: action.payload.data.id,
      };
    default:
      return state;
  }
};

export default reducer;
