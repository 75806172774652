import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import './riff.scss';
import MicRecorder from 'mic-recorder-to-mp3';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import comment from '../../assets/icons/comment.png';
import commentInactive from '../../assets/icons/commentInactive.png';
import like from '../../assets/icons/like.png';
import likeInactive from '../../assets/icons/likeInactive.png';
import echo from '../../assets/icons/echo.png';
import echoInactive from '../../assets/icons/echoInactive.png';
import play from '../../assets/icons/play.png';
import playInactive from '../../assets/icons/playInactive.png';
import waveform from '../../assets/icons/waveform.png';
import waveformActive from '../../assets/icons/waveformActive.png';
import recordOrange from '../../assets/icons/recordOrange@3x.png';
import sendSmall from '../../assets/icons/sendSmall@3x.png';
import shareOutline from '../../assets/icons/shareOutline@3x.png';
import echoOutlineGrey from '../../assets/icons/echoOutlineGrey@3x.png';
import favouriteSolidGrey from '../../assets/icons/favouriteSolidGrey@3x.png';
import { dateDifferenceWithOffset, formatDate, toUpperCaseFirstLetter } from '../../utils/common';
import constants from '../../utils/constants';
import Options from '../options/options';
import AudioPlayer from '../audioPlayer/audioPlayer';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getRiffComments, addRiffAudioComment } from '../../store/actions/homepageActions';
import { echoRiff, echoRiffAudio, likeComment } from '../../store/actions/riffActions';
import Timer from 'react-compound-timer/build';
import { addComment, deleteComment, reportComment } from '../../store/actions/commentsAction';
import ConfirmationDialog from '../confirmationDialog/confirmationDialog';
// import { deleteDraft } from '../../store/actions/recordActions';

const Riff = ({
  name,
  description,
  commentCount,
  playCount,
  echoCount,
  likes,
  length,
  file,
  onPlay,
  classNames,
  readOnly,
  hideActions,
  onActionRiff,
  liked,
  isPlayed,
  commented,
  echoed,
  data,
  isPlaying,
  tags,
  hide,
  date,
  isSideRiff,
  next,
  playAllMode,
  setPlayAllMode,
  isMyRiff,
  index,
  comments,
  isEchoComment,
  canComment,
  id,
  echoObj,
  commentObj,
  likeObj,
  dateRiff
}) => {
  const { t } = useTranslation();
  // const audioRef = React.createRef();
  // const audioRefEcho = React.createRef();
  // const commentAudioRef = React.createRef();
  const [micRecorder, setMicRecorder] = useState(new MicRecorder({ bitRate: 128 }));
  const [showComments, setShowComments] = useState(false);
  const [commentTxt, setCommentTxt] = useState('');
  const [audioBlob, setAudioBlob] = useState(null);
  const [commentLoading, setCommentLoading] = useState(false);
  const [commentOnPlay, setCommentOnPlay] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [triggeredAction, setTriggeredAction] = useState('');
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const { userId } = useSelector(state => state.authentication);
  const counterRef = useRef();
  const dispatch = useDispatch();
  const loading = <div className="text-center"><Spinner animation="border" className="spinner" /></div>;
  // console.log(echoObj)
  const riffOptions = [
    {
      text: t('riff.reportRiff'),
      click: () => [setShowConfirmationDialog(true), setTriggeredAction(constants.riffActions.report), setConfirmationDialogTitle(t('riff.reportRiff'))],
    },
    {
      text: `${t('globals.block')} ${name}`,
      click: () => [setShowConfirmationDialog(true), setTriggeredAction(constants.riffActions.block), setConfirmationDialogTitle(`${t('globals.block')} ${name}`)],
    },
    {
      text: `${t('globals.mute')} ${name}`,
      click: () => [setShowConfirmationDialog(true), setTriggeredAction(constants.riffActions.mute), setConfirmationDialogTitle(`${t('globals.mute')} ${name}`)],
    },
  ];
  const myRiffOptions = [
    {
      text: t('riff.deleteRiff'),
      click: () => [setShowConfirmationDialog(true), setTriggeredAction(constants.riffActions.delete), setConfirmationDialogTitle(`${(t('riff.deleteRiff')).toLowerCase()}`)],
    },
    {
      text: `${t('riff.editRiff')}`,
      click: () => onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.editRiff}/${data.id}` }),
    }
  ];

  const handleRecord = () => {
    if(!isRecording) {
      startRecording();
    }
  }

  const startRecording = () => {
    setAudioBlob(null);
    micRecorder.start().then(() => {
        setIsRecording(true);
        counterRef.current.start();
        setMicRecorder(micRecorder);
      }).catch((e) => {
        alert('This feature requires MICROPHONE PERMISSION');
      });
}

const stopRecording = () => {
    micRecorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
        // setIsRecording(false);
        counterRef.current.stop();
        buffer.splice(0, 36);
        const newBlob = new Blob(buffer, { type: blob.type });
        setAudioBlob(newBlob);
      }).catch((e) => {
        alert('We could not retrieve your message');
      });
  }

  const sendRecording = (isEcho) => {
    if(!audioBlob) {
      micRecorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
        setIsRecording(false);
        buffer.splice(0, 36);
        const newBlob = new Blob(buffer, { type: blob.type });
        if(isEcho) {
          handleEchoAudioComment(newBlob);
        } else {
          submitAudioComment(newBlob);
        }
      }).catch((e) => {
        alert('We could not retrieve your message');
      });
    } else {
      setIsRecording(false);
      if(isEcho) {
        handleEchoAudioComment(audioBlob);
      } else {
        submitAudioComment(audioBlob);
      }
    }
  }

  const submitAudioComment = (blob) => {
    setShowComments(true);
    const formData = new FormData();
          formData.append('file', blob, 'fileRiff.mp3');
          dispatch(addRiffAudioComment(formData, data.id)).then(() => {
            dispatch(getRiffComments(data.id, 1, 1));
          }
        );
  }

  const cancelRecording = () => {
    setAudioBlob(null);
    micRecorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
        setIsRecording(false);
      }).catch((e) => {
      });
  }

  const commentReportOption = (id) => ({
      text: t('riff.reportComment'),
      click: () => dispatch(reportComment(id, data.id)),
    });
  
  const riffCopyLinkOption = () => ([{
    text: t('riff.copyLink'),
    click: () => {
      const textarea = document.createElement('textarea');
      textarea.textContent = data.url;
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
  }]);

  const commentDeleteOption = (id) => ({
      text: t('riff.deleteComment'),
      click: () => dispatch(deleteComment(id, data.id)),
    });

  const echoOptions = (click) => ([{
      text: t('riff.echo'),
      click,
    }, 
    {
      text: t('riff.echoWithRiffOrComment'),
      click: () => onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.echoComment}/${data.id}` }),
    }
  ]);

  const getComments = (page) => {
    dispatch(getRiffComments(data.id, page));
  }

  const allCommentsHidden = useCallback(() => { 
    let allHidden = true;
    for(const comment of comments.result) {
      if(!comment.hide) allHidden = false;
    }
    return allHidden;
  }, [comments]);

  const handleOpenComments = () => {
      setShowComments(!showComments);
      if(!data.loadingComments && (!comments || (comments && allCommentsHidden()))) { 
        getComments(1);
    }
  }

  useEffect(() => {
    if(comments && allCommentsHidden()) {
      setShowComments(false);
    }
  }, [comments, allCommentsHidden]);

  useEffect(() => {
    if(!data.actions){
      data.actions = {
        commented: false,
        echoed: false,
        liked: false,
        played: false,
      }
    }
  },[data.actions]);

  const handleTxtComment = () => {
    setShowComments(true);
    dispatch(addComment(data.id, commentTxt))
      .then(() => {
        dispatch(getRiffComments(data.id, 1, 1));
      }
    );
    setCommentTxt('');
  }

  const handleEchoTxtComment = () => {
    setCommentLoading(true);
    dispatch(echoRiff(data.id, {comment: commentTxt})).then(() => {
      setCommentLoading(false);
      onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.home}` })
    });
  }

  const handleEchoAudioComment = (blob) => {
    setCommentLoading(true);
    const formData = new FormData();
    formData.append('file', blob, 'fileRiff.mp3');
    dispatch(echoRiffAudio(data.id, formData)).then(() => {
      setCommentLoading(false);
      onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.home}` })
    });
  }

  const handleLikeComment = (comment) => {
    dispatch(likeComment(comment));
  }

  useEffect(() => {
    if (isPlaying) {
      // if ((audioRef.current.paused && !audioRef.current.ended) || audioRef.current.ended) {
        if (!isPlayed && !readOnly) {
          onActionRiff({ ...data, triggeredAction: constants.riffActions.play });
        }
      }
    // } else if (!audioRef.current.paused) {
    //   audioRef.current.pause();
    // }
  }, [isPlaying, isPlayed]); // eslint-disable-line

  const renderComments = () => (
    <>
    { comments && comments.result && comments.result.length? <div className="w-100 commentInputContainer d-flex flex-column justify-content-between align-items-start">
      { comments.result.map((comment, i) => (
        <div key={i} className={`w-100 commentRow ${comment.hide? 'hide': ''} d-flex flex-column justify-content-between align-items-start pt-3 pr-4 pb-3 pl-4`}>
          { !comment.hide && <div className="w-100 d-flex flex-row justify-content-between align-items-center">
            <div className={`riffName ${comment.riff && comment.riff.file? 'w-100': 'w-50'} mr-2 mt-0`}><span className="app-value">{toUpperCaseFirstLetter(comment.user.name)}</span></div>
            {comment.riff && comment.riff.file? 
              <AudioPlayer
                file={comment.riff.file}
                waveform={waveform}
                waveformActive={waveformActive}
                audioRef={React.createRef()}
                onPlay={setCommentOnPlay}
                isPlaying={`${comment.riff.id}-${i}` === commentOnPlay}
                id={comment.riff.id}
                index={i}
                isComment
              /> : <div className="commentTxt"><span>{comment.comment}</span></div>
            }
          </div>
          }
          <div>
            <span className="app-body light">
              {dateDifferenceWithOffset(comment.dateCreated)}
            </span>
          </div>
          {!comment.hide && <div className="w-100 d-flex flex-row justify-content-between align-items-center mt-2">
            <div className="d-flex flex-row align-items-center" >
              {/* <img src={echoInactive} alt="echo" width="18" height="14" className="mr-4 clickable" /> */}
              <div
                className={`riff-footer-element mr-4 clickable`}
                aria-hidden="true"
                onClick={() => handleLikeComment(comment)}
              >
                <img src={comment.actions.liked ? like : likeInactive} alt="like" className={comment.actions.liked ? 'like' : 'like-inactive'} />
                <span className="count light">{comment.likes}</span>
              </div>
              <Options data={(comment.user.id === userId)? [commentDeleteOption(comment.id)]: isMyRiff? [commentReportOption(comment.id), commentDeleteOption(comment.id)]: [commentReportOption(comment.id)] } />
            </div>
            {/* <Options data={[commentReportOption(comment.id)]} icon={shareOutline} classNames="shareOutline" /> */}
          </div>}
        </div>
      ))
      }
    </div>: null
    }
    {
      data.loadingComments? 
        <div className={`py-2`}>
          {loading}
        </div>
      : null
    }
    {
      !data.loadingComments && comments && comments.currentPage < comments.totalPages ? 
        <div className="clickable py-2 px-4" onClick={() => getComments(comments.currentPage + (comments.maxResults/comments.totalPages === 1? 0: 1))}>
          <p className="bold m-0 p-0">See more replies</p>
        </div>
      : null
    }
    </>
  );
  
  const renderCommentInput = () => (
    <>
    {showComments && renderComments()}
    { commentLoading && 
      <div className="w-100 commentInputContainer d-flex flex-row justify-content-center align-items-center pt-3 pr-4 pb-3 pl-4">{loading}</div>
      }
    { !commentLoading && <div className="w-100 commentInputContainer d-flex flex-row justify-content-between align-items-center pt-3 pr-4 pb-3 pl-4">
      <img src={recordOrange} width="39" className="clickable pr-2" alt="record" onClick={handleRecord} />
      {!isRecording? 
        <div className="position-relative w-100">
          <input onChange={({target}) => target.value.length <= 50 && setCommentTxt(target.value)} value={commentTxt} style={{paddingRight: isEchoComment? '57px': '35px'}} className="commentInput w-100 py-1 pl-3" placeholder="record or type your reply…" />
          { !isEchoComment? 
            <Button onClick={handleTxtComment} disabled={!commentTxt.length} className="btn commentInputSend rounded-circle d-flex justify-content-center align-items-start">
              <img src={sendSmall} alt="send" width="25" height="25" />
            </Button> : 
            <Button onClick={handleEchoTxtComment} disabled={!commentTxt.length} className="btn commentInputSendTxt d-flex flex-column justify-content-center align-items-center">
              <div className="pt-1 bold">Echo</div> 
            </Button>
          }
        </div> : 
        <div className="w-100 d-flex flex-row justify-content-between align-items-center">
          <Timer
            ref={counterRef}
            startImmediately={false}
            checkpoints={[
                {
                  time: constants.commentMaxAudioLength * 1000,
                  callback: () => stopRecording(),
                }
            ]}
            >
            <Timer.Minutes />
            :
            <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
        </Timer>
        <span className="app-value clickable" onClick={cancelRecording}>Cancel</span>
        {
          !isEchoComment? <img src={sendSmall} alt="send" width="39" className="clickable" onClick={() => sendRecording(false)} /> : 
          <Button onClick={() => sendRecording(true)} className="btn commentInputSendAudio d-flex justify-content-center align-items-center">
            <div className="bold w-100 h-100 d-flex justify-content-center align-items-center pt-1">Echo</div> 
          </Button>
        } 
        </div>
      }
    </div>}
    </>
  )

  return (
    <div className={`riffContainer ${classNames} ${hide ? 'hide' : ''} ${isSideRiff ? 'riff-side' : ''}`} id={id}>
      <ConfirmationDialog 
        show={showConfirmationDialog}
        onHide={() => setShowConfirmationDialog(false)}
        handleClose={() => setShowConfirmationDialog(false)}
        onConfirm={() => onActionRiff({ ...data, triggeredAction})}
        title={confirmationDialogTitle}
      />
      <div className={`riff`}>
      { echoObj && !echoObj.echoRiff?
        <div className="echoCommentContainer d-flex flex-row align-items-center mb-3">
          <img src={echoOutlineGrey} alt="echo" width="20" height="14" className="mr-2" />
          {echoObj.user && <h6 className="m-0 pt-1 bold text-break">{echoObj.user.name + ' ' + t('riff.echoed') + (echoObj.comment? (': ' + echoObj.comment): '')}</h6>}
        </div>: echoObj && echoObj.echoRiff &&
        <div className="echoCommentContainer d-flex flex-row align-items-center mb-3">
          <img src={echoOutlineGrey} alt="echo" width="20" height="14" className="mr-2" />
          {echoObj.user && <h6 className="m-0 pt-1 bold echoedTxt text-break">{echoObj.user.name + ' ' + t('riff.echoed') + ': '}</h6>}
          <AudioPlayer 
            file={echoObj.echoRiff.file}
            waveform={waveform}
            waveformActive={waveformActive}
            audioRef={React.createRef()}
            onPlay={setCommentOnPlay}
            isPlaying={`${echoObj.echoRiff.id}-c` === commentOnPlay}
            id={echoObj.echoRiff.id}
            next={next}
            index={'c'}
            isComment
            isEcho
          />
        </div>
      }

      { !echoObj && commentObj && !commentObj.commentRiff?
        <div className="echoCommentContainer d-flex flex-row align-items-center mb-3">
          <img src={echoOutlineGrey} alt="echo" width="20" height="14" className="mr-2" />
          {commentObj.user && <h6 className="m-0 pt-1 bold text-break">{commentObj.user.name + ' ' + t('riff.replied') + (commentObj.comment? (': ' + commentObj.comment): '')}</h6>}
        </div>: commentObj && commentObj.commentRiff &&
        <div className="echoCommentContainer d-flex flex-row align-items-center mb-3">
          <img src={echoOutlineGrey} alt="echo" width="20" height="14" className="mr-2" />
          {commentObj.user && <h6 className="m-0 pt-1 bold echoedTxt">{commentObj.user.name + ' ' + t('riff.replied') + ': '}</h6>}
          <AudioPlayer 
            file={commentObj.commentRiff.file}
            waveform={waveform}
            waveformActive={waveformActive}
            audioRef={React.createRef()}
            onPlay={setCommentOnPlay}
            isPlaying={`${commentObj.commentRiff.id}-c` === commentOnPlay}
            id={commentObj.commentRiff.id}
            next={next}
            index={'c'}
            isComment
            isEcho
          />
        </div>
      }
      { !echoObj && !commentObj && likeObj?
        <div className="echoCommentContainer d-flex flex-row align-items-center mb-3">
          <img src={favouriteSolidGrey} alt="echo" width="20" height="18" className="mr-2" />
          {likeObj.user && <h6 className="m-0 pt-1 bold text-break">{likeObj.user.name + ' ' + t('riff.liked')}</h6>}
        </div>: null
      }

        <AudioPlayer
          file={file}
          length={length}
          waveform={waveform}
          waveformActive={waveformActive}
          audioRef={React.createRef()}
          onPlay={onPlay}
          isPlaying={isPlaying}
          id={data.id}
          next={next}
          playAllMode={playAllMode}
          setPlayAllMode={setPlayAllMode}
          date={date}
          index={index}
        />
        <div className={`d-flex ${isSideRiff ? 'flex-row align-items-center' : 'flex-column'}`}>
          {name && 
            <div className="riffName">
              <span 
                  onClick={() => !readOnly && !isMyRiff && onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.profile}/${data.userId}` })} 
                  className={`app-value ${!readOnly && !isMyRiff? 'clickable': ''}`}>
                  {name}
              </span>
            </div>}
          {dateRiff && (
            <div className={`riffDescription`}>
              <span className={`app-body light`}>
                  {dateDifferenceWithOffset(dateRiff)}
                </span>
            </div>
          )}
          {(description || tags.length > 0) && (
            <div className={`riffDescription ${isSideRiff ? 'mt-2' : ''}`}>
              {tags.length > 0 && 
                tags.map((tag, i) => 
                  <span 
                    onClick={() => !readOnly && onActionRiff({ ...data, triggeredAction: constants.riffActions.goTo, screen: `${constants.screens.home}/${tag}` })} 
                    key={i} 
                    className={`app-value ${!readOnly? 'clickable': ''} ${tag.toLowerCase() === 'podcastpreviews'? 'blueText': ''}`}>
                      {`#${tag}`}
                    </span>
                )}
              <span className={`app-body ${tags.length > 0? 'pl-1': ''} ${isSideRiff ? 'ml-2' : ''} light`}>{description}</span>
            </div>
          )}
          {data && data.user && data.user.slug === 'podcastpreviews' && data.link && data.link.url &&
            <div>
              <a rel="noopener noreferrer" id="externalLink" href={data.link.url} target="_blank" hidden>pod</a>
              <Button variant="outline-primary" className="goToPodcastBtn p-0 pt-1 px-2 my-2" onClick={() => document.getElementById('externalLink').click()}>
                <span>{t('riff.goToPodcast')}</span>
              </Button>
            </div>
          }
        </div>
        {!hideActions && (
          <div className="d-flex flex-row justify-content-between align-items-center">
          <Row className="no-gutters riff-footer">
            {playCount > 0 && (
            <div className="riff-footer-element">
              <img src={isPlayed ? play : playInactive} alt="play" className="play" />
              <span className="count light">{playCount}</span>
            </div>
            )}
            {commentCount > 0 && (
            <div className={`riff-footer-element ${!readOnly ? 'clickable' : ''}`} onClick={!readOnly ? handleOpenComments: undefined}>
              <img src={commented ? comment : commentInactive} alt="comment" className="comment" />
              <span className="count light">{commentCount}</span>
            </div>
            )}
            
            {!readOnly && !echoed ? 
            <>
            <Options data={echoOptions(() => onActionRiff({
                ...data,
                triggeredAction: constants.riffActions.echo,
              }))} icon={echoed ? echo : echoInactive} classNames="echo" />
            <span className="count light mr-4">{echoCount}</span> 
            </>:
              <div
                className={`riff-footer-element`}
                aria-hidden="true"
            >
              <img src={echoed ? echo : echoInactive} alt="echo" className="echo" />
              <span className="count light">{echoCount}</span>
            </div>
            }
            <div
              className={`riff-footer-element ${!readOnly ? 'clickable' : ''}`}
              aria-hidden="true"
              onClick={!readOnly ? () => onActionRiff({ ...data, triggeredAction: constants.riffActions.like }) : undefined}
            >
              <img src={liked ? like : likeInactive} alt="like" className={liked ? 'like' : 'like-inactive'} />
              <span className="count light">{likes}</span>
            </div>
            {!readOnly && (
            <div
              className={`riff-footer-element ${!readOnly ? 'clickable' : ''}`}
            >
              <Options data={isMyRiff? myRiffOptions: riffOptions} />
            </div>
            )}
          </Row>
          <Options data={riffCopyLinkOption()} icon={shareOutline} classNames="shareOutline" />
        </div>
        )}
        {hideActions && (
          <Col className="timeContainer d-block d-sm-block d-md-block d-lg-block d-xl-none pl-0">
            <span
              className="app-inner-body light"
            >
              {
                date && formatDate(date)
              }
            </span>
          </Col>
        )}
      </div>
      {canComment && !readOnly && renderCommentInput()}
    </div>
  
  );
};

Riff.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  commentCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  playCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  echoCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  likes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  file: PropTypes.string,
  isPlaying: PropTypes.bool,
  onPlay: PropTypes.func,
  classNames: PropTypes.string,
  readOnly: PropTypes.bool,
  hideActions: PropTypes.bool,
  onActionRiff: PropTypes.func,
  liked: PropTypes.bool,
  isPlayed: PropTypes.bool,
  commented: PropTypes.bool,
  echoed: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  tags: PropTypes.arrayOf(PropTypes.string),
  hide: PropTypes.bool,
  date: PropTypes.string,
  isSideRiff: PropTypes.bool,
  isMyRiff: PropTypes.bool,
  comments: PropTypes.object,
  isEchoComment: PropTypes.bool,
  canComment: PropTypes.bool
};

Riff.defaultProps = {
  name: '',
  description: '',
  classNames: '',
  readOnly: true,
  hideActions: false,
  isPlaying: false,
  onActionRiff: undefined,
  liked: false,
  isPlayed: false,
  commented: false,
  echoed: false,
  data: null,
  tags: [],
  hide: false,
  date: null,
  isSideRiff: false,
  isMyRiff: false,
  comments: null,
  isEchoComment: false,
  canComment: false,
};

export default memo(Riff);
