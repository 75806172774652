import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../../components/footer/footer';
import logo from '../../assets/icons/riffrLogoWhite.png';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import ResetPasswordForm from '../../components/resetPasswordForm/resetPasswordForm';
import { authenticate, resetPassword } from '../../services/auth';
import constants from '../../utils/constants';
import { clearUserById, getUserById } from '../../store/actions/resetPasswordActions';

const ResetPassword = ({ match }) => {
  const { t } = useTranslation();
  const form = React.createRef();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const resetPasswordState = useSelector((state) => state.resetPassword);
  const dispatch = useDispatch();

  useEffect(() => {
    const { id } = match.params;
    dispatch(getUserById(id));
  }, [dispatch, match.params]);

  const onSubmit = async (values) => {
    try {
      const tempPassword = resetPasswordState.result.forgot_password_token;
      setLoading(true);
      setError(null);
      const authenticationResponse = await authenticate(resetPasswordState.result.username, tempPassword);
      if ('error' in authenticationResponse && authenticationResponse.error === 'invalid_grant') {
        setError(t('authentication.resetLinkHasBeenExpired'));
        setLoading(false);
      } else {
        await resetPassword(authenticationResponse, tempPassword, values.newPassword);
        setLoading(false);
        setShowSuccess(true);
      }
    } catch (e) {
      setError(t('globals.somethingWentWrong'));
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (resetPasswordState.loading) {
      return <div className="text-center mt-5 mb-5"><Spinner animation="border" /></div>;
    }

    if (resetPasswordState.error || (resetPasswordState.result && resetPasswordState.result.forgot_password_token !== match.params.token)) {
      return (
        <>
          <h6 className="app-section-title light">{t('authentication.invalidResetLink')}</h6>
          <Row className="no-gutters align-items-center submit-container">
            <div className="flex-fill">
              <Link to={constants.screens.login}>
                <Button
                  variant="outline-primary"
                  className="submit-button app-value float-right"
                  onClick={() => dispatch(clearUserById())}
                >
                  {t('authentication.login')}
                </Button>
              </Link>
            </div>
          </Row>
        </>
      );
    }

    if (showSuccess) {
      return (
        <>
          <h6 className="app-section-title light">{t('authentication.passwordSuccessfullyReset')}</h6>
          <Row className="no-gutters align-items-center submit-container">
            <div className="flex-fill">
              <Link to={constants.screens.login}>
                <Button
                  variant="outline-primary"
                  className="submit-button app-value float-right"
                  disabled={loading}
                  onClick={() => null}
                >
                  {t('authentication.login')}
                </Button>
              </Link>
            </div>
          </Row>
        </>
      );
    }

    return (
      <>
        <h6 className="app-body">{t('authentication.chooseAPassword')}</h6>
        {error && (
        <Alert variant="danger">
          {error}
        </Alert>
        )}
        <ErrorBoundary>
          <ResetPasswordForm formReference={form} onSubmit={onSubmit} />
        </ErrorBoundary>
        <Row className="no-gutters align-items-center submit-container">
          <div className="flex-fill">
            <Button
              variant="outline-primary"
              className="submit-button app-value float-right"
              disabled={loading || error}
              onClick={() => form.current.submitForm()}
            >
              {t('authentication.resetPassword')}
              {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
            </Button>
          </div>
        </Row>
      </>
    );
  };

  return (
    <div className="authentication-screen">
      <ul className="nav" id="homepage-nav">
        <li className="nav-item flex-fill">
          <a className="nav-link nav-link-logo" href={constants.screens.default}>
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </li>
        <Row className="no-gutters">
          <li className="nav-item">
            <a className="nav-link" href={constants.screens.login}>
              <Row className="no-gutters align-items-center">
                <span className="login">{t('globals.cancel')}</span>
              </Row>
            </a>
          </li>
        </Row>
      </ul>
      <div className="page-content">
        <Row className=" no-gutters">
          <Col xs={10} sm={5} lg={4} className="offset-1 offset-sm-3 offset-lg-4">
            <h4 className="app-sub-header-2">{t('authentication.resetPassword')}</h4>
            {renderContent()}
          </Col>
        </Row>
        <div className="authentication-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default ResetPassword;
