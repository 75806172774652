import {
  FOLLOW,
  FOLLOW_FAIL,
  FOLLOW_SUCCESS,
  GET_USER,
  GET_USER_FAIL,
  GET_USER_SUCCESS,
} from '../../constants/connectionsConstants';

const initialState = {
  result: null,
  loading: false,
  error: null,
  userResult: null,
  userLoading: null,
  userError: null,
  followLoading: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FOLLOW: {
      const { user } = action.payload;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: true,
      };
      return {
        ...state,
        result: null,
        loading: true,
        error: null,
        userLoading: updatedUserLoading,
      };
    }
    case FOLLOW_SUCCESS: {
      const { user } = action.payload.config.reduxSourceAction.payload;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: false,
      };
      return {
        ...state,
        result: action.payload.data,
        loading: false,
        userLoading: updatedUserLoading,
      };
    }
    case FOLLOW_FAIL: {
      const { user } = action.meta.previousAction.payload;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: false,
      };
      return {
        ...state,
        loading: false,
        error: action.payload,
        userLoading: updatedUserLoading,
      };
    }
    case GET_USER: {
      const { user } = action.payload;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: true,
      };
      return {
        ...state,
        userResult: null,
        userLoading: updatedUserLoading,
        userError: null,
      };
    }
    case GET_USER_SUCCESS: {
      const { user } = action.payload.config.reduxSourceAction.payload;
      if(action.payload.data.actions) action.payload.data.actions.follower = action.payload.data.actions.following;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: false,
      };
      return {
        ...state,
        userResult: action.payload.data,
        userLoading: updatedUserLoading,
      };
    }
    case GET_USER_FAIL: {
      const { user } = action.meta.previousAction.payload;
      const updatedUserLoading = {
        ...state.userLoading,
        [user]: false,
      };
      return {
        ...state,
        userLoading: updatedUserLoading,
        userError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
