import { GET_USER_BLOCKED, GET_USER_BLOCKED_FAIL, GET_USER_BLOCKED_SUCCESS, GET_USER_MUTED, GET_USER_MUTED_FAIL, GET_USER_MUTED_SUCCESS, UNBLOCK_USER, UNBLOCK_USER_SUCCESS, UNMUTE_USER, UNMUTE_USER_SUCCESS } from "../constants/blockedMutedConstants";

const initialState = {
    loading: false,
    blockedUsers: [],
    mutedUsers: [],
    resultMuted: null,
    resultBlocked: null,
    userLoading: {}
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_BLOCKED:
            return {
                ...state,
                loading: true,
                blockedUsers: action.payload.page === 1? []: state.blockedUsers
            }
        case GET_USER_BLOCKED_SUCCESS:
            if(!action.payload.data.result) return {
                ...state,
                loading: false
            };
            return {
                ...state,
                loading: false,
                resultBlocked: action.payload.data,
                blockedUsers: action.payload.data.currentPage === 1? action.payload.data.result: [...state.blockedUsers, ...action.payload.data.result]
            }
        case GET_USER_BLOCKED_FAIL:
            return {
                ...state,
                loading: false
            }
        case GET_USER_MUTED:
            return {
                ...state,
                loading: true,
                mutedUsers: action.payload.page === 1? []: state.mutedUsers
            }
        case GET_USER_MUTED_SUCCESS:
            if(!action.payload.data.result) return {
                ...state,
                loading: false
            };
            return {
                ...state,
                loading: false,
                resultMuted: action.payload.data,
                mutedUsers: action.payload.data.currentPage === 1? action.payload.data.result: [...state.mutedUsers, ...action.payload.data.result]
            }
        case GET_USER_MUTED_FAIL:
            return {
                ...state,
                loading: false
            }
        case UNMUTE_USER:
        case UNBLOCK_USER: 
            return {
                ...state,
                userLoading: {
                    ...state.userLoading,
                    [action.payload.userId]: true
                }
            }
        case UNBLOCK_USER_SUCCESS: 
            return {
                ...state,
                useLoading: {
                    ...state.userLoading,
                    [action.payload.userId]: false
                },
                blockedUsers: state.blockedUsers.filter(user => user.id !== action.payload.userId)
            }
        case UNMUTE_USER_SUCCESS: 
            return {
                ...state,
                useLoading: {
                    ...state.userLoading,
                    [action.payload.userId]: false
                },
                mutedUsers: state.mutedUsers.filter(user => user.id !== action.payload.userId)
            }
        default:
            return state;
  }
};

export default reducer;
