import {
  DEBATE_VOTE, DEBATE_VOTE_SUCCESS, DEBATE_VOTE_FAIL, ADD_DEBATE_RIFF, ADD_DEBATE_RIFF_SUCCESS,
} from '../../constants/debateConstants';
import constants from '../../../utils/constants';

const initialState = {
  loading: false,
  result: null,
  error: null,
  votedTrump: false,
  votedBiden: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DEBATE_VOTE: {
      const { user } = action.payload;
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
        votedTrump: user === constants.debates.users.trump || state.votedTrump,
        votedBiden: user === constants.debates.users.biden || state.votedBiden,
      };
    }
    case ADD_DEBATE_RIFF:
      return {
        ...state,
        loading: true,
      };
    case ADD_DEBATE_RIFF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DEBATE_VOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        result: action.payload,
        error: null,
      };
    case DEBATE_VOTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default reducer;
