import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axiosMiddleware from 'redux-axios-middleware';
import constants from '../utils/constants';
import { clearAuthentication, saveAuthentication } from '../store/actions/authenticationActions';

const appClient = axios.create({
  baseURL: constants.apiUrl,
  headers: {
    'x-api-key': constants.apiKey,
  },
});

const refreshToken = () => {
  const body = {
    grant_type: 'refresh_token',
    refresh_token: '',
    client_id: constants.clientId,
    client_secret: constants.clientSecret,
  };
  return appClient.post('auth/refresh', body);
};

createAuthRefreshInterceptor(appClient, refreshToken);

const middlewareConfig = {
  interceptors: {
    request: [{
      success({ getState }, req) {
        const { authentication } = getState();
        if (req.authenticated) {
          req.headers.authorization = `${authentication.token_type} ${authentication.access_token}`;
        }
        if (req.url === 'auth/refresh') {
          req.data.refresh_token = authentication.refresh_token;
        }
        return req;
      },
    },
    ],
    response: [{
      success({ dispatch }, res) {
        if (res.config.url === 'auth/refresh') {
          dispatch(saveAuthentication(res.data));
        }
        return Promise.resolve(res);
      },
      error({ dispatch }, res) {
        if (res.config.url === 'auth/refresh') {
          dispatch(clearAuthentication());
          window.location.href = constants.screens.login;
        }
        return Promise.reject(res);
      },
    }],
  },
};

export default axiosMiddleware(appClient, middlewareConfig);
