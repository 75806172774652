import React, { createRef, useEffect, useRef, useState } from 'react'
import { Button, Col, Collapse, Dropdown, Row, Spinner } from 'react-bootstrap'
import MicRecorder from 'mic-recorder-to-mp3';
import logo from '../../assets/icons/riffrLogoBlack.png';
import dropDown from '../../assets/icons/chevronDown@3x.png';
// import fist from '../../assets/icons/fist@3x.png';
import mic from '../../assets/icons/record@3x.png';
import stop from '../../assets/icons/stop.png';
import waveform from '../../assets/icons/waveform.png';
import waveformActive from '../../assets/icons/waveformActive.png';
import './openMic.scss'
import { useTranslation } from 'react-i18next';
import constants from '../../utils/constants';
import { mapMinMax } from '../../utils/common';
import AudioRect from '../../components/audioRect/audioRect';
import AudioWave from '../../components/audioWave/audioWave';
import AudioPlayer from '../../components/audioPlayer/audioPlayer';
import Timer from 'react-compound-timer/build';
import DebateAudioForm from '../../components/debateAudioForm/debateAudioForm';
import { useParams } from 'react-router-dom';
import Riff from '../../components/riff/riff';
import { useDispatch, useSelector } from 'react-redux';
import { addRiffsByHost, getHostNames, getPageByHost, getRiffsByHost, setIsSubmitted } from '../../store/actions/openMicActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import Popup from '../../components/popup/popup';
import Footer from '../../components/footer/footer';
import FooterNav from '../../components/footerNav/footerNav';

export default function OpenMic({history}) {

    

    const { t } = useTranslation();
    const [showDetails, setShowDetails] = useState(false);
    const [isStartRecording, setIsStartRecording] = useState(false);
    const [micRecorder, setMicRecorder] = useState(new MicRecorder({ bitRate: 128 }));
    const [disabledStop, setDisabledStop] = useState(true);
    const [amplitude, setAmplitude] = useState(5);
    const [counter, setCounter] = useState(3);
    const [audioFile, setAudioFile] = useState(null);
    const [audioBlob, setAudioBlob] = useState(null);
    const [playingRiff, setPlayingRiff] = useState(0);
    const [audioPlaying, setAudioPlaying] = useState(0);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const counterRef = useRef();
    const form = useRef();
    const audioRef = createRef();
    const { hostName } = useParams();
    const dispatch = useDispatch();
    const openMic = useSelector((state) => state.openMic);
    const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;
    const { page, loadingPage, hostNames } = openMic;

    var showOpenMics = true;

    console.log(page);
    if (page && page.id == 4) {

        if (page.bodyClass) document.body.classList.add(page.bodyClass);
        showOpenMics = false;
    }
    



    useEffect(() => {
        dispatch(getRiffsByHost(hostName));
        dispatch(getPageByHost(hostName));
        dispatch(getHostNames());        
    },[dispatch, hostName]);

    const checkPermission = (num) => {
        micRecorder.start().then((stream) => {
            micRecorder.stop();
            setIsStartRecording(true);
            let counterSec = num;
            let interval = setInterval(() => {
                counterSec--;
                setCounter(counterSec);
                if(counterSec <= 0) {
                    clearInterval(interval);
                    startRecording();
                }
            }, 1000);
            
        }).catch((e) => {
            alert(t('globals.featureRequiresMicrophonePermission'));
      });
    }

    const startRecording = () => {
        micRecorder.start().then((stream) => {
            setAmplitude(5);
            counterRef.current.start();
            subscribeToMic(stream);
            setMicRecorder(micRecorder);
          }).catch((e) => {
            alert(t('globals.featureRequiresMicrophonePermission'));
          });
    }

    const stopRecording = () => {
        micRecorder
          .stop()
          .getMp3().then(([buffer, blob]) => {
            counterRef.current.stop();
            buffer.splice(0, 36);
            const file = new File(buffer, 'file123.mp3', {
              type: blob.type,
              lastModified: Date.now(),
            });
            const newBlob = new Blob(buffer, { type: blob.type });
            setAudioFile(URL.createObjectURL(file));
            setAudioBlob(newBlob);
          }).catch((e) => {
            alert('We could not retrieve your message');
          });
      }

    const subscribeToMic = (stream) => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
    
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
    
        microphone.connect(analyser);
        analyser.connect(javascriptNode);
        javascriptNode.connect(audioContext.destination);
        javascriptNode.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;
    
          const { length } = array;
          for (let i = 0; i < length; i++) {
            values += (array[i]);
          }
          let average = values / length;
          if (average !== 0) {
            if (average < 5) average = 5;
            setAmplitude(mapMinMax(0, 80, 0, constants.maxWaveAmplitude, average));
          }
        };
      };

    const downloadTheApp = () => {
        if( /Android/i.test(navigator.userAgent) ) {
            window.location.href = constants.riffrAndroidStore;        
        } else if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
            window.location.href = constants.riffrAppStore;
        } else {
            window.location.href = constants.riffrAppStore;
        }
    }

    useEffect(() => {
        if(!openMic.loadingSubmit && openMic.submitted) {
            startOver();
            dispatch(setIsSubmitted(false));
            setShowSuccessModal(true);
        }
    },[openMic.loadingSubmit, openMic.submitted, dispatch])

    const startOver = () => {
        setDisabledStop(true);
        setIsStartRecording(false);
        setCounter(3);
        setAudioFile(null);
        setAudioBlob(null);
    }

    const renderRiffs = () => (
        <>
        {openMic.loading && openMic.allIds.length === 0 && loading}
        {openMic.allIds && openMic.allIds.length !== 0 && (
            <InfiniteScroll
            className="feeds-scroll-container text-left"
            dataLength={openMic.allIds.length}
            next={() => null}
            hasMore={openMic.hasMore}
            >
            {openMic.allIds.map((id, index) => {
                // eslint-disable-next-line no-shadow
                const riff = openMic.byId[id];
                return (
                <Riff
                    name={riff.user.name.replace(" ", "").length? riff.user.name: t('openMic.anonymous')}
                    description={riff.name}
                    commentCount={riff.commentCount}
                    playCount={riff.playCount}
                    echoCount={riff.echoCount}
                    likes={riff.likes}
                    length={riff.length}
                    file={riff.file}
                    key={`${riff.id}-${index.toString()}`}
                    index={index}
                    indexPlaying=""
                    classNames="feed-riff-item"
                    readOnly
                    hideActions
                    liked={riff.actions?.liked}
                    isPlayed={riff.actions?.played}
                    commented={riff.actions?.commented}
                    echoed={riff.actions?.echoed}
                    data={riff}
                    onPlay={(playId) => {
                    setAudioPlaying(playId);
                    }}
                    isPlaying={`${riff.id}-${index}` === audioPlaying}
                    tags={riff.tags}
                    hide={riff.hide}
                    date={riff.dateCreated}
                />
                );
            })}
            </InfiniteScroll>
        )}
      </>
    )

    const submitRiff = () => {
        const formData = new FormData();
          formData.append('publish', '1');
          formData.append('userName', form.current.values.name);
          formData.append('name', form.current.values.description);
          formData.append('file', audioBlob, 'fileRiff.mp3');
          dispatch(
            addRiffsByHost(
              hostName,
              formData,
            ),
          );
    }


    return (
        <Col className="no-gutters openMic">
            <Popup
                show={showSuccessModal}
                onHide={() => setShowSuccessModal(false)}
                title="Nice work, you just posted your first riff!"
                subTitle="Why not download the riffr app for the next time you want to share your voice? It’s the social platform for (micro)podcasting."
                
            />
            
            <Row className="no-gutters p-2 pt-4 p-sm-5 w-100 d-flex justify-content-between align-items-center">

            {!showOpenMics && <FooterNav />}
            
            <Dropdown>
                {showOpenMics && <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <div className="d-flex flex-row align-items-center clickable">
                        <img src={logo} alt="Logo" className="logo" />
                        <h5 className="m-0 bold ml-3 exploreTxt">{t('openMic.exploreOtherOpenMics')}</h5>
                        <img src={dropDown} alt="v" className="ml-1" height="10" />
                    </div>
                </Dropdown.Toggle>}
                {showOpenMics && <Dropdown.Menu>
                    {hostNames.map((hostName, i)=>(
                        <Dropdown.Item key={i} href={`/${hostName.name.toLowerCase()}`}>{hostName.name}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>}
            </Dropdown>
                <h5 className="pageTag bold m-0 d-none d-sm-inline">#{page? page.name: hostName}</h5>
            </Row>
            <Row className="no-gutters w-100 d-flex flex-column justify-content-center align-items-center text-center">
                <Col xs={10} lg={6} className="openMicHolder no-gutters w-100 d-flex flex-column justify-content-center align-items-center text-center">
                {loadingPage && loading}
                {page && page.image && <img src={page.image} alt="icon" width="100" />}
                {page && <h3 className="bold m-0 mt-4">{page.title}</h3>}
                {page && <h5 className="bold m-0 m-4">{page.subtitle}</h5>}
                <>
                    {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                    <h5
                        aria-hidden="true"
                        className="m-0 how-it-works clickable disable-select bold"
                        onClick={() => setShowDetails(!showDetails)}
                        aria-controls="vote-details"
                        aria-expanded={showDetails}
                    >
                        {showDetails ? t('openMic.hideInstructions') : t('openMic.learnMoreAboutHowItWorks')}
                    </h5>
                    <Collapse in={showDetails}>
                        <Row className="no-gutters">
                        <Col className="offset-1 offset-lg-3">
                            <div id="vote-details">
                            <Row className="no-gutters mt-4">
                                <Col>
                                <span className="app-value">
                                    1.
                                    <span className="light">{` ${t('openMic.clickButton')}`}</span>
                                </span>
                                </Col>
                            </Row>
                            <Row className="no-gutters mt-4">
                                <Col>
                                <span className="app-value">
                                    2.
                                    <span className="light">{` ${t('openMic.dontStopThere')}`}</span>
                                    <span onClick={downloadTheApp} className="how-it-works clickable">{t('openMic.downloadTheRiffrAppToday')}</span>
                                    <span className="light">{` ${t('openMic.toShareYourThoughtsAbout')} #${page? page.name: hostName} ${t('openMic.onYourOtherSocialMediaAccounts')}`}</span>
                                </span>
                                </Col>
                            </Row>
                            </div>
                        </Col>
                        </Row>
                    </Collapse>
                </>
                <Col xs={10} sm={10} md={10} lg={10} xl={10} className="no-gutters mt-5">
                    {
                        isStartRecording? (
                            <>
                                {
                                    <div className="w-100 bold recordingContainer d-flex flew-row justify-content-center align-items-center">
                                        {counter? <div className="bold countDownTimer"> {counter} </div>: 
                                        audioFile? (
                                            <>
                                                {openMic.loadingSubmit? loading: (
                                                    <div className="p-4">
                                                        <AudioPlayer
                                                            file={audioFile}
                                                            waveform={waveform}
                                                            waveformActive={waveformActive}
                                                            audioRef={audioRef}
                                                            onPlay={(id)=>setPlayingRiff(id)}
                                                            isPlaying={playingRiff}
                                                            id={1}
                                                            isYourRiff
                                                            index="1"
                                                        />
                                                        <DebateAudioForm formReference={form} canResize={false} maxLength="50" hasCounter={false} rows="2" />
                                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                                            <Button onClick={startOver} className="btn startOverBtn bold formBtn px-3 px-lg-4">{t('openMic.startOver')}</Button>
                                                            <Button onClick={submitRiff} className="btn shareBtn bold formBtn px-3 px-lg-4">{t('openMic.share')}</Button>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                        :(
                                            <>
                                                <div className="w-50 d-flex px-3 flew-row justify-content-center align-items-center">
                                                    <AudioRect amplitude={amplitude} 
                                                               bars={2} 
                                                               width={22} 
                                                               radius={15} 
                                                               margin={50} 
                                                               height="50px"
                                                            />
                                                    <div className="verticalLine ml-1" />
                                                    <AudioWave amplitude={amplitude} 
                                                               waves={3} 
                                                               height="50px"
                                                            />
                                                </div>
                                                <div className="w-50 micTimer d-flex flex-row justify-content-between align-items-center pr-3">
                                                    <div className="d-flex flex-row">
                                                        <Timer
                                                            ref={counterRef}
                                                            startImmediately={false}
                                                            checkpoints={[
                                                                {
                                                                    time: constants.maxAudioLength * 1000,
                                                                    callback: () => document.getElementById('stopBtn').click(),
                                                                },
                                                                {
                                                                    time: constants.minAudioLength * 1000,
                                                                    callback: () => setDisabledStop(false),
                                                                },
                                                            ]}
                                                            >
                                                            <Timer.Minutes />
                                                            :
                                                            <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
                                                        </Timer>
                                                        <div className="d-none d-sm-none d-md-none d-lg-none d-xl-block">
                                                            /
                                                            <Timer startImmediately={false} initialTime={constants.maxAudioLength * 1000}>
                                                                <Timer.Minutes />
                                                                :
                                                                <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
                                                            </Timer>
                                                        </div>
                                                    </div>
                                                    
                                                    <Button id="stopBtn" className="btn rounded-circle stopBtn d-flex justify-content-center align-items-center ml-2" onClick={stopRecording} disabled={disabledStop}>
                                                        <img src={stop} width="50" alt="stop" />
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                }
                            </>
                        ): (
                            <Button onClick={() => checkPermission(3) } className="btn w-100 micBtn d-flex flew-row justify-content-center align-items-center">
                                <img src={mic} alt="mic" width="21" />
                                <h5 className="bold m-0 ml-3">{t('openMic.clickToRecord')}</h5>
                            </Button>
                        )
                    }
                    {
                        openMic.result && openMic.result.maxResults && !openMic.loading && (
                            <h5 className="m-0 mt-5 mb-3 bold">{openMic.result && openMic.result.maxResults} {t('openMic.riffsSharedByOthers')}</h5>
                        )
                    }
                    {renderRiffs()}
                </Col>
                </Col>
            </Row>
            <Footer />
        </Col>
    )
}
