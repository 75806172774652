import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextInput from '../formInputs/textInput/textInput';
import constants from '../../utils/constants';

const initialValues = {
  newPassword: '',
  confirmNewPassword: '',
};

const ResetPasswordForm = ({ onSubmit, formReference }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');

  const getValidationSchema = () => Yup.object({
    newPassword: Yup.string()
      .required(t('validation.fieldIsRequired'))
      .min(
        constants.validation.passwordMinLength,
        `${t('validation.fieldShouldBeAtLeast')} ${constants.validation.passwordMinLength} ${t('validation.characters')}`,
      )
      .matches(constants.validation.containsOneLetter, t('validation.fieldMustContainOneLetter'))
      .matches(constants.validation.containsOneNumberOrSpecialCharacter, t('validation.fieldMustContainOneNumberOrSpecialCharacter')),
    confirmNewPassword: Yup.string()
      .required(t('validation.fieldIsRequired'))
      .test('match', t('authentication.passwordsDoNotMatch'), (value) => value === password),
  });

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      onSubmit={onSubmit}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="newPassword"
              id="newPassword"
              placeholder={t('authentication.newPassword')}
              type="password"
              handleChange={(event) => {
                handleChange(event);
                setPassword(event.target.value);
              }}
              handleBlur={handleBlur}
              values={values}
            />
            <TextInput
              name="confirmNewPassword"
              id="confirmNewPassword"
              placeholder={t('authentication.confirmNewPassword')}
              type="password"
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              disabled={values.newPassword === ''}
            />
          </>
        )
      }

    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
};

export default ResetPasswordForm;
