import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import './progressBat.style.scss';

const Bar = ({ active }) => (<div className={`progress-bar-item ${active ? 'active' : 'inactive'}`} />);

Bar.propTypes = {
  active: PropTypes.bool.isRequired,
};

const ProgressBar = ({ steps, activeStep }) => (
  <Row className="no-gutters progress-bar-container">
    {
      Array(steps).fill({}).map((record, index) => (
        <Col key={index.toString()}>
          <Bar active={index === activeStep - 1} />
        </Col>
      ))
    }
  </Row>
);

ProgressBar.propTypes = {
  steps: PropTypes.oneOf([2, 3, 4]).isRequired,
  activeStep: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
};

export default ProgressBar;
