import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import './confirmationDialog.scss'

export default function ConfirmationDialog({onConfirm, handleClose, title, ...props}) {
    const { t } = useTranslation();
    return (
        <Modal
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className="popup">
                <h5>{t('confiramtionDialog.areYouSure') + title.toLowerCase()}?</h5>
                <div className="w-100 d-flex justify-content-end">
                    <Button variant="outline-primary" onClick={() => [onConfirm(), handleClose()]} className="mr-2 yes-no-btn">yes</Button>
                    <Button variant="outline-primary" onClick={handleClose} className="yes-no-btn">no</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
