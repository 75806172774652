const ADD_DEBATE_RIFF = 'ADD_DEBATE_RIFF';
const ADD_DEBATE_RIFF_SUCCESS = 'ADD_DEBATE_RIFF_SUCCESS';
const ADD_DEBATE_RIFF_FAIL = 'ADD_DEBATE_RIFF_FAIL';

const GET_DEBATE_RIFFS_TRUMP = 'GET_DEBATE_RIFFS_TRUMP';
const GET_DEBATE_RIFFS_TRUMP_SUCCESS = 'GET_DEBATE_RIFFS_TRUMP_SUCCESS';
const GET_DEBATE_RIFFS_TRUMP_FAIL = 'GET_DEBATE_RIFFS_TRUMP_FAIL';

const GET_DEBATE_RIFFS_BIDEN = 'GET_DEBATE_RIFFS_BIDEN';
const GET_DEBATE_RIFFS_BIDEN_SUCCESS = 'GET_DEBATE_RIFFS_BIDEN_SUCCESS';
const GET_DEBATE_RIFFS_BIDEN_FAIL = 'GET_DEBATE_RIFFS_BIDEN_FAIL';

const DEBATE_VOTE = 'DEBATE_VOTE';
const DEBATE_VOTE_SUCCESS = 'DEBATE_VOTE_SUCCESS';
const DEBATE_VOTE_FAIL = 'DEBATE_VOTE_FAIL';

export {
  ADD_DEBATE_RIFF,
  ADD_DEBATE_RIFF_SUCCESS,
  ADD_DEBATE_RIFF_FAIL,
  GET_DEBATE_RIFFS_TRUMP,
  GET_DEBATE_RIFFS_TRUMP_SUCCESS,
  GET_DEBATE_RIFFS_TRUMP_FAIL,
  GET_DEBATE_RIFFS_BIDEN,
  GET_DEBATE_RIFFS_BIDEN_SUCCESS,
  GET_DEBATE_RIFFS_BIDEN_FAIL,
  DEBATE_VOTE,
  DEBATE_VOTE_SUCCESS,
  DEBATE_VOTE_FAIL,
};
