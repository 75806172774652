import React from 'react';
import PropTypes from 'prop-types';
import './connection.scss';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';

const Connection = ({
  id, name, handle, bio, following, onClick, loading, goToProfile
}) => {
  const { t } = useTranslation();

  return (
    <div className="connection">
      <Row className="no-gutters">
        <div className="flex-grow-1">
          <div className="app-value name clickable" onClick={() => goToProfile(id)}>{name}</div>
          <div className="app-value handle clickable" onClick={() => goToProfile(id)}>{`@${handle}`}</div>
        </div>

        <div>
          <Button
            size="sm"
            variant={`${!following ? 'outline-primary' : 'primary'}`}
            className={`app-value ${!following ? 'followBtn' : 'followingBtn'}`}
            onClick={() => onClick(following)}
            disabled={loading}
          >
            {t(`profile.${following ? 'following' : 'follow'}`) + (loading ? '...' : '')}
          </Button>
        </div>
      </Row>

      {bio && <div className="app-inner-body light">{bio}</div>}
      <h6>{following}</h6>
    </div>
  );
};

Connection.propTypes = {
  name: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  bio: PropTypes.string,
  following: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Connection.defaultProps = {
  bio: null,
  loading: false,
};

export default Connection;
