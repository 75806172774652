const GET_TRENDING_HASH_TAGS = 'GET_TRENDING_HASH_TAGS';
const GET_TRENDING_HASH_TAGS_SUCCESS = 'GET_TRENDING_HASH_TAGS_SUCCESS';
const GET_TRENDING_HASH_TAGS_ERROR = 'GET_TRENDING_HASH_TAGS_ERROR';
const SEARCH_ALL = 'SEARCH_ALL';
const SEARCH_ALL_SUCCESS = 'SEARCH_ALL_SUCCESS';
const SEARCH_ALL_ERROR = 'SEARCH_ALL_ERROR';
const SAVE_SEARCH_HISTORY = 'SAVE_SEARCH_HISTORY';
const CLEAR_SEARCH_HISTORY = 'CLEAR_SEARCH_HISTORY';

export {
  GET_TRENDING_HASH_TAGS,
  GET_TRENDING_HASH_TAGS_SUCCESS,
  GET_TRENDING_HASH_TAGS_ERROR,
  SEARCH_ALL,
  SEARCH_ALL_SUCCESS,
  SEARCH_ALL_ERROR,
  SAVE_SEARCH_HISTORY,
  CLEAR_SEARCH_HISTORY,
};
