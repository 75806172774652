import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';
import {
  GET_DEBATE_RIFFS_TRUMP,
  GET_DEBATE_RIFFS_TRUMP_FAIL,
  GET_DEBATE_RIFFS_TRUMP_SUCCESS,
} from '../../constants/debateConstants';

const initialState = {
  loading: false,
  result: {
    currentPage: 0,
  },
  byId: {},
  allIds: [],
  error: null,
  hasMore: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEBATE_RIFFS_TRUMP: {
      const { page } = action.payload.request.params;
      const isFirstPage = page === 1;
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
        byId: isFirstPage ? {} : state.byId,
        allIds: isFirstPage ? [] : state.allIds,
      };
    }
    case GET_DEBATE_RIFFS_TRUMP_SUCCESS: {
      const { data } = action.payload;
      const riff = new schema.Entity('riff');
      const Schema = { riffs: [riff] };
      const normalizedData = normalize({ riffs: data.result }, Schema);
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        hasMore: action.payload.data.currentPage < action.payload.data.totalPages,
        byId: merge({}, state.byId, normalizedData.entities.riff),
        allIds: action.payload.data.result ? state.allIds.concat(normalizedData.result.riffs) : state.allIds,
      };
    }
    case GET_DEBATE_RIFFS_TRUMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default reducer;
