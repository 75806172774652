import React, { useCallback, useMemo } from 'react'

export default function AudioWave({amplitude, startedRecording, getAnimationTransition, isHidden, withAnimation, waves, height}) {
    const svgPathD = [];
  const calculateCurve = useCallback(
    (point, i) => {
      const ratio = 1;
      const xyPoint = {
        x1: point,
        x2: point + 40,
        dx: point + 120,
        dy: 50,
        amplitude: i % 2 === 0 ? 50 + (amplitude * ratio) : 50 - (amplitude * ratio),
      };
      return ['C', xyPoint.x1, xyPoint.amplitude, ',', xyPoint.x2, xyPoint.amplitude, ',', xyPoint.dx, xyPoint.dy].join(' ');
    },
    [amplitude],
  );

  const createWave = useCallback(() => {
    svgPathD.push('M-140 50');

    for (let point = -60, i = 1; point <=  waves * 400; point += 200) {
      svgPathD.push(calculateCurve(point, i));
      i++;
    }
  }, [
    svgPathD,
    calculateCurve,
    waves
  ]);

  const path = useMemo(() => {
    createWave();
    return (
      <path
        id="double-wave"
        fill="none"
        strokeLinecap="round"
        d={svgPathD.join(' ')}
      />
    );
  }, [svgPathD, createWave]);

    return (
        <svg
            viewBox="0 0 500 100"
            style={{
              width: '100%',
              height,
              ...startedRecording && withAnimation ? getAnimationTransition(0.5) : null,
            }}
            className={`waveForm ${startedRecording || !withAnimation ? 'waveAmination' : ''}`}
            hidden={isHidden}
          >
            <defs>
                {
                    path
                }
            </defs>
            <use href="#double-wave" x="0" y="0">
              <animate
                attributeName="x"
                from="-140"
                to="-540"
                dur="1s"
                repeatCount="indefinite"
              />
            </use>
          </svg>
    )
}
