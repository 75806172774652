import React from 'react';

/* eslint-disable */
const AccountDeletion = () => (
  <>
    <p className="p1">Updated April 16, 2024</p>
    <h3>Android Users</h3>
    <p className="p2">The subscription must be cancelled through your Google Play account by <a href="https://support.google.com/googleplay/answer/7018481" target="_blank">clicking here</a> or instructions can be found by visiting this link <a href="https://support.google.com/googleplay/answer/7018481" target="_blank">https://support.google.com/googleplay/answer/7018481</a>.</p>
    <p className="p2">Unfortunately, since we do not have access to Google Play's system, we are unable to cancel the Google based subscription for you. If you&#39;re having trouble doing so, here&#39;s where you can contact the Google Support Team for more help: <a href="https://support.google.com/googleplay/gethelp?sjid=17436547664005384114-NA" target="_blank">https://support.google.com/googleplay/gethelp?sjid=17436547664005384114-NA</a>.</p>
    <h3>iPhone Users</h3>
    <p className="p2">The subscription must be cancelled through your iTunes account by <a href="https://support.apple.com/en-us/HT202039" target="_blank">clicking here</a> or instructions can be found by visiting this link <a href="https://support.apple.com/en-us/HT202039" target="_blank">https://support.apple.com/en-us/HT202039</a>.</p>
    <p className="p2">Unfortunately, since we do not have access into Apple's system, we are unable to cancel the Apple based subscription for you.</p>
    <h3>Delete your Account</h3>
    <p className="p2">To cancel your subscription or delete your account, email us at <a href="mailto:support@riffr.com">support@riffr.com</a>.</p>
  </>
);
/* eslint-enable */

export default AccountDeletion;
