import { publicHttpClient } from '../utils/http/http';
import constants from '../utils/constants';
import { getClientInformation, isEmail } from '../utils/common';

/**
 * @param {string} handle
 */
export const handleExists = async (handle) => {
  try {
    const { data } = await publicHttpClient.get(`user/handle/unique?handle=${handle}`);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {object} params
 */
export const createUser = async (params) => {
  try {
    const body = {
      client_id: constants.clientId,
      client_secret: constants.clientSecret,
      name: `${params.firstName} ${params.lastName}`,
      password: params.password,
      handle: params.handle,
      bio: params.bio,
    };
    const signUpUsingEmail = await isEmail(params.username);
    if (signUpUsingEmail) {
      body.email = params.username;
    } else {
      body.phone = params.username;
    }
    const { data } = await publicHttpClient.post('user', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {string} username
 */
export const resendVerification = async (username) => {
  try {
    const signUpUsingEmail = await isEmail(username);
    const body = {
      ...getClientInformation(),
      [signUpUsingEmail ? 'email' : 'phone']: username,
    };
    const { data } = await publicHttpClient.post('user/resend', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {object} params
 */
export const verifyUser = async (params) => {
  try {
    const signUpUsingEmail = await isEmail(params.username);
    const body = {
      ...getClientInformation(),
      [signUpUsingEmail ? 'email' : 'phone']: params.username,
      pin: params.pin,
    };
    const { data } = await publicHttpClient.post('user/confirm', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {object} params
 */
export const resetPassword = async (params) => {
  try {
    const body = {
      token: params.token,
    };
    const { data } = await publicHttpClient.put('settings/reset_password', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {object} params
 */
export const updateProfile = async (params) => {
  try {
    const body = {
      first_name: params.firstName,
      last_name: params.lastName,
      bio: params.bio,
    };
    const { data } = await publicHttpClient.put('user', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};
