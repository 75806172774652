import React, { useState } from 'react'
import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import ChangePasswordForm from '../../components/changePasswordForm/changePasswordForm';
import constants from '../../utils/constants';
import { handleError } from '../../utils/http/http';
import './changePassword.scss';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import { resetPassword } from '../../services/auth';
import { useSelector } from 'react-redux';

export default function ChangePassword({history}) {
    const { t } = useTranslation();
    const form = React.createRef();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    // const dispatch = useDispatch();
    const authentication = useSelector(state => state.authentication)

    const submitResetPassword = async (values) => {
        try {
            setLoading(true);
            setError(null);
            const response = await resetPassword(authentication ,values.oldPassword, values.password);
            setLoading(false);
            if (response.error_description) {
              setError(handleError(response, t));
            } else {
              setError(null);
              history.push(constants.screens.settings);
            }
          } catch (e) {
            if (e.data && e.data.error_description) {
                setError(handleError(e.data, t));
            } else {
                setError(t('globals.somethingWentWrong'));
            }
            setLoading(false);
          }
    } 
    return (
        <Page header={<AuthenticatedNavBar activeKey="profile" />} hideMargin>
            <Row className="no-gutters mt-4">
                <Col xs={1} sm={2} md={3}>
                    <PageLeftContent title={t('globals.backToSettings')} navigateToRef={constants.screens.settings} />
                </Col>
                <Col xs={10} sm={8} md={6} lg={5}>
                    <PageTitle>{t('settings.changePassword')}</PageTitle>
                    {error && (
                        <Alert variant="danger">
                            {error}
                        </Alert>
                        )}
                    <ErrorBoundary>
                        <ChangePasswordForm onSubmit={submitResetPassword} formReference={form} />
                    </ErrorBoundary>
                    <div className="d-flex flex-row justify-content-end">
                        <Button
                            variant="outline-primary"
                            className="app-value changePasswordBtn d-flex flex-row justify-content-center align-items-center"
                            disabled={loading}
                            onClick={() => form.current.submitForm()}
                            >
                            {t('settings.changePassword')}
                            {loading && <Spinner animation="border" className="ml-3 spinner spinner-border-sm" />}
                        </Button>
                    </div>
                </Col>
            </Row>
        </Page>
    )
}
