import React, { useState } from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import PropTypes from 'prop-types';
import './textArea.scss';
import ConditionalWrap from '../../conditionalWrap/conditionalWrap';

const TextArea = ({ label, ...props }) => {
  const [focus, setFocused] = useState(false);
  const [field, meta] = useField(props);
  const {
    id, name, handleChange, handleBlur, values, placeholder, disabled, maxLength, canResize, hasCounter, rows
  } = props;
  const hasError = meta.touched && meta.error;

  const renderInput = () => (
    <Form.Control
      type="text"
      name={field.name}
      placeholder={placeholder || label}
      onChange={handleChange}
      onBlur={(event) => {
        handleBlur(event);
        setFocused(false);
      }}
      onFocus={() => setFocused(true)}
      value={values[name]}
      className={`${focus ? 'active' : 'inactive'} ${(maxLength !== '' && hasCounter)? '': 'noCounter'}`}
      disabled={disabled}
      as="textarea"
      rows={rows}
      maxLength={maxLength}
      style={canResize? {}: {resize: "none"}}
    />
  );

  return (
    <Form.Group controlId={`form${id || name}`} className={`app-text-area app-text-input ${hasError ? 'app-text-input-error' : ''}`}>
      {label && <Form.Label>{label}</Form.Label>}
      <ConditionalWrap
        condition={maxLength !== ''}
        wrap={(children) => (
          <InputGroup>
            {children}
            {maxLength !== '' && hasCounter && (
            <InputGroup.Append>
              <InputGroup.Text className={`numbers-of-characters-container ${focus ? 'active' : 'inactive'}`}>
                <span className="numbers-of-characters">
                  {`${values[name] ? values[name].length : 0}/${maxLength} ch`}
                </span>
              </InputGroup.Text>
            </InputGroup.Append>
            )}
          </InputGroup>
        )}
      >
        {renderInput()}
      </ConditionalWrap>

      {hasError && (
        <Form.Text className="error-message">
          {meta.error}
        </Form.Text>
      )}
    </Form.Group>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canResize: PropTypes.bool,
  hasCounter: PropTypes.bool,
  rows: PropTypes.string,
};

TextArea.defaultProps = {
  label: '',
  id: '',
  name: '',
  placeholder: '',
  disabled: false,
  maxLength: 255,
  canResize: true,
  hasCounter: true,
  rows: "3"
};

export default TextArea;
