import { normalize, schema } from 'normalizr';
import merge from 'lodash/merge';
import dotProp from 'dot-prop-immutable';
import {
  ADD_RIFF_COMMENT,
  GET_RIFFS, GET_RIFFS_BY_ID, GET_RIFFS_BY_ID_FAIL, GET_RIFFS_BY_ID_SUCCESS, GET_RIFFS_FAIL, GET_RIFFS_SUCCESS, GET_RIFF_COMMENTS, GET_RIFF_COMMENTS_SUCCESS, SET_LOADING,
} from '../constants/hompageConstants';
import {
  ECHO_RIFF, HIDE_RIFF, HIDE_USER_RIFFS, LIKE_COMMENT, LIKE_RIFF, PLAY_RIFF,
} from '../constants/riffConstants';
import { GET_USER_RIFF, GET_USER_RIFF_FAIL, GET_USER_RIFF_SUCCESS } from '../constants/userConstants';
import { ADD_COMMENT, DELETE_COMMENT } from '../constants/commentConstants';

const initialState = {
  loading: false,
  result: {
    currentPage: 0,
  },
  byId: {},
  allIds: [],
  error: null,
  hasMore: false,
  riffId: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_RIFF:
    case GET_RIFFS: 
      const { page } = action.payload.request.params;
      const isFirstPage = page === 1;
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
        byId: isFirstPage ? {} : state.byId,
        allIds: isFirstPage ? [] : state.allIds,
      };
    case GET_USER_RIFF_SUCCESS:
    case GET_RIFFS_SUCCESS: 
      const { data } = action.payload;
      const riff = new schema.Entity('riff');
      const Schema = { riffs: [riff] };
      const normalizedData = normalize({ riffs: data.result }, Schema);
      return {
        ...state,
        loading: false,
        result: action.payload.data,
        hasMore: action.payload.data.currentPage < action.payload.data.totalPages,
        byId: merge({}, state.byId, normalizedData.entities.riff),
        allIds: action.payload.data.result ? state.allIds.concat(normalizedData.result.riffs) : state.allIds,
      };
    case GET_USER_RIFF_FAIL:
    case GET_RIFFS_FAIL: 
      return {
        ...state,
        loading: false,
        error: action.error.response && action.error.response.data.error_description,
      };
    case GET_RIFFS_BY_ID_FAIL:{
      return {
        ...state,
        loading: false,
        error: action.error.response && action.error.response.data.error_description,
      };
    }
    case GET_RIFFS_BY_ID: {
      return {
        ...state,
        loading: true,
        result: null,
        error: null,
      };
    }
    case GET_RIFFS_BY_ID_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        result: data,
        byId: { [data.id]: data },
        allIds: [data.id]
      };
    }
    case LIKE_RIFF: {
      const { id, actions } = action.payload.params;
      return dotProp.set(state, `byId.${id}.likes`, actions.liked ? state.byId[id].likes + 1 : state.byId[id].likes - 1);
    }
    case LIKE_COMMENT: {
      const { id, actions, riffId } = action.payload.comment;
      const { comments } = state.byId[riffId]
      for(const comment of comments.result) {
        if(comment.id === id) {
          comment.actions.liked = !actions.liked;
          comment.likes = comment.likes + (actions.liked? 1: -1);
        }
      }
      return dotProp.set(state, `byId.${riffId}.comments`, comments);
    }
    case PLAY_RIFF: {
      const { id, actions } = action.payload.params;
      const updatedState = dotProp.set(state, `byId.${id}.actions`, actions);
      return dotProp.set(updatedState, `byId.${id}.playCount`, state.byId[id].playCount + 1);
    }
    case ECHO_RIFF: {
      const { id } = action.payload.params;
      const updatedState = dotProp.set(state, `byId.${id}.actions.echoed`, true);
      return dotProp.set(updatedState, `byId.${id}.echoCount`, state.byId[id].echoCount + 1);
    }
    case HIDE_RIFF: {
      const { riffId } = action.payload;
      return dotProp.set(state, `byId.${riffId}.hide`, true);
    }
    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      }
    }
    case HIDE_USER_RIFFS: 
      const { userId } = action.payload;
      const { byId } = state;
      for( const riff of state.result.result){ 
        if(riff.user && riff.user.id === userId){
          byId[riff.id].hide = true;
        }
      }
      return {...state, byId};
    case GET_RIFF_COMMENTS: 
      const {riffId} = action.payload.params;
      const isFirst = action.payload.params.page === 1;
      state.byId[riffId].loadingComments = true;
      state.riffId = riffId;
      return dotProp.set(state, `byId.${riffId}.comments`, isFirst? null: state.byId[riffId].comments);

    case GET_RIFF_COMMENTS_SUCCESS:
      if(action.payload.data.result.length){
        const riffId = action.payload.data.result[0].riffId;
        state.byId[riffId].loadingComments = false;
        if(state.byId[riffId].comments)
          action.payload.data.result = [...state.byId[riffId].comments.result, ...action.payload.data.result];
        return dotProp.set(state, `byId.${riffId}.comments`, action.payload.data);
      }
        return dotProp.set(state, `byId.${state.riffId}.loadingComments`, false);

    case ADD_RIFF_COMMENT:
    case ADD_COMMENT: 
      state.byId[action.payload.params.riffId].actions.commented = true;
      state.byId[action.payload.params.riffId].commentCount++;  
      return dotProp.set(state, `byId.${action.payload.params.riffId}.loadingComments`, true);
    
    case DELETE_COMMENT: 
      const {params} = action.payload;
      return dotProp.set(state, `byId.${params.riffId}.comments.result`, state.byId[params.riffId].comments.result.map(comment => {
        if(comment.id === params.commentId){
          comment.hide = true;
        }
        return comment;
      }));

    default:
      return state;
  }
};

export default reducer;
