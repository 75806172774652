import React, { Fragment } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import PropTypes from 'prop-types';
import options from '../../assets/icons/options.png';
import './options.scss';

const Options = ({ data, icon, classNames }) => (
  <Dropdown className="options-dropdown" as={({ children }) => children}>
    <Dropdown.Toggle><img src={icon || options} alt="options" className={`options ${classNames? classNames: ''}`} /></Dropdown.Toggle>
    <Dropdown.Menu>
      {data.map((item, index) => (
        <Fragment key={index.toString()}>
          <Dropdown.Item onClick={item.click}>{item.text}</Dropdown.Item>
          {index !== data.length - 1 && <Dropdown.Divider />}
        </Fragment>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

Options.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    click: PropTypes.func.isRequired,
  })).isRequired,
};

export default Options;
