import { SET_CAN_SET_REMAINING_TIME, SET_RIFF_AUDIO_LENGTH, SET_RIFF_AUDIO_REMAINING_TIME, SET_RIFF_NAME_ID, SET_SIDE_RIFF_TIME } from "../constants/riffConstants";

  
  const initialState = {
    remainingTime: 0,
    length: 0,
    id: '',
    prevId: '',
    name: '',
    canSetRemainingTime: false,
    sideRiffTime: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
       case SET_RIFF_AUDIO_REMAINING_TIME: 
            return {
                ...state,
                remainingTime: action.payload.remainingTime
            }
        case SET_RIFF_AUDIO_LENGTH: 
            return {
                ...state,
                length: action.payload.length
            }
        case SET_CAN_SET_REMAINING_TIME: 
            return {
                ...state,
                canSetRemainingTime: action.payload.canSetRemainingTime
            }
        case SET_RIFF_NAME_ID: 
            return {
                ...state,
                name: action.payload.name? action.payload.name: state.name,
                prevId: action.payload.id? action.payload.id: state.prevId,
                id: action.payload.id,
                sideRiffTime: action.payload.id? null: state.sideRiffTime
            }
        case SET_SIDE_RIFF_TIME:
            return {
                ...state,
                sideRiffTime: action.payload
            }
  
      default:
        return state;
    }
  };
  
  export default reducer;
  