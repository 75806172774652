import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Footer from '../../components/footer/footer';
import logo from '../../assets/icons/riffrLogoWhite.png';
import person from '../../assets/icons/person.png';
import ErrorBoundary from '../../components/errorBoundary/errorBoundary';
import ForgotPasswordForm from '../../components/forgotPasswordForm/forgotPasswordForm';
import { forgotPassword } from '../../services/auth';
import constants from '../../utils/constants';
import { handleError } from '../../utils/http/http';
import { isEmail } from '../../utils/common';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const form = React.createRef();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({ username: '' });
  const [forgotUsingEmail, setForgotUsingEmail] = useState(true);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      setError(null);
      await forgotPassword(values.username);
      setForgotUsingEmail(await isEmail(values.username));
      setLoading(false);
      setFormValues(values);
      setShowSuccess(true);
    } catch (e) {
      setError(handleError(e, t));
      setLoading(false);
    }
  };

  const renderContent = () => {
    if (!showSuccess) {
      return (
        <>
          <h6 className="app-section-sub-header light">{t('authentication.enterPhoneNumberOrEmailSentence')}</h6>
          {error && (
          <Alert variant="danger">
            {error}
          </Alert>
          )}
          <ErrorBoundary>
            <ForgotPasswordForm formReference={form} onSubmit={onSubmit} />
          </ErrorBoundary>
          <Row className="no-gutters align-items-center submit-container">
            <div className="flex-fill">
              <Button
                variant="outline-primary"
                className="submit-button app-value float-right"
                disabled={loading}
                onClick={() => form.current.submitForm()}
              >
                {t('globals.submit')}
                {loading && <Spinner animation="border" className="spinner spinner-border-sm" />}
              </Button>
            </div>
          </Row>
        </>
      );
    }
    if (forgotUsingEmail) {
      return (
        <>
          <h6 className="app-section-title">{t('authentication.weHaveSentAnEmail') + formValues.username}</h6>
          <h6 className="app-section-title light">{t('authentication.clickOnTheEmailLink')}</h6>
        </>
      );
    }
    return (
      <>
        <h6 className="app-section-title">{t('authentication.weHaveSentAnSMS') + formValues.username}</h6>
        <h6 className="app-section-title light">{t('authentication.clickOnTheSMS')}</h6>
      </>
    );
  };

  return (
    <div className="authentication-screen">
      <ul className="nav" id="homepage-nav">
        <li className="nav-item flex-fill">
          <a className="nav-link nav-link-logo" href={constants.screens.default}>
            <img src={logo} alt="Logo" className="logo" />
          </a>
        </li>
        <Row className="no-gutters">
          <li className="nav-item">
            <a className="nav-link" href={constants.screens.login}>
              <Row className="no-gutters align-items-center">
                <img src={person} alt="person" width="20" height="20" />
                <span className="login">{t('authentication.login')}</span>
              </Row>
            </a>
          </li>
        </Row>
      </ul>
      <div className="page-content">
        <Row className="no-gutters">
          <Col xs={1} sm={3} lg={4} className="flex-fill">
            <PageLeftContent title={t('globals.goBack')} navigateToRef={constants.screens.login} />
          </Col>
          <Col xs={10} sm={5} lg={4}>
            <h4 className="app-section-header bold">{t('authentication.forgotPassword')}</h4>
            {renderContent()}
          </Col>
        </Row>
        <div className="authentication-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
