import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import constants from '../../utils/constants'
import sortIcon from '../../assets/icons/sort@3x.png';
import checkmarkIcon from '../../assets/icons/checkmarkCircleOrange.png';
import './sortBtn.scss';

export default function SortBtn({sort, setSort}) {
    const { t } = useTranslation()
    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="d-flex flex-row">
                    <div className="sortIconContainer mr-2 d-flex justify-content-center align-items-center"><img src={sortIcon} alt="playAll" width="20" height="17" /></div>
                    <span className="bold pt-1 sortTxt">{sort === constants.sort.popular? t('riff.ShowingMostPopularFirst'): t('riff.ShowingMostRecentFirst')}</span>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="sortContainer">
                <Dropdown.Item onClick={() => setSort(constants.sort.recent)} className="sortItemRow">
                    <span className={`d-flex flex-row justify-content-between align-items-center ${sort === constants.sort.recent? 'activeSort': ''}`}>
                        {t('riff.mostRecentFirst')}
                        {sort === constants.sort.recent? <img src={checkmarkIcon} width="22" alt="check" className="ml-4" />: <div className="ml-4" style={{minWidth: 22, height: 22}} />}
                    </span>
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setSort(constants.sort.popular)} className="sortItemRow-last">
                    <span className={`d-flex flex-row justify-content-between align-items-center ${sort === constants.sort.popular? 'activeSort': ''}`}>
                        {t('riff.mostPopularFirst')}
                        {sort === constants.sort.popular? <img src={checkmarkIcon} width="22" alt="check" className="ml-4" />: <div className="ml-4" style={{minWidth: 22, height: 22}} />}
                    </span>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}
