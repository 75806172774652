import { CLEAR_AUTHENTICATION, SAVE_AUTHENTICATION } from '../constants/authenticationConstants';

const saveAuthentication = (data) => ({
  type: SAVE_AUTHENTICATION,
  payload: data,
});

const clearAuthentication = () => ({
  type: CLEAR_AUTHENTICATION,
  payload: null,
});

export { clearAuthentication, saveAuthentication };
