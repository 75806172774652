import { BLOCK_USER, MUTE_USER } from '../constants/usersConstants';

/**
 * @param {string} id
 */
export const blockUser = (id) => ({
  type: BLOCK_USER,
  payload: {
    request: {
      url: `user/${id}/block`,
      method: 'post',
      authenticated: true,
    },
    params: {
      id,
    },
  },
});

/**
 * @param {string} id
 */
export const muteUser = (id) => ({
  type: MUTE_USER,
  payload: {
    request: {
      url: `user/${id}/mute`,
      method: 'post',
      authenticated: true,
    },
    params: {
      id,
    },
  },
});
