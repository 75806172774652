import React, { useState } from 'react';
import { useField } from 'formik';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import PropTypes from 'prop-types';
import eye from '../../../assets/icons/eye.png';
import eyeOff from '../../../assets/icons/eyeOff.png';
import './textInput.scss';

const TextInput = ({ label, type, classNames, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focus, setFocused] = useState(false);
  const [field, meta] = useField(props);
  const {
    id, name, handleChange, handleBlur, values, placeholder, disabled, autocomplete,
  } = props;
  const hasError = meta.touched && meta.error;
  const showEye = type === 'password' && values[name];

  return (
    <Form.Group
      controlId={`form-${id || name}`}
      className={`app-text-input app-text-input-${type} ${hasError ? 'app-text-input-error' : ''}`}
    >
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          type={!showPassword ? type : 'text'}
          name={field.name}
          placeholder={placeholder || label}
          onChange={handleChange}
          onBlur={(event) => {
            handleBlur(event);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          value={values[name]}
          style={showEye ? { borderRight: 'none' } : {}}
          className={`${focus ? 'active' : 'inactive'} ${classNames? classNames: ''}`}
          disabled={disabled}
          autoComplete={autocomplete}
        />
        {showEye && (
          <InputGroup.Append>
            <InputGroup.Text className={`eye-container ${focus ? 'active' : 'inactive'}`}>
              <div aria-hidden="true" onClick={() => setShowPassword(!showPassword)}>
                <img src={showPassword ? eyeOff : eye} alt="eye" height="12" />
              </div>
            </InputGroup.Text>
          </InputGroup.Append>
        )}
      </InputGroup>

      {hasError && (
        <Form.Text className="error-message">
          {meta.error}
        </Form.Text>
      )}
    </Form.Group>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.string,
};

TextInput.defaultProps = {
  label: '',
  id: '',
  name: '',
  type: 'text',
  placeholder: '',
  disabled: false,
  autocomplete: '',
};

export default TextInput;
