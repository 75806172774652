const GET_USER_BLOCKED = 'GET_USER_BLOCKED';
const GET_USER_BLOCKED_SUCCESS = 'GET_USER_BLOCKED_SUCCESS';
const GET_USER_BLOCKED_FAIL = 'GET_USER_BLOCKED_FAIL';

const GET_USER_MUTED = 'GET_USER_MUTED';
const GET_USER_MUTED_SUCCESS = 'GET_USER_MUTED_SUCCESS';
const GET_USER_MUTED_FAIL = 'GET_USER_MUTED_FAIL';

const UNBLOCK_USER = 'UNBLOCK_USER';
const UNBLOCK_USER_SUCCESS = 'UNBLOCK_USER_SUCCESS';
const UNBLOCK_USER_FAIL = 'UNBLOCK_USER_FAIL';

const UNMUTE_USER = 'UNMUTE_USER';
const UNMUTE_USER_SUCCESS = 'UNMUTE_USER_SUCCESS';
const UNMUTE_USER_FAIL = 'UNMUTE_USER_FAIL';


export {
    GET_USER_BLOCKED,
    GET_USER_BLOCKED_SUCCESS,
    GET_USER_BLOCKED_FAIL,
    GET_USER_MUTED,
    GET_USER_MUTED_SUCCESS,
    GET_USER_MUTED_FAIL,
    UNBLOCK_USER,
    UNBLOCK_USER_SUCCESS,
    UNBLOCK_USER_FAIL,
    UNMUTE_USER,
    UNMUTE_USER_SUCCESS,
    UNMUTE_USER_FAIL
}