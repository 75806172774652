import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar'
import Page from '../../components/pageContent/pageContent'
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent'
import PageTitle from '../../components/pageTitle/pageTitle'
import Riff from '../../components/riff/riff';
import { getRiffById } from '../../store/actions/homepageActions'
import constants from '../../utils/constants'

export default function EchoComment({history}) {
    const { t } = useTranslation();
    const [audioPlaying, setAudioPlaying] = useState(0)
    const { riffId } = useParams();
    const dispatch = useDispatch();
    const home = useSelector(state => state.home);
    const loading = <div className="text-center"><Spinner animation="border" className="spinner" /></div>;

    const onActionRiff = useCallback((item) => {
        const {
            triggeredAction, screen
        } = item;
        switch (triggeredAction) {
          case constants.riffActions.goTo:
            history.push(screen);
            break;
          default:
            break;
        }
      }, [history]);

    useEffect(() => {
        dispatch(getRiffById(riffId));
    }, [riffId, dispatch]);

    return (
        <Page header={<AuthenticatedNavBar activeKey={'myFeeds'} />}>
            <Row className="no-gutters">
                <Col xs={1} sm={3} lg={4} className="flex-fill">
                    <PageLeftContent title={t('globals.cancel')} navigateToRef={constants.screens.home} />
                </Col>
                <Col xs={10} sm={6} lg={4} className="flex-fill">
                    <PageTitle>{t('riff.echoWithRiffOrComment')}</PageTitle>
                    {
                        home.loading && loading
                    }
                    {
                        home.result && 
                        <Riff 
                            name={home.result.user && home.result.user.name}
                            description={home.result.name}
                            commentCount={home.result.commentCount}
                            playCount={home.result.playCount}
                            echoCount={home.result.echoCount}
                            likes={home.result.likes}
                            length={home.result.length}
                            file={home.result.file}
                            indexPlaying=""
                            classNames="feed-riff-item"
                            onActionRiff={onActionRiff}
                            readOnly={true}
                            hideActions={true}
                            liked={home.result.actions?.liked}
                            isPlayed={home.result.actions?.played}
                            commented={home.result.actions?.commented}
                            echoed={home.result.actions?.echoed}
                            data={home.result}
                            onPlay={setAudioPlaying}
                            isPlaying={`${home.result.id}-1` === audioPlaying}
                            tags={home.result.tags}
                            hide={home.result.hide}
                            index="1"
                            isEchoComment
                            canComment
                        />
                    }
                </Col>
            </Row>
        </Page>
    )
}
