import { publicHttpClient } from '../utils/http/http';
import constants from '../utils/constants';

/**
 * @param {string} username
 * @param {string} password
 */
export const authenticate = async (username, password) => {
  try {
    const body = {
      grant_type: 'password',
      client_id: constants.clientId,
      client_secret: constants.clientSecret,
      username,
      password,
    };
    const { data } = await publicHttpClient.post('auth', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

export const refresh = async (refreshToken) => {
  const body = {
    grant_type: 'refresh_token',
    client_id: constants.clientId,
    client_secret: constants.clientSecret,
    refresh_token: refreshToken,
  };
  const { data } = await publicHttpClient.post('auth', body);
  return data;
};

/**
 * @param {string} username
 */
export const forgotPassword = async (username) => {
  try {
    const body = {
      email: username,
    };
    const { data } = await publicHttpClient.post('user/forgotpassword', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};

/**
 * @param {object} authenticationData
 * @param {string} oldPassword
 * @param {string} newPassword
 */
export const resetPassword = async (authenticationData, oldPassword, newPassword) => {
  try {
    const body = {
      old: oldPassword,
      password: newPassword,
    };
    const authorization = `${authenticationData.token_type} ${authenticationData.access_token}`;
    const { data } = await publicHttpClient.put('user/password', body, {
      headers: {
        authorization,
      },
    });
    return data;
  } catch (e) {
    throw e.response;
  }
};
