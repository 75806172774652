import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NavItem from '../navItem/navItem';
import home from '../../assets/icons/home.png';
import search from '../../assets/icons/search.png';
import mic from '../../assets/icons/mic.png';
import person from '../../assets/icons/person.png';
import constants from '../../utils/constants';
import NavBar from '../navBar/navBar';

const menuItems = {
  myFeeds: 'myFeeds',
  search: 'search',
  record: 'record',
  profile: 'profile',
};

const AuthenticatedNavBar = ({ activeKey }) => {
  const { t } = useTranslation();
  return (
    <NavBar>
      <NavItem
        text={t('globals.myFeeds')}
        imageSrc={home}
        screen={constants.screens.home}
        showUnderline={activeKey === menuItems.myFeeds}
      />
      <NavItem
        text={t('globals.search')}
        imageSrc={search}
        screen={constants.screens.search}
        showUnderline={activeKey === menuItems.search}
      />
      <NavItem
        text={t('globals.record')}
        imageSrc={mic}
        screen={constants.screens.record}
        showUnderline={activeKey === menuItems.record}
        width={15}
        height={23}
      />
      <NavItem
        text={t('globals.profile')}
        imageSrc={person}
        screen={constants.screens.profile}
        showUnderline={activeKey === menuItems.profile}
      />
    </NavBar>
  );
};

AuthenticatedNavBar.propTypes = {
  activeKey: PropTypes.oneOf([menuItems.myFeeds, menuItems.search, menuItems.record, menuItems.profile]).isRequired,
};

export default AuthenticatedNavBar;
