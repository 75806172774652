import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import InfiniteScroll from 'react-infinite-scroll-component';
import MicRecorder from 'mic-recorder-to-mp3';
import Timer from 'react-compound-timer';
import constants from '../../utils/constants';
import downloadOnAppStore from '../../assets/images/downloadOnAppStore.png';
import downloadOnAppStoreMedium from '../../assets/images/downloadOnAppStore@2x.png';
import downloadOnAppStoreLarge from '../../assets/images/downloadOnAppStore@3x.png';
import getItOnGooglePlay from '../../assets/images/getItOnGooglePlay.png';
import getItOnGooglePlayMedium from '../../assets/images/getItOnGooglePlay@2x.png';
import getItOnGooglePlayLarge from '../../assets/images/getItOnGooglePlay@3x.png';
import usFlag from '../../assets/images/usFlag.png';
import trumpImage from '../../assets/images/trump.png';
import bidenImage from '../../assets/images/biden.png';
import play from '../../assets/icons/playBlack.png';
import stop from '../../assets/icons/stop.png';
import riff from '../../assets/icons/riff.png';
import './debates.scss';
import logo from '../../assets/icons/riffrLogoBlack.png';
import { addDebateRiffsByUser, getDebateRiffsByUser, voteByUser } from '../../store/actions/debateActions';
import Riff from '../../components/riff/riff';
import Footer from '../../components/footer/footer';
import DebateAudioForm from '../../components/debateAudioForm/debateAudioForm';
import { formatTime } from '../../utils/common';
import Popup from '../../components/popup/popup';

const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

const micRecorder = new MicRecorder({ bitRate: 128 });

const audioFormSteps = {
  hidden: 'hidden',
  record: 'record',
  recording: 'recording',
  submit: 'submit',
};

const Debates = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const trump = useSelector((state) => state.debate.trump);
  const biden = useSelector((state) => state.debate.biden);
  const total = biden.result && trump.result && biden.result.maxResults + trump.result.maxResults;
  const vote = useSelector((state) => state.debate.vote);
  const [showDetails, setShowDetails] = useState(false);
  const [audioPlaying, setAudioPlaying] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [trumpAudioForm, setTrumpAudioForm] = useState(audioFormSteps.hidden);
  const [bidenAudioForm, setBidenAudioForm] = useState(audioFormSteps.hidden);
  const [trumpAudioLength, setTrumpAudioLength] = useState('00:00');
  const [bidenAudioLength, setBidenAudioLength] = useState('00:00');
  const [trumpAudio, setTrumpAudio] = useState(null);
  const [bidenAudio, setBidenAudio] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showTrump, setShowTrump] = useState(false);
  const trumpCounterRef = useRef();
  const bidenCounterRef = useRef();
  const trumpFormRef = useRef();
  const bidenFormRef = useRef();

  useEffect(() => {
    dispatch(getDebateRiffsByUser(constants.debates.users.trump, 1));
    dispatch(getDebateRiffsByUser(constants.debates.users.biden, 1));
  }, [dispatch]);

  const loadMoreRiffs = () => {
    if (biden.hasMore) {
      dispatch(getDebateRiffsByUser(constants.debates.users.biden, biden.result.currentPage + 1));
    }
    if (trump.hasMore) {
      dispatch(getDebateRiffsByUser(constants.debates.users.trump, trump.result.currentPage + 1));
    }
  };

  const startRecording = (contestant) => {
    try {
      if (contestant === constants.debates.users.trump && bidenAudioForm === audioFormSteps.recording) {
        return;
      }
      if (contestant === constants.debates.users.biden && trumpAudioForm === audioFormSteps.recording) {
        return;
      }
      if (!isRecording) {
        micRecorder
          .start()
          .then(() => {
            if (contestant === constants.debates.users.trump) {
              trumpCounterRef.current.start();
              setTrumpAudioForm(audioFormSteps.recording);
            } else {
              bidenCounterRef.current.start();
              setBidenAudioForm(audioFormSteps.recording);
            }
            setIsRecording(true);
          }).catch(() => null);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // eslint-disable-next-line no-alert
      alert('Audio recording does not work on your browser. Please use chrome, firefox or edge');
    }
  };

  const stopRecording = (contestant) => {
    micRecorder
      .stop()
      .getMp3()
      // eslint-disable-next-line no-unused-vars
      .then(([buffer, blob]) => {
        if (contestant === constants.debates.users.trump) {
          setTrumpAudioLength(formatTime(trumpCounterRef.current.state.m, trumpCounterRef.current.state.s));
          trumpCounterRef.current.stop();
        } else {
          setBidenAudioLength(formatTime(bidenCounterRef.current.state.m, bidenCounterRef.current.state.s));
          bidenCounterRef.current.stop();
        }
        // const blobURL = URL.createObjectURL(blob);
        // eslint-disable-next-line no-console
        setIsRecording(false);
        if (contestant === constants.debates.users.trump) {
          setTrumpAudio(blob);
          setTrumpAudioForm(audioFormSteps.submit);
        } else {
          setBidenAudio(blob);
          setBidenAudioForm(audioFormSteps.submit);
        }
      }).catch(() => null);
  };

  const renderTrumpImage = () => (
    <img
      height={150}
      src={trumpImage}
      alt="trump"
      className="trump"
    />
  );

  const renderBidenImage = () => (
    <img
      height={150}
      src={bidenImage}
      alt="trump"
      className="trump"
    />
  );

  const voteFor = (contestant) => (
    <div
      className={`app-value vote-for vote-for-${contestant} mt-4 clickable`}
      aria-hidden="true"
      onClick={() => {
        dispatch(voteByUser(contestant));
        if (contestant === constants.debates.users.trump) {
          setTrumpAudioForm(audioFormSteps.record);
          setBidenAudioForm(audioFormSteps.hidden);
        } else {
          setBidenAudioForm(audioFormSteps.record);
          setTrumpAudioForm(audioFormSteps.hidden);
        }
      }}
    >
      {contestant === constants.debates.users.trump ? t('debates.voteForTrump') : t('debates.voteForBiden')}
    </div>
  );

  const recordContainer = (contestant) => (
    <Row className="no-gutters record-container text-left mt-3">
      <Col className="align-bottom m-auto">
        {contestant === constants.debates.users.trump
        && (
          <span className="app-value">
            {(trumpAudioForm === audioFormSteps.recording) ? t('debates.recording') : t('debates.record')}
          </span>
        )}
        {contestant === constants.debates.users.biden
        && (
          <span className="app-value">
            {(bidenAudioForm === audioFormSteps.recording) ? t('debates.recording') : t('debates.record')}
          </span>
        )}
        <Timer
          ref={contestant === constants.debates.users.trump ? trumpCounterRef : bidenCounterRef}
          startImmediately={false}
          checkpoints={[
            {
              time: constants.maxAudioLength * 1000,
              callback: () => stopRecording(contestant),
            },
          ]}
        >
          <Timer.Minutes />
          :
          <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
        </Timer>
        /
        <Timer startImmediately={false} initialTime={constants.maxAudioLength * 1000}>
          <Timer.Minutes />
          :
          <Timer.Seconds formatValue={(value) => (value.toString().length === 1 ? `0${value}` : value)} />
        </Timer>
      </Col>
      <Col>
        <span
          className="clickable"
          aria-hidden="true"
          onClick={() => (isRecording ? stopRecording(contestant) : startRecording(contestant))}
        >
          {contestant === constants.debates.users.biden && (
            <img
              src={bidenAudioForm === audioFormSteps.recording ? stop : play}
              alt="play"
              className="play float-right"
              height="30"
              width="31"
            />
          )}
          {contestant === constants.debates.users.trump && (
            <img
              src={trumpAudioForm === audioFormSteps.recording ? stop : play}
              alt="play"
              className="play float-right"
              height="30"
              width="31"
            />
          )}
        </span>
      </Col>
    </Row>
  );

  // eslint-disable-next-line no-unused-vars
  const renderStores = () => (
    <>
      <a
        href={constants.riffrAndroidStore}
        target="_blank"
        className="getItOnGooglePlay debates-header-item"
        rel="noopener noreferrer"
      >
        <img
          src={getItOnGooglePlay}
          srcSet={`${getItOnGooglePlay} 300w, ${getItOnGooglePlayMedium} 768w, ${getItOnGooglePlayLarge} 1280w`}
          alt="googleStore"
          className="getItOnGooglePlay"
        />
      </a>

      <a href={constants.riffrAppStore} target="_blank" rel="noopener noreferrer">
        <img
          src={downloadOnAppStore}
          srcSet={`${downloadOnAppStore} 300w, ${downloadOnAppStoreMedium} 768w, ${downloadOnAppStoreLarge} 1280w`}
          alt="appStore"
          className="downloadOnAppStore debates-header-item"
        />
      </a>
    </>
  );

  const renderAudioForm = (contestant) => (
    <div className="form-container mt-3 text-left">
      <Row className="no-gutters">
        <img
          src={riff}
          alt="play"
          className="riff-icon m-auto pr-2"
          height="18"
        />
        <span className="m-auto app-value flex-fill text-left">
          {`${t('debates.yourRiff')} ${contestant === constants.debates.users.trump ? trumpAudioLength : bidenAudioLength}`}
        </span>
        <Button
          className="submit-button app-value float-right"
          onClick={() => {
            if (contestant === constants.debates.users.trump) {
              trumpFormRef.current.submitForm();
            } else {
              bidenFormRef.current.submitForm();
            }
          }}
        >
          {t('debates.post')}
        </Button>
      </Row>
      <DebateAudioForm
        formReference={contestant === constants.debates.users.trump ? trumpFormRef : bidenFormRef}
        onSubmit={(values) => {
          const formData = new FormData();
          formData.append('publish', '1');
          formData.append('userName', values.name);
          formData.append('name', values.description);
          formData.append('file', contestant === constants.debates.users.trump ? trumpAudio : bidenAudio, 'batata.mp3');
          dispatch(
            addDebateRiffsByUser(
              contestant,
              formData,
            ),
          );
          setShowSuccessModal(true);
          if (contestant === constants.debates.users.trump) {
            setTrumpAudioForm(audioFormSteps.record);
          } else {
            setBidenAudioForm(audioFormSteps.record);
          }
        }}
      />
    </div>
  );

  const renderPercentage = (maxResult) => {
    if (biden.result && trump.result) {
      const percentage = maxResult * 100 / total;
      return (
        <h6 className="app-value mt-4 mb-4">
          { percentage.toFixed(2) }
          %
        </h6>
      );
    }
  };

  const renderDetails = () => (
    <>
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <h6
        aria-hidden="true"
        className="app-value how-it-works clickable disable-select"
        onClick={() => setShowDetails(!showDetails)}
        aria-controls="vote-details"
        aria-expanded={showDetails}
      >
        {showDetails ? t('debates.hideDetails') : t('debates.learnMoreAboutHowItWorks')}
      </h6>
      <Collapse in={showDetails}>
        <Row className="no-gutters">
          <Col xs={6} className="offset-3">
            <div id="vote-details">
              <Row className="no-gutters mt-4">
                <Col>
                  <span className="app-value">
                    1.
                    <span className="light">{` ${t('debates.voteForWinner')}`}</span>
                  </span>
                </Col>
              </Row>
              <Row className="no-gutters mt-4">
                <Col>
                  <span className="app-value">
                    2.
                    <span className="light">{` ${t('debates.recordYourAudioRiff')}`}</span>
                  </span>
                </Col>
              </Row>
              <Row className="no-gutters mt-4">
                <Col>
                  <span className="app-value">
                    3.
                    <span className="light">{` ${t('debates.dontStopHere')}`}</span>
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Collapse>
    </>
  );

  const renderBidenContent = () => (
    <>
      {renderBidenImage()}
      {renderPercentage(biden.result && biden.result.maxResults)}
      {!vote.votedBiden && voteFor(constants.debates.users.biden)}
      {vote.votedBiden && (bidenAudioForm === audioFormSteps.record || bidenAudioForm === audioFormSteps.recording)
      && recordContainer(constants.debates.users.biden)}
      {bidenAudioForm === audioFormSteps.submit && renderAudioForm(constants.debates.users.biden)}
      {!biden.loading && biden.result && total > 50
      && (
      <h6 className="app-value mt-4 mb-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
        {`${biden.result.maxResults} ${t('debates.teamBidenRiffs')}`}
      </h6>
      )}
      {biden.loading && biden.allIds.length === 0 && loading}
      {biden.allIds && biden.allIds.length !== 0 && (
        <InfiniteScroll
          className="feeds-scroll-container text-left d-none d-sm-block d-md-block d-lg-block d-xl-block"
          dataLength={biden.allIds.length}
          next={() => null}
          hasMore={biden.hasMore}
        >
          {console.log(biden)}
          {biden.allIds.map((id, index) => {
            // eslint-disable-next-line no-shadow
            const riff = biden.byId[id];
            return (
              <Riff
                name={riff.user.name}
                description={riff.name}
                commentCount={riff.commentCount}
                playCount={riff.playCount}
                echoCount={riff.echoCount}
                likes={riff.likes}
                length={riff.length}
                file={riff.file}
                key={`${riff.id}-${index.toString()}`}
                index={index}
                indexPlaying=""
                classNames="feed-riff-item"
                readOnly
                hideActions
                liked={riff.actions?.liked}
                isPlayed={riff.actions?.played}
                commented={riff.actions?.commented}
                echoed={riff.actions?.echoed}
                data={riff}
                onPlay={(playId) => {
                  setAudioPlaying(playId);
                }}
                isPlaying={`${riff.id}-${index}` === audioPlaying}
                tags={riff.tags}
                hide={riff.hide}
                date={riff.dateCreated}
              />
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );

  const showRiffs = (data) => (
    <>
      {!data.loading && data.result && total > 50
      && (
      <h6 className="app-value mb-3 mt-4 d-block d-sm-none d-md-none d-lg-none d-xl-none">
        {`${data.result.maxResults} ${showTrump ? t('debates.teamTrumpRiffs') : t('debates.teamBidenRiffs')}`}
      </h6>
      )}
      <span className="clickable mb-3 switchTeamTxt app-value d-block d-sm-none d-md-none d-lg-none d-xl-none" aria-hidden="true" onClick={() => setShowTrump(!showTrump)}>
        {showTrump ? t('debates.seeTeamBiden') : t('debates.seeTeamTrump')}
      </span>
      <InfiniteScroll
        className="feeds-scroll-container text-left d-block d-sm-none d-md-none d-lg-none d-xl-none"
        dataLength={data.allIds.length}
        next={() => null}
        hasMore={data.hasMore}
      >
        {data.allIds.map((id, index) => {
          // eslint-disable-next-line no-shadow
          const riff = data.byId[id];
          return (
            <Riff
              name={riff.user.name}
              description={riff.name}
              commentCount={riff.commentCount}
              playCount={riff.playCount}
              echoCount={riff.echoCount}
              likes={riff.likes}
              length={riff.length}
              file={riff.file}
              key={`${riff.id}-${index.toString()}`}
              index={index}
              indexPlaying=""
              classNames="feed-riff-item"
              readOnly
              hideActions
              liked={riff.actions?.liked}
              isPlayed={riff.actions?.played}
              commented={riff.actions?.commented}
              echoed={riff.actions?.echoed}
              data={riff}
              onPlay={setAudioPlaying}
              isPlaying={`${riff.id}-${index}` === audioPlaying}
              tags={riff.tags}
              hide={riff.hide}
              date={riff.dateCreated}
            />
          );
        })}
      </InfiniteScroll>
    </>
  );

  const renderTrumpContent = () => (
    <>
      {renderTrumpImage()}
      {renderPercentage(trump.result && trump.result.maxResults)}
      {!vote.votedTrump && voteFor(constants.debates.users.trump)}
      {vote.votedTrump && (trumpAudioForm === audioFormSteps.record || trumpAudioForm === audioFormSteps.recording)
      && recordContainer(constants.debates.users.trump)}
      {trumpAudioForm === audioFormSteps.submit && renderAudioForm(constants.debates.users.trump)}
      {!trump.loading && trump.result && total > 50
      && (
      <h6 className="app-value mt-4 mb-4 d-none d-sm-block d-md-block d-lg-block d-xl-block">
        {`${trump.result.maxResults} ${t('debates.teamTrumpRiffs')}`}
      </h6>
      )}
      {trump.loading && trump.allIds.length === 0 && loading}
      {trump.allIds && trump.allIds.length !== 0 && (
        <InfiniteScroll
          className="feeds-scroll-container text-left d-none d-sm-block d-md-block d-lg-block d-xl-block"
          dataLength={trump.allIds.length}
          next={() => null}
          hasMore={trump.hasMore}
        >
          {trump.allIds.map((id, index) => {
            // eslint-disable-next-line no-shadow
            const riff = trump.byId[id];
            return (
              <Riff
                name={riff.user.name}
                description={riff.name}
                commentCount={riff.commentCount}
                playCount={riff.playCount}
                echoCount={riff.echoCount}
                likes={riff.likes}
                length={riff.length}
                file={riff.file}
                key={`${riff.id}-${index.toString()}`}
                index={index}
                indexPlaying=""
                classNames="feed-riff-item"
                readOnly
                hideActions
                liked={riff.actions?.liked}
                isPlayed={riff.actions?.played}
                commented={riff.actions?.commented}
                echoed={riff.actions?.echoed}
                data={riff}
                onPlay={setAudioPlaying}
                isPlaying={`${riff.id}-${index}` === audioPlaying}
                tags={riff.tags}
                hide={riff.hide}
                date={riff.dateCreated}
              />
            );
          })}
        </InfiniteScroll>
      )}
    </>
  );

  return (
    <div className="debates">
      <Popup
        backdrop={!vote.loading}
        keyboard={!vote.loading}
        show={showSuccessModal}
        isloading={vote.loading.toString()}
        onHide={() => setShowSuccessModal(false)}
        title= "Thanks for voicing your support for your candidate!"
        subTitle= "Why not download the riffr app for the next time you want to share your voice? It's the social platform for (micro)podcasting."
      />
      <div>
        <Row className="no-gutters debates-header">
          <a className="nav-link clickable" href="/">
            <img src={logo} alt="Logo" className="logo" />
            <span
              className="download-app learn-more-title bold d-none d-sm-inline"
            >
              {t('debates.learnMoreAboutRiffr')}
            </span>
          </a>
          <span className="flex-fill">
            <span className="download-app hashtag-debates bold float-right">{t('debates.hashtagDebates')}</span>
          </span>
        </Row>
      </div>

      <Row className="no-gutters">
        <Col lg={10} className="offset-0 offset-lg-1">
          <Row className="no-gutters">
            <Col className="top-content">
              <img
                src={usFlag}
                alt="usFlag"
                className="mt-3 mb-5 usFlag"
              />
              <h6 className="mt-sm-1 mt-md-3 mt-lg-5 app-section-header title">{t('debates.firstTimeMessage')}</h6>
              <h6 className="mt-4 mb-4 sub-title bold">{t('debates.crunchTime')}</h6>
              {renderDetails()}
            </Col>
          </Row>
          <Row className="no-gutters mt-5">
            <Col className={`text-center biden-content ${trumpAudioForm !== audioFormSteps.hidden ? 'd-none' : 'd-block'} d-sm-block d-md-block d-lg-block d-xl-block`}>
              {renderBidenContent()}
            </Col>
            <Col className={`text-center trump-content ${bidenAudioForm !== audioFormSteps.hidden ? 'd-none' : 'd-block'} d-sm-block d-md-block d-lg-block d-xl-block`}>
              {renderTrumpContent()}
            </Col>
          </Row>
          {(biden.hasMore || trump.hasMore) && (
            <Row className="no-gutters text-center">
              <Col>
                {
                  showRiffs(showTrump ? trump : biden)
                }
                <span className="clickable app-value " aria-hidden="true" onClick={() => loadMoreRiffs()}>
                  {t('debates.seeMore')}
                </span>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Debates;
