import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import TermsOfUse from '../../html/termsOfUse';
import Page from '../../components/pageContent/pageContent';
import constants from '../../utils/constants';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import Tabs from '../../components/tabs/tabs';
import AccountDeletion from '../../html/accountDeletion';
import NonAuthenticatedNavBar from '../../components/nonAuthenticatedNavBar/nonAuthenticatedNavBar';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';

const AccountDeletionPage = ({ match }) => {
  const { t } = useTranslation();
  const isAuthenticated = match.url === constants.screens.settingsTerms;
  const [selectedTab, setSelectedTab] = useState(match.url !== constants.screens.terms ? 0 : 1);
  const tabs = [
    t('globals.privacyPolicy'),
    t('globals.termsOfUse'),
  ];

  return (
    <Page header={isAuthenticated? <AuthenticatedNavBar activeKey="profile" />: <NonAuthenticatedNavBar />}>
      <Row className="no-gutters">
        <Col xs={1} sm={2} lg={3} className="flex-fill">
          <PageLeftContent title={t(isAuthenticated? 'globals.backToSettings': 'globals.back')} navigateToRef={isAuthenticated? constants.screens.settings: constants.screens.landing} />
        </Col>
        <Col xs={10} sm={8} lg={6}>
          <PageTitle>
            Account Deletion
          </PageTitle>
          <AccountDeletion />
        </Col>
      </Row>
    </Page>
  );
};

AccountDeletionPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
};

export default AccountDeletionPage;
