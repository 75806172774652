import React from 'react';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/icons/riffrLogoWhite.png';
import constants from '../../utils/constants';

const { screens } = constants;

const NonAuthenticatedNavBar = () => {
  const { t } = useTranslation();
  return (
    <ul className="nav" id="homepage-nav">
      <li className="nav-item flex-fill m-auto">
        <a className="nav-link nav-link-logo" href="/">
          <img src={logo} alt="Logo" className="logo" />
        </a>
      </li>
      <Row className="no-gutters">
        <li className="nav-item justify-content-center d-none d-md-block">
          <a className="nav-link align-middle" href={screens.landing}>{t('homepage.howRiffrWorks')}</a>
        </li>
        <li className="nav-item justify-content-center">
          <a className="nav-link align-middle" href={screens.login}>{t('homepage.loginOrSignup')}</a>
        </li>
      </Row>
    </ul>
  );
};

export default NonAuthenticatedNavBar;
