import React from 'react';
import searchIcon from '../../assets/icons/searchBlack@3x.png';
import './searchInput.scss';

export default function SearchInput({ searchTxt, setSearchTxt, placeholder, onSearch }) {
  return (
    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <div className={`input-group-text searchIcon pr-0 d-flex justify-content-center align-items-center`}>
          <img width="22" height="22" src={searchIcon} alt="@" />
        </div>
      </div>
      <input onChange={(e) => [setSearchTxt(e.target.value), onSearch(e.target.value)]} value={searchTxt} placeholder={placeholder} type="text" className="form-control inputTxt" />
      <div className="input-group-append">
        <span onClick={() => [setSearchTxt(''), onSearch('')]} className="input-group-text clickable clearTxt">{searchTxt.length ? 'Clear' : ''}</span>
      </div>
    </div>
  );
}
