import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import AuthenticatedNavBar from '../../components/authenticatedNavBar/authenticatedNavBar';
import Page from '../../components/pageContent/pageContent';
import constants from '../../utils/constants';
import noRecords from '../../assets/icons/noRecords.png';
import {
  follow, getFollowers, getFollowing, getUser,
} from '../../store/actions/connectionsActions';
import Connection from '../../components/connection/connection';
import './connections.scss';
import SearchForm from '../../components/searchForm/searchForm';
import PageLeftContent from '../../components/pageLeftContent/pageLeftContent';
import PageTitle from '../../components/pageTitle/pageTitle';
import Tabs from '../../components/tabs/tabs';
import { useParams } from 'react-router-dom';

const loading = <div className="text-center mt-5 mb-5"><Spinner animation="border" className="spinner" /></div>;

const Connections = ({history, location}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const following = useSelector((state) => state.connections.following);
  const followers = useSelector((state) => state.connections.followers);
  const { userLoading } = useSelector((state) => state.connections.follow);
  const [showFollowers, setShowFollowers] = useState(!location.state[0].isFollowing);
  const [selectedTab, setSelectedTab] = useState(!showFollowers ? 0 : 1);
  const { userId } = useParams();
  const tabs = [
    t('profile.following'),
    t('profile.followers'),
  ];
  const form = React.createRef();

  useEffect(() => {
    dispatch(getFollowing(1, userId));
    dispatch(getFollowers(1, userId));
  }, [dispatch, userId]);

  const followAction = (data) => {
    dispatch(follow(data.id, !data.actions.follower)).then(() => {
      dispatch(getUser(data.id));
    });
  };

  const onSearch = (search) => {
    if (showFollowers) {
      dispatch(getFollowers(1, userId, search));
    } else {
      dispatch(getFollowing(1, userId, search));
    }
  };

  const renderConnections = () => {
    const content = showFollowers ? followers : following;

    return (
      <>
        <SearchForm
          formReference={form}
          onSearch={debounce(onSearch, 300)}
          initialValues={{
            search: '',
          }}
          searchPlaceholder={t(`profile.search${showFollowers ? 'Followers' : 'Following'}`)}
        />
        {content.loading && loading}
        {content.items.length === 0 && !content.loading && (
        <div className="app-value text-center mt-5">
          <img src={noRecords} alt="noRecords" className="noRecords" height="70" />
          <div className="mt-2">{t('globals.noResultsSorry')}</div>
        </div>
        )}
        {content.items && content.items.length !== 0 && (
          <InfiniteScroll
            className="connections-scroll-container"
            dataLength={content.items.length}
            next={() => dispatch(showFollowers? getFollowers(content.result.currentPage + 1, userId): getFollowing(content.result.currentPage + 1, userId))}
            hasMore={content.hasMore}
            loader={loading}
          >
            {content.items.map((record, index) => record && (
              <div
                className={`connection-container 
            ${content.items.length - 1 !== index ? 'connection-container-item' : 'connection-container-last-item'}`}
                key={index.toString()}
              >
                <Connection
                  id={record.id}
                  name={`${record.first_name} ${record.last_name}`}
                  handle={record.handle}
                  bio={record.bio}
                  following={record.actions && (!showFollowers ? record.actions.follower : record.actions.follower)}
                  onClick={() => followAction(record)}
                  goToProfile={(id) => history.push(`${constants.screens.profile}/${id}`, [{backTo: location.pathname, mainScreen: 'profile'}, ...location.state])}
                  loading={userLoading && userLoading[record.id]}
                />
              </div>
            ))}
          </InfiniteScroll>
        )}
      </>
    );
  };
  const handleBack = () => {
    if(location.state && location.state.length) {
      const currentState = location.state.shift();
      history.push(currentState.backTo, location.state.length? location.state: null );
    } else {
      history.push(`${constants.screens.profile}/${userId? userId: ''}`);
    }
  }
  return (
    <Page header={<AuthenticatedNavBar activeKey="profile" />}>
      <Row className="no-gutters mt-3 mt-sm-5">
        <Col xs={1} sm={3} lg={4} className="flex-fill">
          <PageLeftContent title={t('globals.backToProfile')} onClick={handleBack} />
        </Col>
        <Col xs={10} sm={6} lg={4}>
          <PageTitle>
            <Tabs
              onClick={(index) => {
                setSelectedTab(index);
                setShowFollowers(index !== 0);
              }}
              activeIndex={selectedTab}
              data={tabs}
            />
          </PageTitle>
          {renderConnections()}
        </Col>
      </Row>
    </Page>
  );
};

export default Connections;
