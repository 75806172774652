import { publicHttpClient } from '../utils/http/http';

/**
 * @param {object} body
 */
// eslint-disable-next-line import/prefer-default-export
export const contactUs = async (body) => {
  try {
    const { data } = await publicHttpClient.post('/web/contact', body);
    return data;
  } catch (e) {
    throw e.response;
  }
};
