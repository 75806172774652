import {
  ADD_RIFF, ADD_RIFF_FAIL, SEARCH_HASH_TAGS, SEARCH_HASH_TAGS_FAIL, ADD_RIFF_SUCCESS, DELETE_DRAFT, DELETE_DRAFT_FAIL, DELETE_DRAFT_SUCCESS, GET_DRAFTS, GET_DRAFTS_FAIL, GET_DRAFTS_SUCCESS, RESET_AUDIO, SEARCH_HASH_TAGS_SUCCESS, SET_AUDIO_FILE, SET_AUDIO_LENGTH, SET_MIC_RECORDER, SET_UPLOAD_MODE, SET_SUCCESSFULLY_POSTED, SET_IS_DRAFT,
} from '../constants/recordConstants';

const initialState = {
  audioFile: null,
  blob: null,
  micRecorder: null,
  buffer: [],
  audioLength: 0,
  isUploadMode: false,
  hashTags: [],
  loading: false,
  drafts: [],
  loadingTags: false,
  successfullyPosted: false,
  isDraft: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDIO_FILE:
      return {
        ...state,
        audioFile: action.payload.audioFile,
        blob: action.payload.blob,
        buffer: action.payload.buffer,
      };
    case SET_MIC_RECORDER:
      return {
        ...state,
        micRecorder: action.payload.micRecorder,
      };
    case RESET_AUDIO:
      return {
        ...initialState,
        buffer: [],
        micRecorder: state.micRecorder,
      };
    case SET_AUDIO_LENGTH:
      return {
        ...state,
        audioLength: action.payload.audioLength,
      };
    case SET_UPLOAD_MODE:
      return {
        ...state,
        isUploadMode: action.payload.isUploadMode,
      };
    case SEARCH_HASH_TAGS:
      return {
        ...state,
        loadingTags: true,
      };
    case SEARCH_HASH_TAGS_SUCCESS:
      return {
        ...state,
        hashTags: action.payload.data.result,
        loadingTags: false,
      };
    case SEARCH_HASH_TAGS_FAIL:
      return {
        ...state,
        loadingTags: false,
      };
    case ADD_RIFF:
      return {
        ...state,
        loading: true,
      };
    case ADD_RIFF_SUCCESS:
      return {
        ...initialState,
        isDraft: state.isDraft,
        successfullyPosted: true,
        buffer: [],
      };
    case ADD_RIFF_FAIL:
      return {
        ...initialState,
        buffer: [],
      };
    case GET_DRAFTS:
      return {
        ...state,
        loading: true,
      };
    case GET_DRAFTS_SUCCESS:
      return {
        ...state,
        loading: false,
        drafts: (action.payload.data.currentPage === 1 || !(state.drafts.result && state.drafts.result.length))? action.payload.data: {...action.payload.data, result: [...state.drafts.result, ...action.payload.data.result]},
      };
    case GET_DRAFTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DELETE_DRAFT:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_DRAFT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SET_SUCCESSFULLY_POSTED:
      return {
        ...state,
        successfullyPosted: action.payload.successfullyPosted
      }
    case SET_IS_DRAFT:
      return {
        ...state,
        isDraft: action.payload.isDraft
      }

    default:
      return state;
  }
};

export default reducer;
