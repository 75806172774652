import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import TextInput from '../formInputs/textInput/textInput';

const SearchForm = ({
  formReference, initialValues, searchPlaceholder, onSearch, disabled,
}) => {
  const validationSchema = Yup.object({});

  return (
    <Formik
      innerRef={formReference}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {
        ({
          values,
          handleChange,
          handleBlur,
        }) => (
          <>
            <TextInput
              name="search"
              id="search"
              placeholder={searchPlaceholder}
              handleChange={(event) => {
                handleChange(event);
                onSearch(event.target.value);
              }}
              handleBlur={handleBlur}
              values={values}
              disabled={disabled}
            />
          </>
        )
      }
    </Formik>
  );
};

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formReference: PropTypes.object.isRequired,
  initialValues: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

SearchForm.defaultProps = {
  disabled: false,
};

export default SearchForm;
